import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { Box, Grid, Typography } from '@mui/material';
import './index.scss';
import reportWebVitals from './reportWebVitals';
import { router } from './routes';
import Login from './components/Login';
import { persistor, store } from './store';
import { ToastContainer } from 'react-toastify';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  // <React.StrictMode>
  <>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <RouterProvider router={router} future={{ v7_startTransition: true }}></RouterProvider>
        <footer style={{ flexShrink: 0 }}>
          <Grid item>
            <Box style={{ textAlign: 'center', padding: 8 }}>
              <Typography variant="body1">Powered by INTELLAIRE</Typography>
            </Box>
          </Grid>
        </footer>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </PersistGate>
    </Provider>
  </>,
  // </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
