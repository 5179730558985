// Setup Keycloak instance as needed
// Pass initialization options as required or leave blank to load from 'keycloak.json'
const createConfig = () => {
  const config = localStorage.getItem('config') ? JSON.parse(localStorage.getItem('config')) : {};
  return {
    url: config?.idp,
    realm: config?.orgId,
    clientId: config?.clientId,
  };
};

export default createConfig;
