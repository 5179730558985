export const TABLE_HEADS = {
  sNo: 'S.No',
  groups: 'Groups',
  users: 'Users',
  roles: 'Roles',
  home: 'Home',
  appInfo: 'App Info',
  firstName: 'First Name',
  lastName: 'Last Name',
  username: 'Username',
  email: 'Email',
  emailVerified: 'Email Verified',
  id: 'Id',
  userId: 'User Id',
  ipAddress: 'Ip Address',
  start: 'Start',
  lastAccess: 'Last Access',
  userInfo: 'User Info',
  userRoles: 'User Roles',
  userSessions: 'User Sessions',
  actions: 'Action',
  pagination: 'per Page:',
  clientId: 'ClientID',
  clientName: 'Client Name',
  rootUrl: 'Root URL',
  homeUrl: 'Home URL',
  validUrl: 'Valid redirect URIs',
  logoutUris: 'Valid post logout redirect URIs',
  status: 'Status',
  edit: 'Edit',
  create: 'Create',
  member: 'Member',
  initial: 'initial',
  nonInitial: 'non-initial',
  dashboard: 'Dashboard',
  available: 'Available',
  assigned: 'Assigned',
  settings: 'Settings',
  theme: 'Theme',
};
export const MENU_HEADS = {
  dashboard: 'Dashboard',
  clients: 'Clients',
  users: 'Users',
  roles: 'Realm roles',
  groups: 'Groups',
  sessions: 'Sessions',
};

export const ACTIONS = {
  PasswordCopied: 'Password Copied!',
  copyPassword: 'Copy Password',
};

export const lowercaseLetters = 'abcdefghijklmnopqrstuvwxyz';
export const numbers = '0123456789';
export const specialChars = '!@$-*';

export const paginationRowsPerPage = [10, 20, 50, 75, 100];
