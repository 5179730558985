import { createBrowserRouter, redirect } from 'react-router-dom';

import Login from '../components/Login';
import Dashboard from './dashboard';
import ErrorPage from './error';
import UserDashboard from '../components/userdashboard';
import Root from '../components/Root';
import { KeyclockConfig } from '../auth/keycloackConfig';
import UsersList from '../components/appcomponents/usersList';
import Profile from '../components/profile';
import AppInfo from '../components/appcomponents/AppInfo';
import Roles from '../components/appcomponents/Roles';
import Groups from '../components/appcomponents/Groups';
import Clients from '../components/appcomponents/Clients';
import CreateClient from '../components/appcomponents/createClient';
import Authentication from '../components/appcomponents/Authentication';
import Settings from '../components/appcomponents/Settings';
import ClientDetails from '../components/appcomponents/ClientsDetails';
import GroupsChild from '../components/appcomponents/GroupsChild';
import GroupDetails from '../components/appcomponents/GroupDetails';
import UserDetails from '../components/appcomponents/UserDetail';
import UserClientScopes from '../components/appcomponents/UserClientsScopes';
import Events from '../components/appcomponents/Events';
import CreateClientScope from '../components/appcomponents/createClientScope';

const refreshToken = () => {
  const keycloakIntance = KeyclockConfig.getInstance();
  if (keycloakIntance.authenticated) {
    // Schedule token refresh
    setInterval(() => {
      keycloakIntance
        .updateToken(60)
        .then((refreshed) => {
          if (refreshed) {
          } else {
            // console.log(
            //   'Token not refreshed, valid for',
            //   Math.round(keycloakIntance?.tokenParsed?.exp + keycloakIntance?.timeSkew - new Date().getTime() / 1000),
            //   'seconds',
            // );
          }
        })
        .catch(() => {
          console.error('Failed to refresh token');
          keycloakIntance.login();
        });
    }, 10000); // Check every 10 seconds
  }
};
const checkAuth = async (redirectUrl = '/auth/dashboard') => {
  const keycloakInstance: any = KeyclockConfig.getInstance();

  try {
    const currentPath = window.location.pathname;
    redirectUrl = currentPath === '/' ? '/auth/dashboard' : currentPath;
    if (keycloakInstance?.authenticated) {
      return { authenticated: true, keycloakInstance };
    }
    if (!keycloakInstance.initialized) {
      keycloakInstance.initialized = true;
      await keycloakInstance.init({
        onLoad: 'login-required',
        checkLoginIframe: false,
        silentCheckSsoRedirectUri: `${window.location.origin}/silent-check-sso.html`,
        redirectUri: `${window.location.origin}${redirectUrl}`,
      });
    }
    if (keycloakInstance.authenticated) {
      refreshToken();
      return { authenticated: true, keycloakInstance };
    } else {
      return { authenticated: false };
    }
  } catch (error: any) {
    console.error('Error during Keycloak initialization:', error);
    error.message = 'Authentication Failed';
    KeyclockConfig.destroyInstance();
    throw error;
  }
};

export const router = createBrowserRouter([
  {
    path: '/',
    element: <Login />,
    // loader: keycloakInstance && checkAuth,
    // errorElement: <ErrorPage />,
  },
  {
    path: '*',
    element: <ErrorPage />, // Catch-all route for 404 Not Found
  },
  {
    path: 'auth',
    element: <Root />,
    loader: async ({ params }) => {
      try {
        const { keycloakInstance } = await checkAuth('/auth/dashboard');
        if (!keycloakInstance?.authenticated) {
          await KeyclockConfig.getInstance()?.logout({
            redirectUri: process.env.REACT_APP_URL,
          });
          localStorage.clear();
          return redirect('/');
        }
        return keycloakInstance;
      } catch (error) {
        console.log('error during keycloak check::-', error);
        return redirect('/');
      }
    },
    errorElement: <ErrorPage />,
    children: [
      {
        path: 'dashboard',
        element: <Dashboard />,
      },
      {
        path: 'userdashboard',

        children: [
          // Children routes of UserDashboard
          {
            path: ':orgName',
            element: (
              <>
                <UserDashboard />
              </>
            ),
          },
          {
            path: ':orgName/users',
            element: (
              <>
                <UsersList />
              </>
            ),
          },
          {
            path: ':orgName/users',
            element: (
              <>
                <UsersList />
              </>
            ),
          },
          {
            path: ':orgName/users/:userId/settings',
            element: (
              <>
                <UserDetails />
              </>
            ),
          },
          {
            path: ':orgName/roles',
            element: (
              <>
                <Roles />
              </>
            ),
          },
          {
            path: ':orgName/info',
            element: (
              <>
                <AppInfo />
              </>
            ),
          },
          {
            path: ':orgName/groups',
            element: (
              <>
                <Groups />
              </>
            ),
          },
          {
            path: ':orgName/groups/groupsdetails/:clientid',
            element: (
              <>
                <GroupDetails />
              </>
            ),
          },
          {
            path: ':orgName/groups/groupschild/:clientid',
            element: (
              <>
                <GroupsChild />
              </>
            ),
          },
          {
            path: ':orgName/clients',
            element: (
              <>
                <Clients />
              </>
            ),
          },
          {
            path: ':orgName/clients/createclient',
            element: (
              <>
                <CreateClient />
              </>
            ),
          },
          {
            path: ':orgName/clientscopes/createNewClientScope',
            element: (
              <>
                <CreateClientScope />
              </>
            ),
          },
          {
            path: ':orgName/clients/:clientId/clientdetails',
            element: (
              <>
                <ClientDetails />
              </>
            ),
          },
          {
            path: ':orgName/authentication',
            element: (
              <>
                <Authentication />
              </>
            ),
          },
          {
            path: ':orgName/clientscopes',
            element: (
              <>
                <UserClientScopes />
              </>
            ),
          },
          {
            path: ':orgName/events',
            element: (
              <>
                <Events />
              </>
            ),
          },
          {
            path: ':orgName/settings',
            element: (
              <>
                <Settings />
              </>
            ),
          },
          // {
          //   path: 'roles',
          //   element: (
          //     <>
          //       <Roles />
          //     </>
          //   ),
          // },
          {
            path: 'profile',
            element: <Profile />,
          },
          // Additional children routes of UserDashboard can be added here
        ],
      },
      // {
      //   path: 'roles',
      //   element: <Roles />,
      // },

      // {
      //   path: 'info',
      //   element: <AppInfo />,
      // },

      // {
      //   path: 'groups',
      //   element: <Groups />,
      // },
      // {
      //   path: 'clients',
      //   element: <Clients />,
      // },
    ],
  },
]);
