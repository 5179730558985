import {
  Grid,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  Container,
  CardMedia,
  ThemeProvider,
  Paper,
  styled,
  DialogContentText,
  DialogProps,
  Tooltip,
  Fade,
  Skeleton,
  Card,
} from '@mui/material';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
// import { keycloakInstance } from '../routes';
import { ApiHttpClient } from '../interceptor/axiosInterceptor';
import { useDispatch, useSelector } from 'react-redux';
import { setConfigSlice } from '../store/realmSlice';
import { setLoginConfigSlice } from '../store/loginRealmSlice';
// import { getSession } from "next-auth/react";
function MasterDashboard() {
  const imageUrl = '/noimageavailable.png';
  const [open, setOpen] = React.useState(false);
  const [organizations, setOrganizations] = useState([]);
  const dispatch = useDispatch();
  const state = useSelector((state: any) => state.config);
  const initialState = useSelector((state: any) => state.initialConfig);
  const [loading, setLoading] = useState(true);
  const orgId = state.orgId;
  const loginOrgId = initialState.orgId;

  const fetchData = async () => {
    try {
      const response = await ApiHttpClient.get(
        orgId !== loginOrgId
          ? `admin/${loginOrgId}/console/whoami?currentRealm=${orgId}`
          : `admin/${orgId}/console/whoami?currentRealm=${orgId}`,
      );
      const nameResponse = await ApiHttpClient.get(`admin/realms/${loginOrgId}/ui-ext/realms/names?`);
      let reformedState: any = {
        ...state,
        userId: response.userId,
        realms: nameResponse,
        realmAccess: response.realm_access,
      };
      dispatch(setConfigSlice(reformedState));
      if (orgId === loginOrgId) {
        dispatch(setLoginConfigSlice(reformedState));
      }
      setOrganizations(nameResponse);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  useEffect(() => {
    fetchData();
    // getNames();
  }, [orgId]);
  const [scroll, setScroll] = React.useState<DialogProps['scroll']>('paper');
  let navigate = useNavigate();

  const handleClickOpen = (scrollType: DialogProps['scroll']) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickNavigate = (orgName: any) => {
    // navigate(`/auth/userdashboard/${orgName}`, { state: { orgName } });
    let refactoredState = { ...state, orgId: orgName.name };
    dispatch(setConfigSlice(refactoredState));
    navigate(`/auth/userdashboard/${orgName.name}/clients`);
    // ${orgName} *note : it is used in url so dont remove
    // { state: { orgName } }
  };

  const DemoPaper = styled(Paper)(({ theme }) => ({
    maxWidth: 250,
    height: 'auto',
    // background: theme.palette.background.paper,
    padding: theme.spacing(2),
    ...theme.typography.body2,
    textAlign: 'center',
  }));
  return (
    <>
      <Grid container justifyContent="center" alignItems="center" spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h4" align="center">
            Welcome
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <Typography variant="body1">
            Hey <b>{orgId.toUpperCase()}</b>,{' '}
            {`You have privileges to view or manage the following organizations below`}
          </Typography>
        </Grid>
        {/* <Grid item xs={12} sm={10} md={8} style={{ textAlign: 'center' }}>
            <div>
              else create new
              <Typography
                gutterBottom
                color="primary"
                onClick={handleClickOpen('paper')}
                style={{
                  marginLeft: '5px',
                  display: 'inline',
                  cursor: 'pointer',
                }}
              >
                click here
              </Typography>
            </div>
          </Grid> */}
      </Grid>
      <Container style={{ marginTop: 25 }}>
        {loading ? (
          <Grid container justifyContent={'center'} spacing={'4'} textAlign={'center'}>
            {[...Array(4)].map((_, index) => (
              <Grid key={index} item xs={12} sm={6} md={3}>
                <Grid container direction="column" alignItems="center" spacing={2}>
                  <Grid item xs={12} md={3}>
                    <Card
                      sx={{
                        padding: 2,
                        textAlign: 'center',
                      }}
                    >
                      <Skeleton variant="rectangular" width={200} height={140} />
                      <Grid>
                        <Skeleton variant="text" width={120} />
                        <Skeleton variant="text" width={90} />
                      </Grid>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
        ) : (
          <Grid container justifyContent="center" spacing={4} textAlign="center">
            <>
              {organizations !== null &&
                organizations.length > 0 &&
                organizations.map((orgName: any, index: number) => (
                  <Grid key={index} item xs={12} sm={6} md={3}>
                    <DemoPaper variant="elevation">
                      <Grid key={index} container direction="column" alignItems="center" spacing={2}>
                        <Grid item>
                          <CardMedia
                            component="img"
                            height="120px"
                            width="100%"
                            image={imageUrl || 'https://lykrepair.com/media/pages/img_not_found.png'}
                            alt="logo"
                          />
                        </Grid>
                        <Grid item>
                          <Tooltip
                            sx={{ fontSize: '14px' }}
                            title={orgName.name.toUpperCase()}
                            arrow
                            placement="top"
                            TransitionComponent={Fade}
                            TransitionProps={{ timeout: 600 }}
                          >
                            <Typography
                              variant="h6"
                              component="div"
                              sx={{
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                                width: '200px',
                                whiteSpace: 'nowrap',
                                padding: '10px',
                              }}
                              gutterBottom
                            >
                              {orgName.name.toUpperCase()}
                            </Typography>
                          </Tooltip>
                        </Grid>
                        <Grid item>
                          <Button
                            onClick={() => handleClickNavigate(orgName)}
                            size="small"
                            variant="contained"
                            color="primary"
                            sx={{
                              borderRadius: 50,
                            }}
                          >
                            More Actions
                          </Button>
                        </Grid>
                      </Grid>
                    </DemoPaper>
                  </Grid>
                ))}
            </>
          </Grid>
        )}
        <Dialog
          open={open}
          onClose={handleClose}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogTitle id="scroll-dialog-title">Subscribe</DialogTitle>
          <DialogContent dividers={scroll === 'paper'}>
            <DialogContentText id="scroll-dialog-description">
              <form>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField label="Oraganization Name" fullWidth size="small" />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField label="Organization Title" fullWidth size="small" />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField label="Service Account" fullWidth size="small" />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField label="Client Secret" fullWidth size="small" />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField label="Public Key" multiline rows={4} fullWidth size="small" />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      type="file"
                      size="small"
                      inputProps={{
                        accept: 'image/png',
                      }}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </form>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleClose}>Save</Button>
          </DialogActions>
        </Dialog>
      </Container>
    </>
  );
}

export default MasterDashboard;
