import {
  Grid,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  TextField,
  Button,
  Snackbar,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { error, success } from '../Toast';
import { ApiHttpClient } from '../../interceptor/axiosInterceptor';
import { useDispatch, useSelector } from 'react-redux';
import { setConfigSlice } from '../../store/realmSlice';

function SettingsGeneral({ apiData, fetchData }: any) {
  const state = useSelector((state: any) => state.config);
  const orgId = state.orgId;
  const accessArray = Object.values(state.realmAccess ?? {}).flat();
  const [realmData, setRealmData] = useState(apiData);
  const [orgName, setOrgName] = useState(apiData.realm || '');
  const [displayName, setDisplayName] = useState(apiData.displayName || '');
  const [htmlName, setHtmlName] = useState(apiData.displayNameHtml || '');
  const [copied, setCopied] = useState(false);
  const initialRender = useRef(true);
  const initialState = useSelector((state: any) => state.initialConfig);
  const loginOrgId = initialState.orgId;
  const dispatch = useDispatch();
  const handleDisplayNameInputChange = (e: any) => {
    const value = e.target.value;
    setDisplayName(value);
  };
  const handleOrgNameChange = (e: any) => {
    const value = e.target.value;
    setOrgName(value);
  };

  useEffect(() => {
    checkAuthentication();
  }, [orgId]);

  const checkAuthentication = async () => {
    const response1 = await ApiHttpClient.get(
      orgId !== loginOrgId
        ? `admin/${loginOrgId}/console/whoami?currentRealm=${orgId}`
        : `admin/${orgId}/console/whoami?currentRealm=${orgId}`,
    );
    const nameResponse1 = await ApiHttpClient.get(`admin/realms/${loginOrgId}/ui-ext/realms/names?`);
    let reformedState: any = {
      ...state,
      userId: response1.userId,
      realms: nameResponse1,
      realmAccess: response1.realm_access,
    };
    dispatch(setConfigSlice(reformedState));
  };

  const handleHtmlNameInputChange = (e: any) => {
    const value = e.target.value;
    setHtmlName(value);
  };
  const handleRevert = () => {
    let data: any = realmData;
    setDisplayName(data['displayName']);
    setHtmlName(data['displayNameHtml']);
    setOrgName(data['realm']);
  };
  const handleCopyClick = () => {
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(orgName)
        .then(() => {
          setCopied(true);
          setTimeout(() => setCopied(false), 2000);
        })
        .catch((error) => {
          console.error('Failed to copy text: ', error);
        });
    } else {
      console.error('Clipboard API not supported');
    }
  };

  const handleSave = async () => {
    try {
      const url = `admin/realms/${orgId}/ui-ext`;
      const payload: any = realmData;
      payload['id'] = orgId;
      payload['realm'] = orgName;
      payload['displayName'] = displayName;
      payload['displayNameHtml'] = htmlName;
      const response = await ApiHttpClient.put(url, payload);
      fetchData();
      success('Updated Sucessfully');
    } catch (err: any) {
      console.error('Error While Updating:', err);
      error(err?.['response']?.data || 'Error While Updating');
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl fullWidth variant="outlined">
            <InputLabel htmlFor="outlined-adornment-password">Realm ID *</InputLabel>
            <OutlinedInput
              size="small"
              value={orgName}
              disabled
              id="outlined-adornment-password"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton aria-label="copy text" edge="end" onClick={handleCopyClick}>
                    <FileCopyIcon />
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
            />
            <Snackbar
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              open={copied}
              autoHideDuration={1500}
              message="Text copied to clipboard"
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField
            value={displayName}
            onChange={handleDisplayNameInputChange}
            size="small"
            fullWidth
            id="outlined-basic"
            label="Display name"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            value={htmlName}
            onChange={handleHtmlNameInputChange}
            size="small"
            fullWidth
            id="outlined-basic"
            label="HTML Display name"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            disabled={!accessArray.includes('manage-realm') && !accessArray.includes('manage-clients')}
            onClick={handleSave}
            variant="contained"
            sx={{ marginRight: '10px' }}
          >
            Save
          </Button>

          <Button
            disabled={!accessArray.includes('manage-realm') && !accessArray.includes('manage-clients')}
            onClick={handleRevert}
          >
            Revert
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

export default SettingsGeneral;
