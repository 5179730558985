export const errorNotification = (err: any) => {
  const { response } = err;
  if (response) {
    const errorString = response.data;
    const jsonString = errorString.match(/\"{.*}\"/g)?.[0];
    if (jsonString) {
      const cleanJsonString = jsonString.replace(/^"|"$/g, '');
      const message = JSON.parse(cleanJsonString);
      const error: any = {};
      if (message.errorMessage) {
        error.errorMessage = message?.errorMessage;
      }
      if (message.params && message.params.length > 1) {
        error.params = `${message.params[0]} should be greater than or equal to  ${message.params[1]} and less than or equal to ${message.params[2]}`;
      }
      return error;
    }
  } else {
    return 'Error creating client';
  }
};
