import axios, { AxiosRequestConfig, AxiosError } from 'axios';
import { KeyclockConfig } from '../auth/keycloackConfig';
import axiosRetry from 'axios-retry';
let decodedToken: string | null = null;

axios.interceptors.request.use(
  async function (config) {
    if (KeyclockConfig.getInstance()?.token) {
      config.headers.Authorization = `Bearer ${KeyclockConfig.getInstance()?.token}`;
      //   decodedToken = jwt.decode(keycloakInstance.token) as string;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

const apiInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});
axiosRetry(apiInstance, {
  retries: 1, // Number of retries
  retryCondition: (error) => {
    // Retry on 401 or 500 status codes
    return error.response?.status === 401 || error.response?.status === 500;
  },
  retryDelay: (retryCount) => {
    // Exponential backoff delay
    return Math.pow(2, retryCount) * 1000;
  },
});
class HttpApi {
  private getDefaultHeaders() {
    return {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
      'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
      Authorization: `Bearer ${KeyclockConfig.getInstance().token}`,
    };
  }

  public get(url: string, config?: AxiosRequestConfig) {
    const headers = {
      ...this.getDefaultHeaders(),
      ...(config?.headers || {}),
    };
    return apiInstance.get(url, { ...config, headers }).then((response) => response.data);
  }

  public post(url: string, data?: any, config?: AxiosRequestConfig) {
    const headers = {
      ...this.getDefaultHeaders(),
      ...(config?.headers || {}),
    };
    return apiInstance.post(url, data, { ...config, headers });
  }

  // public delete(url: string, config?: AxiosRequestConfig) {
  //   const headers = {
  //     ...this.getDefaultHeaders(),
  //     ...(config?.headers || {}),
  //   };
  //   return apiInstance.delete(url, { ...config, headers }).then((response) => response.data);
  // }

  public delete(url: string, data?: any, config?: AxiosRequestConfig) {
    const headers = {
      ...this.getDefaultHeaders(),
      ...(config?.headers || {}),
    };
    return apiInstance
      .delete(url, {
        ...config,
        headers,
        data,
      })
      .then((response) => response.data);
  }

  public put(url: string, data?: any, config?: AxiosRequestConfig) {
    const headers = {
      ...this.getDefaultHeaders(),
      ...(config?.headers || {}),
    };
    return apiInstance.put(url, data, { ...config, headers }).then((response) => response.data);
  }
}

export const ApiHttpClient = new HttpApi();

export { axios, decodedToken };
