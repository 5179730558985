import { createTheme } from '@mui/material/styles';

const lightTheme = createTheme({
  palette: {
    primary: {
      main: '#1976d2',
    },
    secondary: {
      main: '#F58634',
    },
    background: {
      default: '#fafafa',
      paper: '#fff',
    },
    text: {
      primary: '#000000',
    },
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#000000',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#000000',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#1976d2',
          },
          color: '#000000',
        },
        input: {
          '&::placeholder': {
            color: '#000000',
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: '#000000',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            color: '#1976d2',
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: '#1976d2',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          color: '#1976d2',
        },
      },
    },
  },
});

const darkTheme = createTheme({
  palette: {
    primary: {
      // main: '#bb86fc',
      main: '#a8cf45f2',
    },
    secondary: {
      main: '#03dac6',
    },
    background: {
      default: '#232323',
      paper: '#424242',
    },
    text: {
      primary: '#ffffff',
    },
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#ffffff',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#ffffff',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#a8cf45f2',
          },
          color: '#ffffff',
        },
        input: {
          '&::placeholder': {
            color: '#ffffff',
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: '#ffffff',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            color: '#a8cf45f2',
          },
          color: '#ffffff',
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: '#a8cf45f2',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          color: '#a8cf45f2',
        },
      },
    },
  },
});

export { lightTheme, darkTheme };
