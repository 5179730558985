import {
  Box,
  Button,
  Chip,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  OutlinedInput,
  Paper,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  styled,
  tableCellClasses,
  useTheme,
} from '@mui/material';
import { StyledTableRow } from '../../styles/tableStyles';
import { useEffect, useRef, useState } from 'react';
import { ApiHttpClient } from '../../interceptor/axiosInterceptor';
import { useDispatch, useSelector } from 'react-redux';
import SkeletonLoader from '../SkeletonLoader';
import dayjs from 'dayjs';
import { ArrowDropDownIcon, DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import usePopupMargin from '../../hooks/usePopupMargin';
import { paginationRowsPerPage } from '../../const/strings';
import { setConfigSlice } from '../../store/realmSlice';

function AdminEvents() {
  const state = useSelector((state: any) => state.config);
  const orgId = state.orgId;
  const initialState = useSelector((state: any) => state.initialConfig);
  const loginOrgId = initialState.orgId;
  const [loading, setLoading] = useState(true);
  const [adminevents, setAdminEvents] = useState<any[]>([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [firstValue, setFirstValue] = useState(0);
  const [eventType, setEventType] = useState([]);
  const [submittedData, setSubmittedData] = useState<any[]>([]);
  const chipRef: any = useRef(null);
  const open = Boolean(anchorEl);
  const [userId, setUserId] = useState('');
  const [fromDate, setFromDate] = useState(null);
  const [query, setQuery] = useState('');
  const [hasDeleted, setHasDeleted] = useState(false);
  const [toDate, setToDate] = useState(null);
  const [expanded, setExpanded] = useState(false);
  const [expanded1, setExpanded1] = useState(false);
  const [expanded2, setExpanded2] = useState(false);
  const [enabledEventTypes, setEnabledEventTypes] = useState<any[]>([]);
  const [value, setValue] = useState(enabledEventTypes);
  const [client, setClient] = useState('');
  const [ipAddress, setIpAddress] = useState('');
  const [realm, setRealm] = useState('');
  const [openTypes, setOpenTypes] = useState(false);
  const [openTypes1, setOpenTypes1] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedOptions, setSelectedOptions] = useState<any[]>([]);
  const [operationTypes, setOperationTypes] = useState<any[]>(['ACTION', 'CREATE', 'DELETE', 'UPDATE']);
  const [filteredOperationTypeOptions, setFilteredOperationTypeOptions] = useState<any[]>(operationTypes);
  const [resourceTypes, setResourceTypes] = useState<any[]>([
    'AUTHENTICATOR_CONFIG',
    'AUTHORIZATION_POLICY',
    'AUTHORIZATION_RESOURCE',
    'AUTHORIZATION_RESOURCE_SERVER',
    'AUTHORIZATION_SCOPE',
    'AUTH_EXECUTION',
    'AUTH_EXECUTION_FLOW',
    'AUTH_FLOW',
    'CLIENT',
    'CLIENT_INITIAL_ACCESS_MODEL',
    'CLIENT_ROLE',
    'CLIENT_ROLE_MAPPING',
    'CLIENT_SCOPE',
    'CLIENT_SCOPE_CLIENT_MAPPING',
    'CLIENT_SCOPE_MAPPING',
    'CLUSTER_NODE',
    'COMPONENT',
    'CUSTOM',
    'GROUP',
    'GROUP_MEMBERSHIP',
    'IDENTITY_PROVIDER',
    'IDENTITY_PROVIDER_MAPPER',
    'PROTOCOL_MAPPER',
    'REALM',
    'REALM_ROLE',
    'REALM_ROLE_MAPPING',
    'REALM_SCOPE_MAPPING',
    'REQUIRED_ACTION',
    'USER',
    'USER_FEDERATION_MAPPER',
    'USER_FEDERATION_PROVIDER',
    'USER_LOGIN_FAILURE',
    'USER_PROFILE',
    'USER_SESSION',
  ]);
  const [filteredOptions, setFilteredOptions] = useState<any[]>(resourceTypes);
  const [filteredResourceTypeOptions, setFilteredResourceTypeOptions] = useState<any[]>(resourceTypes);
  const [selectedOperationTypeOptions, setSelectedOperationTypeOptions] = useState<any[]>([]);
  const [expandDisplaySelectedOption, setExpandDisplaySelectedOption] = useState(false);
  const [expandDisplaySelectedOperationTypeOption, setExpandDisplaySelectedOperationTypeOption] = useState(false);
  const [count, setCount] = useState(10);
  const initialRender = useRef(true);
  const containerRef = useRef<HTMLDivElement>(null);
  const containerRef1 = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLDivElement>(null);
  const inputRef1 = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    mainDropdown: '',
    nestedDropdown1: '',
    nestedDropdown2: '',
    textField1: '',
    textField2: '',
  });
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
        setOpenTypes(false);
      }
    }

    function handleClickOutside1(event: MouseEvent) {
      if (containerRef1.current && !containerRef1.current.contains(event.target as Node)) {
        setOpenTypes1(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside1);
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside1);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  useEffect(() => {
    fetchData(query ? query : '');
  }, [orgId, page, rowsPerPage]);
  useEffect(() => {
    if (hasDeleted) {
      const dateFormat = 'YYYY-MM-DD';

      const query = [
        selectedOptions.length > 0 ? 'resourceTypes=' + selectedOptions.join('&resourceTypes=') : '',
        selectedOperationTypeOptions.length > 0
          ? 'operationTypes=' + selectedOperationTypeOptions.join('&operationTypes=')
          : '',
        userId && { label: 'User ID', value: userId },
        client && { label: 'Client', value: client },
        realm && { label: 'Realm', value: realm },
        ipAddress && { label: 'IP Address', value: ipAddress },
        fromDate && { label: 'From Date', value: dayjs(fromDate).format(dateFormat) },
        toDate && { label: 'To Date', value: dayjs(toDate).format(dateFormat) },
      ]
        .filter(Boolean)
        .join('&');
      setQuery(query);
      fetchData(query);
      setHasDeleted(false);
    }
  }, [hasDeleted, selectedOptions, selectedOperationTypeOptions, userId, realm, client, ipAddress, fromDate, toDate]);

  const handleDropdownClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const fetchData = async (query = '') => {
    try {
      const response1 = await ApiHttpClient.get(
        orgId !== loginOrgId
          ? `admin/${loginOrgId}/console/whoami?currentRealm=${orgId}`
          : `admin/${orgId}/console/whoami?currentRealm=${orgId}`,
      );
      const nameResponse1 = await ApiHttpClient.get(`admin/realms/${loginOrgId}/ui-ext/realms/names?`);
      let reformedState: any = {
        ...state,
        userId: response1.userId,
        realms: nameResponse1,
        realmAccess: response1.realm_access,
      };
      dispatch(setConfigSlice(reformedState));
      const url = query
        ? `/admin/realms/${orgId}/admin-events?${query}&first=${firstValue}&max=${rowsPerPage!}`
        : `/admin/realms/${orgId}/admin-events?first=${firstValue}&max=${rowsPerPage!}`;
      const paginationUrl = query
        ? `/admin/realms/${orgId}/admin-events?${query}`
        : `/admin/realms/${orgId}/admin-events`;
      const response = await ApiHttpClient.get(url);
      const paginationResponse = await ApiHttpClient.get(paginationUrl);

      setAdminEvents(response ? response : []);
      setCount(paginationResponse.length);

      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  const handleSubmit = () => {
    const dateFormat = 'YYYY-MM-DD';
    setPage(0);
    setFirstValue(0);
    const data = [
      selectedOptions.length > 0 && { label: 'Resource types', value: displaySelectedOptions },
      selectedOperationTypeOptions.length > 0 && {
        label: 'Operation types',
        value: displaySelectedOperationTypeOptions,
      },
      userId && { label: 'User ID', value: userId },
      client && { label: 'Client', value: client },
      realm && { label: 'Realm', value: realm },
      ipAddress && { label: 'IP Address', value: ipAddress },
      fromDate && { label: 'From Date', value: dayjs(fromDate).format(dateFormat) },
      toDate && { label: 'To Date', value: dayjs(toDate).format(dateFormat) },
    ].filter(Boolean);

    setSubmittedData(data);

    const query = [
      selectedOptions.length > 0 ? 'resourceTypes=' + selectedOptions.join('&resourceTypes=') : '',
      selectedOperationTypeOptions.length > 0
        ? 'operationTypes=' + selectedOperationTypeOptions.join('&operationTypes=')
        : '',
      userId ? `userId=${userId}` : '',
      client ? `client=${client}` : '',
      ipAddress ? `ipAddress=${ipAddress}` : '',
      fromDate ? `dateFrom=${dayjs(fromDate).format(dateFormat)}` : '',
      toDate ? `dateTo=${dayjs(toDate).format(dateFormat)}` : '',
    ]
      .filter(Boolean)
      .join('&');
    setQuery(query);
    fetchData(query ? query : '');
    setAnchorEl(null);
    handlePopoverClose();
  };

  const toggleExpand = () => {
    setExpandDisplaySelectedOption(!expandDisplaySelectedOption);
  };

  const toggleExpand1 = () => {
    setExpandDisplaySelectedOperationTypeOption(!expandDisplaySelectedOperationTypeOption);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenEventTypes = () => {
    setOpenTypes(!openTypes);
  };

  const handleOpenEventTypes1 = () => {
    setOpenTypes1(!openTypes1);
  };

  const handleSearchChange = (event: any) => {
    const value = event.target.value;
    setSearchTerm(value);
    setFilteredOptions(resourceTypes.filter((option: any) => option.toLowerCase().includes(value.toLowerCase())));
    setOpenTypes(true);
  };

  const handleSearchChange1 = (event: any) => {
    const value = event.target.value;
    setSearchTerm(value);
    setFilteredOperationTypeOptions(
      operationTypes.filter((option: any) => option.toLowerCase().includes(value.toLowerCase())),
    );
    setOpenTypes1(true);
  };

  const handleRemoveOption = (option: any) => {
    setSelectedOptions(selectedOptions.filter((o) => o !== option));
  };

  const handleRemoveOption1 = (option: any) => {
    setSelectedOperationTypeOptions(selectedOperationTypeOptions.filter((o) => o !== option));
  };

  const ResourceTypesPopUpMargin = usePopupMargin(inputRef, [selectedOptions, openTypes]);
  const OperationTypesPopUpMargin = usePopupMargin(inputRef1, [selectedOperationTypeOptions, openTypes1]);

  const handleExpand = () => {
    setExpanded1(!expanded1);
  };
  const handleExpand1 = () => {
    setExpanded2(!expanded2);
  };

  const theme = useTheme();
  const id = open ? 'filter-popover' : undefined;
  const minInitialOptionsInField = 2;
  const initialDisplayCount = 5;
  const displaySelectedOptions = expanded1 ? selectedOptions : selectedOptions.slice(0, minInitialOptionsInField);
  const displaySelectedOptions1 = expandDisplaySelectedOption
    ? selectedOptions
    : selectedOptions.slice(0, minInitialOptionsInField);
  const displaySelectedOperationTypeOptions = expanded2
    ? selectedOperationTypeOptions
    : selectedOperationTypeOptions.slice(0, minInitialOptionsInField);
  const displaySelectedOperationTypeOptions1 = expandDisplaySelectedOperationTypeOption
    ? selectedOperationTypeOptions
    : selectedOperationTypeOptions.slice(0, minInitialOptionsInField);
  const handleUserIdChange = (event: any) => setUserId(event.target.value);
  const handleClientChange = (event: any) => setClient(event.target.value);
  const handleIpAddressChange = (event: any) => setIpAddress(event.target.value);
  const handleRealmChange = (event: any) => setRealm(event.target.value);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      padding: '7px !important',
    },
  }));

  const handleSelectResourceTypeOption = (option: any) => {
    if (!selectedOptions.includes(option)) {
      setSelectedOptions([...selectedOptions, option]);
    }
    if (selectedOptions.includes(option)) {
      setSelectedOptions(selectedOptions.filter((item) => item !== option));
    }
    setSearchTerm('');
    setFilteredOptions(enabledEventTypes);
  };

  const handleOperationTypeSelectOption = (option: any) => {
    if (!selectedOperationTypeOptions.includes(option)) {
      setSelectedOperationTypeOptions([...selectedOperationTypeOptions, option]);
    }
    if (selectedOperationTypeOptions.includes(option)) {
      setSelectedOperationTypeOptions(selectedOperationTypeOptions.filter((item) => item !== option));
    }
    setSearchTerm('');
    setFilteredOperationTypeOptions(operationTypes);
  };

  const getStyles1 = (option: string, selectedOptions: string[], theme: any) => {
    return {
      background: selectedOptions.includes(option) ? '#1976d21f' : 'transparent',
      color: theme.palette.text.primary,
      cursor: 'pointer',
      padding: '8px 16px',
    };
  };

  const handleDeleteItem = (label: string, itemToDelete: string) => {
    if (label === 'Resource types') {
      setEventType((prev) => prev.filter((item) => item !== itemToDelete));
      setSelectedOptions(selectedOptions.filter((o) => o !== itemToDelete));
    }
    if (label === 'Operation types') {
      setSelectedOperationTypeOptions(selectedOperationTypeOptions.filter((o) => o !== itemToDelete));
    }
    if (label === 'User ID') {
      setUserId('');
    }
    if (label === 'Client') {
      setClient('');
    }
    if (label === 'IP Address') {
      setIpAddress('');
    }
    if (label === 'From Date') {
      setFromDate(null);
    }
    if (label === 'To Date') {
      setToDate(null);
    }

    setSubmittedData((prevData) => prevData.filter((item) => item.label !== label || item.value !== itemToDelete));
    const dateFormat = 'YYYY-MM-DD';

    const query = [
      selectedOptions.length > 0 ? 'resourceTypes=' + selectedOptions.join('&resourceTypes=') : '',
      selectedOperationTypeOptions.length > 0
        ? 'operationTypes=' + selectedOperationTypeOptions.join('&operationTypes=')
        : '',
      userId ? `user=${userId}` : '',
      client ? `client=${client}` : '',
      ipAddress ? `ipAddress=${ipAddress}` : '',
      fromDate ? `dateFrom=${dayjs(fromDate).format(dateFormat)}` : '',
      toDate ? `dateTo=${dayjs(toDate).format(dateFormat)}` : '',
    ]
      .filter(Boolean)
      .join('&');
    setQuery(query);
    setPage(0);
    setFirstValue(0);
    setHasDeleted(true);
  };

  const handleEventTypeDeleteItem = () => {
    setEventType([]);
    setPage(0);
    setFirstValue(0);
    // setSelectedOperationTypeOptions((prevData) => prevData.filter((item) => item.label !== 'Event Type'));
    setSelectedOptions([]);
    setHasDeleted(true);
  };
  const handleEventTypeDeleteItem1 = () => {
    setEventType([]);
    setPage(0);
    setFirstValue(0);
    setSelectedOperationTypeOptions([]);
    // setSelectedOptions([]);
    setHasDeleted(true);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setFirstValue(0);
  };

  const handleChangePage = (event: any, newPage: any) => {
    const firstValue = newPage * rowsPerPage;
    setFirstValue(firstValue);
    setPage(newPage);
  };
  return (
    <>
      {loading ? (
        <SkeletonLoader rows={''} />
      ) : (
        <Grid container spacing={3}>
          {/* <Grid item xs={12} sm={6}>
            <TextField size="small" fullWidth id="outlined-basic" label="Search user by event" variant="outlined" />
          </Grid> */}
          <Grid item xs={12} sm={6}>
            <Box sx={{ p: 0 }}>
              <TextField
                fullWidth
                label="Search Event"
                value={formData.nestedDropdown1}
                onClick={handleDropdownClick}
                size="small"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <ArrowDropDownIcon />
                    </InputAdornment>
                  ),
                  readOnly: true,
                }}
              />
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                sx={{ maxWidth: 1600 }}
              >
                <Box component="form" onSubmit={handleSubmit} sx={{ p: 2, width: '300px' }}>
                  <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                  >
                    <Box component="form" onSubmit={handleSubmit} sx={{ p: 2, width: 500 }}>
                      <Grid container spacing={1}>
                        <FormControl fullWidth sx={{ m: 1, mt: 1 }} ref={containerRef}>
                          <InputLabel id="demo-multiple-chip-label">Resource types</InputLabel>
                          <OutlinedInput
                            id="select-multiple-chip"
                            label="Event Saved Type"
                            size="small"
                            onClick={() => handleOpenEventTypes()}
                            onChange={(e: any) => handleSearchChange(e)}
                            // value={selectedOptions}
                            sx={{ width: '100%' }}
                            startAdornment={
                              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {displaySelectedOptions.map((option: any) => (
                                  <Chip
                                    key={option}
                                    label={option}
                                    onDelete={() => handleRemoveOption(option)}
                                    sx={{ gap: 0.5 }}
                                  />
                                ))}
                                {selectedOptions.length > minInitialOptionsInField && (
                                  <Button
                                    onClick={() => handleExpand()}
                                    sx={{
                                      // color: '#1976d2',
                                      textTransform: 'none',
                                      ml: 1,
                                    }}
                                  >
                                    {expanded1
                                      ? 'Show Less'
                                      : `${selectedOptions.length - minInitialOptionsInField} more`}
                                  </Button>
                                )}
                              </Box>
                            }
                            ref={inputRef}
                            endAdornment={
                              <InputAdornment position="end">
                                <ArrowDropDownIcon />
                              </InputAdornment>
                            }
                          />
                          {openTypes && (
                            <Paper
                              sx={{
                                position: 'absolute',
                                zIndex: 10,
                                width: '100%',
                                mt: `${ResourceTypesPopUpMargin}px`,
                                maxHeight: 200,
                                overflow: 'auto',
                                cursor: 'pointer',
                              }}
                            >
                              <List>
                                {filteredOptions.map((option, index) => (
                                  <ListItem
                                    key={option}
                                    sx={{
                                      '&:hover': {
                                        background: 'grey',
                                      },
                                    }}
                                    style={getStyles1(option, selectedOptions, theme)}
                                    onClick={() => handleSelectResourceTypeOption(option)}
                                  >
                                    {option}
                                  </ListItem>
                                ))}
                              </List>
                            </Paper>
                          )}
                        </FormControl>

                        <FormControl fullWidth sx={{ m: 1, mt: 1 }} ref={containerRef1}>
                          <InputLabel id="demo-multiple-chip-label">Operation types</InputLabel>
                          <OutlinedInput
                            id="select-multiple-chip"
                            label="Event Saved Type"
                            size="small"
                            onClick={() => handleOpenEventTypes1()}
                            onChange={(e: any) => handleSearchChange1(e)}
                            // value={selectedOptions}
                            sx={{ width: '100%' }}
                            startAdornment={
                              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {displaySelectedOperationTypeOptions.map((option: any) => (
                                  <Chip
                                    key={option}
                                    label={option}
                                    onDelete={() => handleRemoveOption1(option)}
                                    sx={{ gap: 0.5 }}
                                  />
                                ))}
                                {selectedOperationTypeOptions.length > minInitialOptionsInField && (
                                  <Button
                                    onClick={() => handleExpand1()}
                                    sx={{
                                      // color: '#1976d2',
                                      textTransform: 'none',
                                      ml: 1,
                                    }}
                                  >
                                    {expanded2
                                      ? 'Show Less'
                                      : `${selectedOperationTypeOptions.length - minInitialOptionsInField} more`}
                                  </Button>
                                )}
                              </Box>
                            }
                            ref={inputRef1}
                            endAdornment={
                              <InputAdornment position="end">
                                <ArrowDropDownIcon />
                              </InputAdornment>
                            }
                          />
                          {openTypes1 && (
                            <Paper
                              sx={{
                                position: 'absolute',
                                zIndex: 10,
                                width: '100%',
                                mt: `${OperationTypesPopUpMargin}px`,
                                maxHeight: 200,
                                overflow: 'auto',
                                cursor: 'pointer',
                              }}
                            >
                              <List>
                                {filteredOperationTypeOptions.map((option, index) => (
                                  <ListItem
                                    key={option}
                                    sx={{
                                      '&:hover': {
                                        background: 'grey',
                                      },
                                    }}
                                    style={getStyles1(option, selectedOperationTypeOptions, theme)}
                                    onClick={() => handleOperationTypeSelectOption(option)}
                                  >
                                    {option}
                                  </ListItem>
                                ))}
                              </List>
                            </Paper>
                          )}
                        </FormControl>

                        <TextField
                          size="small"
                          fullWidth
                          label="Realm"
                          variant="outlined"
                          value={realm}
                          onChange={handleRealmChange}
                          sx={{ m: 1, mt: 1 }}
                        />

                        <TextField
                          size="small"
                          fullWidth
                          label="User ID"
                          variant="outlined"
                          value={userId}
                          onChange={handleUserIdChange}
                          sx={{ m: 1, mt: 1 }}
                        />

                        <TextField
                          size="small"
                          fullWidth
                          label="Client"
                          variant="outlined"
                          value={client}
                          onChange={handleClientChange}
                          sx={{ m: 1, mt: 1 }}
                        />

                        <TextField
                          size="small"
                          fullWidth
                          label="IP Address"
                          variant="outlined"
                          value={ipAddress}
                          onChange={handleIpAddressChange}
                          sx={{ m: 1, mt: 1 }}
                        />

                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            label="From Date"
                            value={fromDate}
                            sx={{ m: 1, mt: 1 }}
                            slotProps={{ textField: { size: 'small' } }}
                            onChange={(newValue: any) => setFromDate(newValue)}
                          />
                          <DatePicker
                            label="To Date"
                            value={toDate}
                            sx={{ m: 1, mt: 1 }}
                            slotProps={{ textField: { size: 'small' } }}
                            onChange={(newValue: any) => setToDate(newValue)}
                          />
                        </LocalizationProvider>

                        <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
                          <Button fullWidth variant="contained" color="primary" onClick={handleSubmit}>
                            Submit
                          </Button>
                          <Button
                            fullWidth
                            sx={{ marginLeft: '5px' }}
                            variant="outlined"
                            color="primary"
                            onClick={handleClose}
                          >
                            Close
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                  </Popover>
                </Box>
              </Popover>
            </Box>
          </Grid>

          {submittedData && submittedData.length > 0 && (
            <Grid item xs={12} style={{ paddingTop: '0px', width: '300px' }}>
              {submittedData.map((ele, index) => {
                if (ele.label === 'Resource types' && ele.value && ele.value.length > 0 && selectedOptions.length > 0) {
                  return (
                    <Grid key={index} container spacing={2} sx={{ marginLeft: '5px', marginTop: '10px' }}>
                      <Paper>
                        <Grid item xs={12} sx={{ padding: '8px' }}>
                          <Typography component={'span'} variant="body1">
                            Resource types:
                          </Typography>
                          {displaySelectedOptions1.map((data, idx) => (
                            <Chip
                              key={idx}
                              ref={chipRef}
                              label={data}
                              onDelete={() => handleDeleteItem('Resource types', data)}
                              sx={{
                                margin: '2px',
                                maxWidth: '200px',
                                flexShrink: 1,
                              }}
                            />
                          ))}
                          {selectedOptions.length > minInitialOptionsInField && (
                            <Button
                              onClick={toggleExpand}
                              sx={{
                                textTransform: 'none',
                                ml: 1,
                              }}
                            >
                              {expandDisplaySelectedOption
                                ? 'Show less'
                                : `${selectedOptions.length - minInitialOptionsInField} more`}
                            </Button>
                          )}
                          <Button
                            onClick={() => handleEventTypeDeleteItem()}
                            sx={{
                              textTransform: 'none',
                              ml: 1,
                              marginLeft: 'auto',
                            }}
                          >
                            <CancelOutlinedIcon />
                          </Button>
                        </Grid>
                      </Paper>
                    </Grid>
                  );
                }
                if (
                  ele.label === 'Operation types' &&
                  ele.value &&
                  ele.value.length > 0 &&
                  selectedOperationTypeOptions.length > 0
                ) {
                  return (
                    <Grid container spacing={2} sx={{ marginLeft: '5px', marginTop: '10px' }}>
                      <Paper>
                        <Grid item xs={12} sx={{ padding: '8px' }}>
                          <Typography component={'span'} variant="body1">
                            Operation types:
                          </Typography>
                          {displaySelectedOperationTypeOptions1.map((data, idx) => (
                            <Chip
                              key={idx}
                              ref={chipRef}
                              label={data}
                              onDelete={() => handleDeleteItem('Operation types', data)}
                              sx={{
                                margin: '2px',
                                maxWidth: '200px',
                                flexShrink: 1,
                              }}
                            />
                          ))}
                          {selectedOperationTypeOptions.length > minInitialOptionsInField && (
                            <Button
                              onClick={toggleExpand1}
                              sx={{
                                textTransform: 'none',
                                ml: 1,
                              }}
                            >
                              {expandDisplaySelectedOperationTypeOption
                                ? 'Show less'
                                : `${selectedOperationTypeOptions.length - minInitialOptionsInField} more`}
                            </Button>
                          )}
                          <Button
                            onClick={() => handleEventTypeDeleteItem1()}
                            sx={{
                              textTransform: 'none',
                              ml: 1,
                              marginLeft: 'auto',
                            }}
                          >
                            <CancelOutlinedIcon />
                          </Button>
                        </Grid>
                      </Paper>
                    </Grid>
                  );
                }
                if (ele.label === 'Realm' && ele.value && realm) {
                  return (
                    <Grid container spacing={2} sx={{ marginLeft: '5px', marginTop: '10px' }}>
                      <Paper>
                        <Grid item xs={12} sx={{ padding: '8px' }}>
                          <Typography component={'span'} variant="body1">
                            Realm:
                          </Typography>
                          <Chip
                            key={ele.value}
                            label={ele.value}
                            sx={{
                              // backgroundColor: '#f0f0f0',
                              // color: '#1976d2',
                              margin: '2px',
                              // '& .MuiChip-deleteIcon': {
                              //   color: '#1976d2',
                              // },
                            }}
                          />
                          <Button
                            onClick={() => handleDeleteItem('Realm', ele.value)}
                            sx={{
                              // color: '#1976d2',
                              textTransform: 'none',
                              ml: 1,
                            }}
                          >
                            <CancelOutlinedIcon />
                          </Button>
                        </Grid>
                      </Paper>
                    </Grid>
                  );
                }

                if (ele.label === 'User ID' && ele.value && userId) {
                  return (
                    <Grid container spacing={2} sx={{ marginLeft: '5px', marginTop: '10px' }}>
                      <Paper>
                        <Grid item xs={12} sx={{ padding: '8px' }}>
                          <Typography component={'span'} variant="body1">
                            User ID:
                          </Typography>
                          <Chip
                            key={ele.value}
                            label={ele.value}
                            sx={{
                              // backgroundColor: '#f0f0f0',
                              // color: '#1976d2',
                              margin: '2px',
                              // '& .MuiChip-deleteIcon': {
                              //   color: '#1976d2',
                              // },
                            }}
                          />
                          <Button
                            onClick={() => handleDeleteItem('User ID', ele.value)}
                            sx={{
                              // color: '#1976d2',
                              textTransform: 'none',
                              ml: 1,
                            }}
                          >
                            <CancelOutlinedIcon />
                          </Button>
                        </Grid>
                      </Paper>
                    </Grid>
                  );
                }

                if (ele.label === 'Client' && ele.value && client) {
                  return (
                    <Grid container spacing={2} sx={{ marginLeft: '5px', marginTop: '10px' }}>
                      <Paper>
                        <Grid item xs={12} sx={{ padding: '8px' }}>
                          <Typography component={'span'} variant="body1">
                            Client:
                          </Typography>
                          <Chip
                            key={ele.value}
                            label={ele.value}
                            sx={{
                              // backgroundColor: '#f0f0f0',
                              // color: '#1976d2',
                              margin: '2px',
                              // '& .MuiChip-deleteIcon': {
                              //   color: '#1976d2',
                              // },
                            }}
                          />
                          <Button
                            onClick={() => handleDeleteItem('Client', ele.value)}
                            sx={{
                              // color: '#1976d2',
                              textTransform: 'none',
                              ml: 1,
                            }}
                          >
                            <CancelOutlinedIcon />
                          </Button>
                        </Grid>
                      </Paper>
                    </Grid>
                  );
                }

                if (ele.label === 'IP Address' && ele.value && ipAddress) {
                  return (
                    <Grid container spacing={2} sx={{ marginLeft: '5px', marginTop: '10px' }}>
                      <Paper>
                        <Grid item xs={12} sx={{ padding: '8px' }}>
                          <Typography component={'span'} variant="body1">
                            IP Address:
                          </Typography>
                          <Chip
                            key={ele.value}
                            label={ele.value}
                            sx={{
                              // backgroundColor: '#f0f0f0',
                              // color: '#1976d2',
                              margin: '2px',
                              // '& .MuiChip-deleteIcon': {
                              //   color: '#1976d2',
                              // },
                            }}
                          />
                          <Button
                            onClick={() => handleDeleteItem('IP Address', ele.value)}
                            sx={{
                              // color: '#1976d2',
                              textTransform: 'none',
                              ml: 1,
                            }}
                          >
                            <CancelOutlinedIcon />
                          </Button>
                        </Grid>
                      </Paper>
                    </Grid>
                  );
                }

                if (ele.label === 'From Date' && ele.value && fromDate) {
                  return (
                    <Grid container spacing={2} sx={{ marginLeft: '5px', marginTop: '10px' }}>
                      <Paper>
                        <Grid item xs={12} sx={{ padding: '8px' }}>
                          <Typography component={'span'} variant="body1">
                            From Date:
                          </Typography>
                          <Chip
                            key={ele.value}
                            label={ele.value}
                            sx={{
                              // backgroundColor: '#f0f0f0',
                              // color: '#1976d2',
                              margin: '2px',
                              // '& .MuiChip-deleteIcon': {
                              //   color: '#1976d2',
                              // },
                            }}
                          />
                          <Button
                            onClick={() => handleDeleteItem('From Date', ele.value)}
                            sx={{
                              // color: '#1976d2',
                              textTransform: 'none',
                              ml: 1,
                            }}
                          >
                            <CancelOutlinedIcon />
                          </Button>
                        </Grid>
                      </Paper>
                    </Grid>
                  );
                }

                if (ele.label === 'To Date' && ele.value && toDate) {
                  return (
                    <Grid container spacing={2} sx={{ marginLeft: '5px', marginTop: '10px' }}>
                      <Paper>
                        <Grid item xs={12} sx={{ padding: '8px' }}>
                          <Typography component={'span'} variant="body1">
                            To Date:
                          </Typography>
                          <Chip
                            key={ele.value}
                            label={ele.value}
                            sx={{
                              // backgroundColor: '#f0f0f0',
                              // color: '#1976d2',
                              margin: '2px',
                              // '& .MuiChip-deleteIcon': {
                              //   color: '#1976d2',
                              // },
                            }}
                          />
                          <Button
                            onClick={() => handleDeleteItem('To Date', ele.value)}
                            sx={{
                              // color: '#1976d2',
                              textTransform: 'none',
                              ml: 1,
                            }}
                          >
                            <CancelOutlinedIcon />
                          </Button>
                        </Grid>
                      </Paper>
                    </Grid>
                  );
                }

                return null;
              })}
            </Grid>
          )}

          <Grid item xs={12}>
            <TableContainer component={Paper} sx={{ maxHeight: '60vh' }}>
              <Table sx={{ minWidth: 700 }}>
                <TableHead sx={{ position: 'sticky', top: 0, zIndex: 1 }}>
                  <TableRow>
                    <StyledTableCell>Time </StyledTableCell>
                    <StyledTableCell>Resource path </StyledTableCell>
                    <StyledTableCell>Resource type </StyledTableCell>
                    <StyledTableCell>Operation type </StyledTableCell>
                    <StyledTableCell>User</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {adminevents.map((adminevents: any, idx: any) => (
                    <StyledTableRow key={idx}>
                      <StyledTableCell>{dayjs(adminevents.time).format('MMMM D, YYYY [at] h:mm A')}</StyledTableCell>
                      <StyledTableCell>{adminevents?.resourcePath}</StyledTableCell>
                      <StyledTableCell>{adminevents?.resourceType} </StyledTableCell>
                      <StyledTableCell>{adminevents?.operationType} </StyledTableCell>
                      <StyledTableCell>{adminevents?.authDetails.userId}</StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              count={count}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={(event) => handleChangeRowsPerPage(event)}
              rowsPerPageOptions={paginationRowsPerPage}
              labelRowsPerPage={'Events per Page'}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default AdminEvents;
