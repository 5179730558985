import Keycloak from 'keycloak-js';
import createConfig from '.';

export class KeyclockConfig {
  private static keycloakInstance: Keycloak | null = null;

  public static createInstance(): Keycloak {
    if (!this.keycloakInstance) {
      const keycloakConfig = createConfig();
      if (keycloakConfig?.clientId && keycloakConfig?.realm && keycloakConfig?.url) {
        this.keycloakInstance = new Keycloak(keycloakConfig);
      }
    }
    return this.keycloakInstance!;
  }

  public static getInstance(): Keycloak {
    return this.keycloakInstance ? this.keycloakInstance : this.createInstance();
  }

  public static destroyInstance(): void {
    this.keycloakInstance = null;
  }
}
