import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, styled } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { closeConfModal, closeModal } from '../store/appStateSlice';
import CloseIcon from '@mui/icons-material/Close';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    width: '450px',
    maxWidth: '90%',
  },
  '& .MuiDialogTitle-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export default function ConfirmationModal({
  children,
  title,
  handeleConfirm,
  saveButtonName = '',
  cancelClick = () => {},
  width = '450px',
  height = '',
}: {
  children: any;
  title: string;
  handeleConfirm: any;
  saveButtonName?: string;
  cancelClick?: Function;
  width?: string;
  height?: string;
}) {
  const app = useSelector((state: any) => state?.app);
  const dispatch = useDispatch();

  const handleClose = () => {
    cancelClick();
    dispatch(closeConfModal());
  };

  return (
    <Grid>
      <BootstrapDialog
        open={app?.confirmationModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          '& .MuiDialog-paper': {
            width: width,
            maxWidth: '90%',
            height: height,
          },
        }}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          {title}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>{children}</DialogContent>
        <DialogActions>
          <Button size="medium" variant="contained" autoFocus onClick={handleClose}>
            Cancel
          </Button>
          {saveButtonName && (
            <Button color="error" size="medium" variant="contained" autoFocus onClick={handeleConfirm}>
              {saveButtonName}
            </Button>
          )}
        </DialogActions>
      </BootstrapDialog>
    </Grid>
  );
}
