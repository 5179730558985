import { Grid, Container, Box, Typography, Tab, Tabs } from '@mui/material';
import Layout from '../Layout';
import React, { useEffect, useState } from 'react';

import SettingsKeys from './SettingsKeys';
import SettingsTheme from './SettingsTheme';
import SettingsEmail from './SettingsEmail';
import SettingsLogin from './SettingsLogin';
import SettingsGeneral from './SettingsGeneral';
import { useDispatch, useSelector } from 'react-redux';
import { ApiHttpClient } from '../../interceptor/axiosInterceptor';
import SkeletonLoader from '../SkeletonLoader';
import { setConfigSlice } from '../../store/realmSlice';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function Settings() {
  const [value, setValue] = React.useState(0);
  const [realmData, setRealmData] = useState({});
  const state = useSelector((state: any) => state.config);
  const orgId = state.orgId;
  const initialState = useSelector((state: any) => state.initialConfig);
  const loginOrgId = initialState.orgId;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    // if (initialRender.current) {
    //   initialRender.current = false;
    //   return;
    // }
    fetchData();
  }, [orgId]);
  const fetchData = async (query = '') => {
    try {
      const response1 = await ApiHttpClient.get(
        orgId !== loginOrgId
          ? `admin/${loginOrgId}/console/whoami?currentRealm=${orgId}`
          : `admin/${orgId}/console/whoami?currentRealm=${orgId}`,
      );
      const nameResponse1 = await ApiHttpClient.get(`admin/realms/${loginOrgId}/ui-ext/realms/names?`);
      let reformedState: any = {
        ...state,
        userId: response1.userId,
        realms: nameResponse1,
        realmAccess: response1.realm_access,
      };
      dispatch(setConfigSlice(reformedState));
      const url = `admin/realms/${orgId}`;
      const response = await ApiHttpClient.get(url);
      const apiData = response;
      if (apiData) {
        setRealmData(apiData);
        setLoading(false);
      } else {
        console.error('API returned undefined data');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };

  // if (loading) {
  //   return <SkeletonLoader rows={''} />;
  // }

  return (
    <Grid sx={{ margin: 5, marginTop: 10 }}>
      <Layout>
        {loading ? (
          <SkeletonLoader rows={''} />
        ) : (
          <Grid container spacing={3}>
            <Grid item xs={12} lg={9}>
              <Typography variant="h6">{orgId.toUpperCase()}</Typography>
              <Typography variant="body1">
                <Typography component="span" color={'red'}>
                  *Note:
                </Typography>{' '}
                Realm settings are settings that control the options for users, applications, roles, and groups in the
                current realm.
              </Typography>
            </Grid>
            <Box sx={{ width: '100%' }}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                  <Tab label="Genral" {...a11yProps(0)} />
                  <Tab label="Login" {...a11yProps(1)} />
                  <Tab label="Email" {...a11yProps(2)} />
                  <Tab label="Themes" {...a11yProps(3)} />
                  <Tab label="Keys" {...a11yProps(4)} />
                </Tabs>
              </Box>

              <CustomTabPanel value={value} index={0}>
                <SettingsGeneral apiData={realmData} fetchData={fetchData} />
              </CustomTabPanel>

              <CustomTabPanel value={value} index={1}>
                <SettingsLogin apiData={realmData} fetchData={fetchData} />
              </CustomTabPanel>

              <CustomTabPanel value={value} index={2}>
                <SettingsEmail apiData={realmData} fetchData={fetchData} />
              </CustomTabPanel>

              <CustomTabPanel value={value} index={3}>
                <SettingsTheme apiData={realmData} fetchData={fetchData} />
              </CustomTabPanel>

              <CustomTabPanel value={value} index={4}>
                <SettingsKeys />
              </CustomTabPanel>
            </Box>
          </Grid>
        )}
      </Layout>
    </Grid>
  );
}

export default Settings;
