import * as React from 'react';
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Logout from './logout';
import HomeIcon from '@mui/icons-material/Home';
import BrightnessHighIcon from '@mui/icons-material/BrightnessHigh';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import SideNav from './appcomponents/sideNav';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { FormControl, MenuItem, Select, useMediaQuery } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setConfigSlice } from '../store/realmSlice';
import { toggleTheme } from '../store/themeSlice';
import { useRef } from 'react';

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

export default function PersistentDrawerLeft({ open, handleSidebarToggle }: any) {
  const theme = useTheme();
  // const [open, setOpen] = React.useState(true);
  let navigate = useNavigate();
  const location = useLocation();
  const { orgName } = useParams();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const handleDrawerOpen = () => {
    handleSidebarToggle();
    // setOpen(true);
  };

  const handleDrawerClose = () => {
    handleSidebarToggle();
    // setOpen(false);
  };
  const dispatch = useDispatch();
  const state = useSelector((state: any) => state.config);
  const initialState = useSelector((state: any) => state.initialConfig);
  const orgId: any = state.orgId;
  const initialOrgId: any = initialState.orgId;
  const realms = state.realms;
  const validRealms = realms;
  const realmsArray = Array.isArray(validRealms) ? validRealms : [];
  const [selectedRealm, setSelectedRealm] = React.useState(orgId ? orgId : initialOrgId);

  const handleNavigate = () => {
    if (location.pathname !== '/auth/dashboard') {
      dispatch(setConfigSlice(initialState));
      setSelectedRealm(initialState.orgId);
      navigate('/auth/dashboard');
    }
  };

  React.useEffect(() => {}, [state]);

  const style = {
    padding: 1,
    marginTop: 1,
    paddingRight: 2,
    cursor: 'pointer',
  };

  const handleChange = React.useCallback(
    (event: any) => {
      const value = event.target.value;
      setSelectedRealm(value);

      const refactoredState = {
        ...state,
        orgId: value,
      };
      dispatch(setConfigSlice(refactoredState));
      if (location.pathname === '/auth/dashboard') {
        navigate(`/auth/userdashboard/${value}/clients`);
      } else {
        navigate(`/auth/userdashboard/${value}/clients`);
      }
    },
    [state],
  );

  const isDarkMode = useSelector((state: any) => state.theme.isDarkMode);
  const handleToggleDarkMode = () => {
    dispatch(toggleTheme());
  };
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleSidebarToggle}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" sx={style} onClick={handleNavigate}>
            <HomeIcon />
          </Typography>
          {!isMobile && (
            <Typography variant="h6" sx={{ flexGrow: 1 }}>
              {orgId.toUpperCase()}
            </Typography>
          )}
          <Typography variant="h6" sx={style} onClick={handleToggleDarkMode}>
            {isDarkMode ? <BrightnessHighIcon /> : <Brightness4Icon />}
          </Typography>
          <Logout />
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <FormControl
            fullWidth
            size="small"
            variant="outlined"
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            <Select value={selectedRealm} onChange={handleChange}>
              {realmsArray?.map((realm: any, index: any) => (
                <MenuItem key={index} value={realm.name}>
                  {realm.name.toUpperCase()}
                </MenuItem>
              )) || null}
            </Select>
          </FormControl>
          <IconButton onClick={handleSidebarToggle}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <SideNav open={open} />
        <Divider />
      </Drawer>
    </Box>
  );
}
