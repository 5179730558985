import { createSlice } from '@reduxjs/toolkit';

const appStateSlice = createSlice({
  name: 'appState',
  initialState: {
    previousState: 'none',
    currentState: 'Uninitialized',
    confirmationModal: false,
  },
  reducers: {
    openConfModal(state) {
      state.confirmationModal = true;
    },
    closeConfModal(state) {
      state.confirmationModal = false;
    },
  },
});

export const { openConfModal, closeConfModal } = appStateSlice.actions;

export default appStateSlice.reducer;
