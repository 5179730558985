import { createSlice } from '@reduxjs/toolkit';

const clientSlice = createSlice({
  name: 'client',
  initialState: {
    clients: [],
  },
  reducers: {
    setClientSlice(state, action) {
      state.clients = action.payload;
    },
  },
});
// `createSlice` automatically generated action creators with these names.
// export them as named exports from this "slice" file
// export const { todoAdded, todoToggled } = todosSlice.actions;
export const { setClientSlice } = clientSlice.actions;
// Export the slice reducer as the default export
export default clientSlice.reducer;
// export default todosSlice.reducer;
