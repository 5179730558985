import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  Box,
  Chip,
  MenuItem,
  TextField,
  InputAdornment,
  IconButton,
  Tooltip,
  FormControlLabel,
  Typography,
  Switch,
  Button,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ACTIONS } from '../../const/strings';
import { useSelector } from 'react-redux';
import { ApiHttpClient } from '../../interceptor/axiosInterceptor';
import { useParams } from 'react-router-dom';
import { error, success } from '../Toast';
import { errorNotification } from '../../utils/errorNotification';

function UserUpdate({ user, onDetailsChange }: any) {
  const state = useSelector((state: any) => state.config);
  const orgId = state.orgId;
  const accessArray = Object.values(state.realmAccess ?? {}).flat();
  const { userId } = useParams();
  const [actionNames, setActionNames] = useState([]);
  const [userDetails, setUserDetails]: any = useState(user);
  const [personName, setPersonName] = useState<any>([]);
  const handleSwitchChange = (event: any) => {
    const { checked } = event.target;
    setUserDetails((prevDetails: any) => ({
      ...prevDetails,
      emailVerified: checked,
    }));
  };
  const fetchData = async () => {
    try {
      const url = `/admin/realms/${orgId}/users/${userId}?userProfileMetadata=true`;
      const response = await ApiHttpClient.get(url);
      let apiData: any = response;
      setUserDetails(apiData);
      onDetailsChange(apiData);
      setPersonName(apiData.requiredActions);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    try {
      const url = `admin/realms/${orgId}/authentication/required-actions`;
      const response = await ApiHttpClient.get(url);
      const actionsData = response;
      if (actionsData) {
        const names = actionsData.map((action: any) => action.name);
        setActionNames(names);
      } else {
        console.error('API returned undefined actions data');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  const handleSave = async () => {
    try {
      let payload: any = { ...userDetails, requiredActions: personName };
      const url = `admin/realms/${orgId}/users/${userId}`;
      await ApiHttpClient.put(url, payload);
      fetchData();
      success('User updated');
    } catch (err) {
      console.error('Error updating User:', err);
      const { errorMessage } = errorNotification(err);
      error(errorMessage);
    }
  };
  const handleDropdownChange = (event: any) => {
    console.log(userDetails, event.target.value, 'val');

    userDetails.requiredActions = event.target.value;
  };
  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setUserDetails((prevDetails: any) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const formatDateTime = (timestamp: any) => {
    const date = new Date(timestamp);
    const formattedDate = date.toLocaleDateString('en-GB'); // dd/mm/yyyy
    const formattedTime = date.toLocaleTimeString('en-GB'); // hh:mm:ss
    return `${formattedDate} ${formattedTime}`;
  };
  useEffect(() => {
    fetchData();
  }, [orgId]);
  return (
    <>
      {userDetails ? (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              size="small"
              fullWidth
              id="id"
              name="id"
              label="ID"
              variant="outlined"
              value={userDetails.id}
              InputLabelProps={{ shrink: true }}
              disabled
              //   onChange={handleChange}
              //   error={!!errors.firstName}
              //   helperText={errors.firstName}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              size="small"
              fullWidth
              id="createdAt"
              name="createdAt"
              label="Created at"
              variant="outlined"
              disabled
              value={formatDateTime(userDetails.createdTimestamp)}
              //   value={formData.firstName}
              //   onChange={handleChange}
              //   error={!!errors.firstName}
              //   helperText={errors.firstName}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth size="small">
              <InputLabel id="required-user-actions-label">Required User Actions</InputLabel>
              <Select
                labelId="required-user-actions-label"
                id="required-user-actions-select"
                multiple
                value={personName}
                onChange={(e) => setPersonName(e.target.value)}
                input={<OutlinedInput id="select-multiple-chip" label="Required User Actions" />}
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((value: any) => (
                      <Chip key={value} label={value} />
                    ))}
                  </Box>
                )}
              >
                {actionNames.map((name) => (
                  <MenuItem key={name} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={2} sx={{ marginTop: '5px' }}>
            <Typography variant="subtitle1"> Email Verified</Typography>
          </Grid>
          <Grid item xs={2}>
            <FormControlLabel
              control={
                <Switch checked={userDetails.emailVerified} onChange={(e) => handleSwitchChange(e)} color="primary" />
              }
              label={userDetails.emailVerified ? 'On' : 'Off'}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6">General</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              size="small"
              fullWidth
              id="username"
              name="username"
              label="Username*"
              variant="outlined"
              value={userDetails.username}
              InputLabelProps={{ shrink: true }}
              disabled
              //   value={formData.username}
              //   onChange={handleChange}
              //   error={!!errors.username}
              //   helperText={errors.username}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              size="small"
              fullWidth
              id="email"
              name="email"
              label="Email"
              variant="outlined"
              value={userDetails?.email || ''}
              //   value={formData.email}
              onChange={handleChange}
              //   error={!!errors.email}
              //   helperText={errors.email}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              size="small"
              fullWidth
              id="firstName"
              name="firstName"
              label="First Name"
              variant="outlined"
              value={userDetails?.firstName || ''}
              onChange={handleChange}
              //   value={formData.firstName}
              //   error={!!errors.firstName}
              //   helperText={errors.firstName}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              size="small"
              fullWidth
              id="lastName"
              name="lastName"
              label="Last Name"
              variant="outlined"
              value={userDetails?.lastName || ''}
              onChange={handleChange}
              //   value={formData.lastName}
              //   error={!!errors.lastName}
              //   helperText={errors.lastName}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              disabled={!accessArray.includes('manage-users')}
              onClick={handleSave}
              variant="contained"
              sx={{ marginRight: '10px' }}
            >
              Save
            </Button>

            <Button disabled={!accessArray.includes('manage-users')} onClick={fetchData}>
              Revert
            </Button>
          </Grid>
        </Grid>
      ) : (
        <Grid>
          <Typography>Loading...</Typography>
        </Grid>
      )}
    </>
  );
}

export default UserUpdate;
