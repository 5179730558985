import { Grid, FormControl, InputLabel, Select, MenuItem, Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { ApiHttpClient } from '../../interceptor/axiosInterceptor';
import { error, success } from '../Toast';
import { useDispatch, useSelector } from 'react-redux';
import { setConfigSlice } from '../../store/realmSlice';

function SettingsTheme({ apiData, fetchData }: any) {
  const state = useSelector((state: any) => state.config);
  const orgId = state.orgId;
  const accessArray = Object.values(state.realmAccess ?? {}).flat();
  const [themeData, setThemeData]: any = useState({});
  const [loading, setLoading] = useState(true);
  const [selectedLoginTheme, setSelectedLoginTheme] = useState(apiData.loginTheme || '');
  const [selectedAccountTheme, setSelectedAccountTheme] = useState(apiData.accountTheme || '');
  const [selectedAdminTheme, setSelectedAdminTheme] = useState(apiData.adminTheme || '');
  const [selectedEmailTheme, setSelectedEmailTheme] = useState(apiData.emailTheme || '');
  const initialState = useSelector((state: any) => state.initialConfig);
  const loginOrgId = initialState.orgId;
  const dispatch = useDispatch();
  useEffect(() => {
    // if (initialRender.current) {
    //   initialRender.current = false;
    //   return;
    // }
    fetchThemeData();
  }, [orgId]);

  const fetchThemeData = async (query = '') => {
    try {
      const response1 = await ApiHttpClient.get(
        orgId !== loginOrgId
          ? `admin/${loginOrgId}/console/whoami?currentRealm=${orgId}`
          : `admin/${orgId}/console/whoami?currentRealm=${orgId}`,
      );
      const nameResponse1 = await ApiHttpClient.get(`admin/realms/${loginOrgId}/ui-ext/realms/names?`);
      let reformedState: any = {
        ...state,
        userId: response1.userId,
        realms: nameResponse1,
        realmAccess: response1.realm_access,
      };
      dispatch(setConfigSlice(reformedState));
      const url = `admin/serverinfo`;
      const response = await ApiHttpClient.get(url);
      const apiData = response;
      if (apiData) {
        console.log(apiData);
        setThemeData(apiData.themes);
        console.log(apiData, 'JJ');

        setLoading(false);
      } else {
        console.error('API returned undefined data');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  if (loading) {
    return; // Display loading message or spinner
  }
  const handleRevert = () => {
    setSelectedLoginTheme(apiData.loginTheme);
    setSelectedAccountTheme(apiData.accountTheme);
    setSelectedAdminTheme(apiData.adminTheme);
    setSelectedEmailTheme(apiData.emailTheme);
  };
  const handleSave = async () => {
    try {
      const url = `admin/realms/${orgId}/ui-ext`;
      const payload = {
        ...apiData,
        loginTheme: selectedLoginTheme,
        accountTheme: selectedAccountTheme,
        adminTheme: selectedAdminTheme,
        emailTheme: selectedEmailTheme,
      };

      const response = await ApiHttpClient.put(url, payload);
      fetchData();
      success('Updated Sucessfully');
    } catch (err: any) {
      console.error('Error While Updating:', err);
      error(err?.['response']?.data || 'Error While Updating');
    }
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FormControl fullWidth size="small">
          <InputLabel id="demo-simple-select-helper-label">Select Login theme</InputLabel>
          <Select
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            value={selectedLoginTheme}
            onChange={(e: any) => setSelectedLoginTheme(e.target.value)}
            label="Select Login theme "
          >
            {themeData.login.map((theme: any) => (
              <MenuItem key={theme.name} value={theme.name}>
                {theme.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth size="small">
          <InputLabel id="demo-simple-select-helper-label">Select Account theme</InputLabel>
          <Select
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            label="Select Account theme "
            value={selectedAccountTheme}
            onChange={(e: any) => setSelectedAccountTheme(e.target.value)}
          >
            {themeData.account.map((theme: any) => (
              <MenuItem key={theme.name} value={theme.name}>
                {theme.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth size="small">
          <InputLabel id="demo-simple-select-helper-label">Select Admin UI theme</InputLabel>
          <Select
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            label="Select Admin UI theme "
            value={selectedAdminTheme}
            onChange={(e: any) => setSelectedAdminTheme(e.target.value)}
          >
            {themeData.admin.map((theme: any) => (
              <MenuItem key={theme.name} value={theme.name}>
                {theme.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth size="small">
          <InputLabel id="demo-simple-select-helper-label">Select Email theme</InputLabel>
          <Select
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            value={selectedEmailTheme}
            onChange={(e: any) => setSelectedEmailTheme(e.target.value)}
            label="Select Email theme "
          >
            {themeData.email.map((theme: any) => (
              <MenuItem key={theme.name} value={theme.name}>
                {theme.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <Button
          disabled={!accessArray.includes('manage-realm') && !accessArray.includes('manage-clients')}
          onClick={handleSave}
          variant="contained"
          sx={{ marginRight: '5px' }}
        >
          Save
        </Button>
        <Button
          disabled={!accessArray.includes('manage-realm') && !accessArray.includes('manage-clients')}
          onClick={handleRevert}
        >
          Revert
        </Button>
      </Grid>
    </Grid>
  );
}

export default SettingsTheme;
