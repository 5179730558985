import { createSlice } from '@reduxjs/toolkit';

const loginInputSlice = createSlice({
  name: 'config',
  initialState: {
    idp: '',
    orgId: '',
    clientId: '',
    userId: '',
    realms: [],
    realmAccess: {},
  },
  reducers: {
    setConfigSlice(state, action) {
      state.idp = action.payload.idp;
      state.orgId = action.payload.orgId;
      state.clientId = action.payload.clientId;
      state.userId = action.payload.userId;
      state.realms = action.payload.realms;
      state.realmAccess = action.payload.realmAccess;
    },
  },
});
// `createSlice` automatically generated action creators with these names.
// export them as named exports from this "slice" file
// export const { todoAdded, todoToggled } = todosSlice.actions;
export const { setConfigSlice } = loginInputSlice.actions;
// Export the slice reducer as the default export
export default loginInputSlice.reducer;
// export default todosSlice.reducer;
