import { Box, Breadcrumbs, Grid, Link, Stack, Tab, Tabs, Typography } from '@mui/material';
import Layout from '../Layout';
import React from 'react';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useSelector } from 'react-redux';
import { Link as RouterLink, useParams } from 'react-router-dom';
import ClientSettings from './ClientSettings';
import ClientsRole from './ClientsRole';
import ClientsScope from './ClientsScope';
import Credentials from './Credentials';
import { ApiHttpClient } from '../../interceptor/axiosInterceptor';
import { ChangeEvent, useEffect, useState } from 'react';
import UserEvents from './UserEvents';
import AdminEvents from './AdminEvents';
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function Events() {
  const [value, setValue] = React.useState(0);
  const state = useSelector((state: any) => state.config);
  const orgId = state.orgId;
  const clientState = useSelector((state: any) => state.client);
  const { clientId } = useParams();
  const [isCredEnable, SetisCredEnable] = useState(false);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const fetchData = async () => {
    try {
      // const url = `admin/realms/${orgId}/clients/${clientId}`;
      // const response = await ApiHttpClient.get(url);
      // let apiData: any = response;
      // if (apiData?.publicClient === false && apiData?.secret) {
      //   SetisCredEnable(true);
      // }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  useEffect(() => {
    fetchData();
  }, [orgId]);

  return (
    <>
      <Grid sx={{ margin: 5, marginTop: 10 }}>
        <Layout>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={9}>
              <Typography variant="h5">Events</Typography>
              <Typography variant="body1">
                <Typography component="span" color={'red'}>
                  *Note:
                </Typography>{' '}
                Events are records of user and admin events in this realm. To configure the tracking of these events, go
                to Event configs.
              </Typography>
            </Grid>
            <Box sx={{ width: '100%' }}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                  <Tab label="User events" {...a11yProps(0)} />
                  <Tab label="Admin events" {...a11yProps(1)} />
                </Tabs>
              </Box>

              <CustomTabPanel value={value} index={0}>
                <UserEvents />
              </CustomTabPanel>

              <CustomTabPanel value={value} index={1}>
                <AdminEvents />
              </CustomTabPanel>
            </Box>
          </Grid>
        </Layout>
      </Grid>
    </>
  );
}

export default Events;
