import React, { useCallback, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Toast from './Toast';
import { Box, Grid, Typography, ThemeProvider } from '@mui/material';
import { toggleTheme } from '../store/themeSlice';
import { lightTheme, darkTheme } from '../theme';
import Navbar from './navbar';
import { RootState } from '../store';
import { toggleSidebar } from '../store/sideBarSlice';
import ProtectedRoute from '../routes/protectedRoute';
import { current } from '@reduxjs/toolkit';
import { getRequiredPermissionsForRoute } from '../utils/permissionUtils';
import { useLocation } from 'react-router-dom';

const Layout: any = ({ children }: any) => {
  const dispatch = useDispatch();
  const isDarkMode = useSelector((state: RootState) => state.theme.isDarkMode);
  const sidebarOpen = useSelector((state: any) => state.sidebar.isOpen);

  const handleSidebarToggle = () => {
    dispatch(toggleSidebar());
  };

  const handleToggleDarkMode = () => {
    dispatch(toggleTheme());
  };

  const location = useLocation(); // React Router hook to get the current location
  const currentRoute = location.pathname;
  const requiredPermission = getRequiredPermissionsForRoute(currentRoute);
  const currentTheme = isDarkMode ? darkTheme : lightTheme;

  return (
    <ThemeProvider theme={currentTheme}>
      <div
        className={`App ${isDarkMode ? 'dark' : 'light'}`}
        style={{ display: 'flex', flexDirection: 'column', minHeight: '90vh' }}
      >
        <Navbar
          open={sidebarOpen}
          handleSidebarToggle={handleSidebarToggle}
          toggleDarkMode={handleToggleDarkMode}
          isDarkMode={isDarkMode}
        />
        <Toast />
        <div
          style={{
            flex: '1 0 auto',
            marginLeft: sidebarOpen ? '220px' : '60px',
            transition: 'margin 0.3s',
            paddingLeft: '15px',
          }}
        >
          <ProtectedRoute requiredPermission={requiredPermission}>{children}</ProtectedRoute>
        </div>
        {/* <footer
          style={{
            flexShrink: 0,
            flex: '1 0 auto',
            marginLeft: sidebarOpen ? '220px' : '0px',
            transition: 'margin 0.3s',
            bottom: 0,
          }}
        >
          <Grid item>
            <Box style={{ textAlign: 'center', padding: 8 }}>
              <Typography variant="body1">Powered by INTELLAIRE</Typography>
            </Box>
          </Grid>
        </footer> */}
      </div>
    </ThemeProvider>
  );
};

export default Layout;
