import { TableCell, TableRow, styled, tableCellClasses } from '@mui/material';

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: 8,
    borderRadius: 1,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: '7px !important',
    borderRadius: 1,
  },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.action.hover,
    border: 0,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    // border: 0,
    backgroundColor: theme.palette.action.hover,
  },
}));
