import {
  Button,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  styled,
  tableCellClasses,
} from '@mui/material';
import Layout from '../Layout';
import { useDispatch, useSelector } from 'react-redux';
import { StyledTableRow } from '../../styles/tableStyles';
import { useEffect, useRef, useState } from 'react';
import { ApiHttpClient } from '../../interceptor/axiosInterceptor';
import Model from '../model';
import { error, success } from '../Toast';
import SkeletonLoader from '../SkeletonLoader';
import useDebouncedSearch from '../../hooks/useDebouncedSearch';
import { pagination } from '../../utils/pagination';
import { paginationRowsPerPage } from '../../const/strings';
import { setConfigSlice } from '../../store/realmSlice';

function Authentication() {
  const state = useSelector((state: any) => state.config);
  const orgId = state.orgId;
  const initialState = useSelector((state: any) => state.initialConfig);
  const loginOrgId = initialState.orgId;
  const dispatch = useDispatch();
  const [flows, setFlows] = useState([]);
  const [loading, setLoading] = useState(true);
  const initialRender = useRef(true);
  const [count, setCount] = useState(10);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [firstValue, setFirstValue] = useState(0);
  const [searchTerm, setSearchTerm] = useDebouncedSearch((debouncedTerm: any) => {
    fetchData(debouncedTerm);
  });
  const [openModal, setOpenModal] = useState(false);
  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      return;
    }
    fetchData(searchTerm);
  }, [orgId, page, rowsPerPage]);

  const fetchData = async (query = '') => {
    try {
      const response1 = await ApiHttpClient.get(
        orgId !== loginOrgId
          ? `admin/${loginOrgId}/console/whoami?currentRealm=${orgId}`
          : `admin/${orgId}/console/whoami?currentRealm=${orgId}`,
      );
      const nameResponse1 = await ApiHttpClient.get(`admin/realms/${loginOrgId}/ui-ext/realms/names?`);
      let reformedState: any = {
        ...state,
        userId: response1.userId,
        realms: nameResponse1,
        realmAccess: response1.realm_access,
      };
      dispatch(setConfigSlice(reformedState));
      const url = `/admin/realms/${orgId}/ui-ext/authentication-management/flows?first=${firstValue}&max=${rowsPerPage!}`;
      const response = await ApiHttpClient.get(url);
      const filteredValues = response.filter((item: any) => {
        const lowerCaseQuery = query.toLowerCase();
        return (
          item.alias.toLowerCase().includes(lowerCaseQuery) || item.description.toLowerCase().includes(lowerCaseQuery)
          // item.providerId.toLowerCase().includes(lowerCaseQuery) // This line should be removed
        );
      });
      const paginatedResponse = pagination(query ? filteredValues : response, page, rowsPerPage);
      query ? setCount(filteredValues.length) : setCount(response.length);
      query ? setFlows(filteredValues) : setFlows(paginatedResponse);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  // Table data code starts

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      padding: '7px !important',
    },
  }));

  // Table data code ends

  // Modal pop up code starts
  const handleClose = () => {
    setOpenModal(false);
  };

  const [formValues, setFormValues] = useState({
    alias: '',
    description: '',
    flowType: 'basic-flow',
  });

  const handleSearchInputChange = (e: any) => {
    const value = e.target.value;
    setSearchTerm(value);
    setFirstValue(0);
    setPage(0);
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = async () => {
    try {
      const url = `/admin/realms/${orgId}/authentication/flows`;
      const response = await ApiHttpClient.post(url, {
        alias: formValues.alias,
        description: formValues.description,
        providerId: formValues.flowType,
        builtIn: false,
        topLevel: true,
      });

      fetchData();
      handleClose();
      setFormValues({
        alias: '',
        description: '',
        flowType: 'basic-flow',
      });
      success('Flow created successfully');
    } catch (err) {
      console.error('Error creating flow:', err);
      handleClose();
      setFormValues({
        alias: '',
        description: '',
        flowType: 'basic-flow',
      });
      error('Error in creating Flow');
    }
  };

  const handleChangePage = (event: any, newPage: any) => {
    const firstValue = newPage * rowsPerPage;
    setFirstValue(firstValue);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setFirstValue(0);
  };
  // Modal pop up code Ends

  return (
    <>
      <Grid sx={{ margin: 5, marginTop: 10 }}>
        <Layout>
          {loading ? (
            <SkeletonLoader rows={''} />
          ) : (
            <Grid container spacing={3}>
              <Grid item xs={12} lg={9}>
                <Typography variant="h6">Authentication</Typography>
                <Typography variant="body1">
                  <Typography component="span" color={'red'}>
                    *Note:
                  </Typography>{' '}
                  Authentication is the area where you can configure and manage different credential types.
                </Typography>
              </Grid>
              {/* <Grid item xs={12} lg={3}>
                <Typography variant="h6" textAlign="right">
                  Org: {orgId}
                </Typography>
              </Grid> */}

              <Grid item xs={12} sm={6}>
                <TextField
                  size="small"
                  fullWidth
                  type="search"
                  id="outlined-basic"
                  label="Search for Flow"
                  variant="outlined"
                  value={searchTerm}
                  onChange={handleSearchInputChange}
                />
              </Grid>
              {/* <Grid item xs={12} sm={6} textAlign="right">
                <Button size="medium" variant="contained" onClick={handleClickOpen}>
                  Create Flow
                </Button>
              </Grid> */}

              <Grid item xs={12}>
                <TableContainer component={Paper} sx={{ maxHeight: '60vh' }}>
                  <Table sx={{ minWidth: 700 }}>
                    <TableHead sx={{ position: 'sticky', top: 0, zIndex: 1 }}>
                      <TableRow>
                        <StyledTableCell>S.No </StyledTableCell>
                        <StyledTableCell>Flow name</StyledTableCell>
                        <StyledTableCell>Used by</StyledTableCell>
                        <StyledTableCell>Description</StyledTableCell>
                        {/* <StyledTableCell>Action</StyledTableCell> */}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {flows.map((flow: any, index) => (
                        <StyledTableRow key={flow.id}>
                          <StyledTableCell component="th" scope="row">
                            {page * rowsPerPage + index + 1}
                          </StyledTableCell>
                          <StyledTableCell>{flow.alias}</StyledTableCell>
                          <StyledTableCell>
                            {flow?.usedBy?.values ? `${flow?.usedBy?.values?.join(' ')} flow` : 'Not in use'}
                          </StyledTableCell>
                          <StyledTableCell>{flow.description}</StyledTableCell>
                          {/* <StyledTableCell>
                            <Typography sx={{ cursor: 'pointer' }} component="span" color={'primary'}>
                              <DifferenceIcon />
                            </Typography>
                          </StyledTableCell> */}
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  component="div"
                  count={count}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={(event) => handleChangeRowsPerPage(event)}
                  rowsPerPageOptions={paginationRowsPerPage}
                  labelRowsPerPage={'Rows per Page'}
                />
              </Grid>
            </Grid>
          )}

          <Model
            open={openModal}
            handeleConfirm={handleSubmit}
            title={'Create Flow'}
            saveButtonName={'Create'}
            data-modal-backdrop="static"
            height="auto"
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  size="small"
                  fullWidth
                  id="Name"
                  name="alias"
                  label="Name *"
                  variant="outlined"
                  value={formValues.alias}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  size="small"
                  fullWidth
                  id="Description"
                  name="description"
                  label="Description"
                  variant="outlined"
                  value={formValues.description}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Flow type</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Flow type"
                    value={formValues.flowType}
                    onChange={handleChange}
                  >
                    <MenuItem value="basic-flow">Basic Flow</MenuItem>
                    <MenuItem value="client-flow">Client Flow</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Model>
        </Layout>
      </Grid>
    </>
  );
}

export default Authentication;
