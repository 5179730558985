import React from 'react';
import { useSelector } from 'react-redux';

const ProtectedRoute = ({ children, requiredPermission }: any) => {
  const state = useSelector((state: any) => state.config);

  const accessArray = Object.values(state.realmAccess ?? {}).flat();

  const location = window.location.pathname;
  if (location !== '/auth/dashboard') {
    if (location.includes('authentication')) {
      const hasPermission = requiredPermission.every((ele: any) => accessArray.includes(ele));
      return hasPermission ? children : <div>Foebidden, permission needed</div>;
    } else {
      const hasPermission = accessArray.some((ele: any) => requiredPermission.includes(ele));
      return hasPermission ? children : <div>Forbidden, permission needed</div>;
    }
  } else {
    return children;
  }
};

export default ProtectedRoute;
