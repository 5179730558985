import {
  Grid,
  Button,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  styled,
  TableCell,
  tableCellClasses,
  Link,
  TablePagination,
  CardMedia,
  Fade,
  Tooltip,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  useTheme,
  Card,
  Skeleton,
  Box,
  CardContent,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import Layout from '../Layout';
import { StyledTableRow } from '../../styles/tableStyles';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Link as RouterLink, useParams } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import { useEffect, useRef, useState } from 'react';
import { ApiHttpClient } from '../../interceptor/axiosInterceptor';
import useDebouncedSearch from '../../hooks/useDebouncedSearch';
import SkeletonLoader from '../SkeletonLoader';
import { error, success } from '../Toast';
import ConfirmationDialog from '../deleteModal';
import { setClientSlice } from '../../store/clientSlice';
import { paginationRowsPerPage } from '../../const/strings';
import PersonIcon from '@mui/icons-material/Person';
import { setConfigSlice } from '../../store/realmSlice';

function Clients() {
  const imageUrl = '/noimageavailable.png';
  const state = useSelector((state: any) => state.config);
  const initialState = useSelector((state: any) => state.initialConfig);
  const orgId = state.orgId;
  const loginOrgId = initialState.orgId;
  const realmAccess = state.realmAccess;
  const initialRender = useRef(true);
  const dispatch = useDispatch();
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [selectedRowIndex, setSelectedRowIndex] = useState(-1);
  const [count, setCount] = useState(10);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [firstValue, setFirstValue] = useState(0);
  const [searchTerm, setSearchTerm] = useDebouncedSearch((debouncedTerm: any) => {
    fetchData(debouncedTerm);
  });
  const [dialogContent, setDialogContent] = useState<any>({});
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const accessArray = Object.values(state.realmAccess ?? {}).flat();
  let navigate = useNavigate();
  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      return;
    }
    // if (accessArray.includes('query-clients')) {
    fetchData(searchTerm);
    //}
  }, [orgId, page, rowsPerPage]);

  const handleOpenConfirmation = (index: any, userId: any) => {
    setSelectedUserId(userId);
    setSelectedRowIndex(index);
    setOpenConfirmation(true);
  };

  const handleSearchInputChange = (e: any) => {
    const value = e.target.value;
    setSearchTerm(value);
    setFirstValue(0);
    setPage(0);
  };

  const fetchData = async (query = '') => {
    try {
      const response1 = await ApiHttpClient.get(
        orgId !== loginOrgId
          ? `admin/${loginOrgId}/console/whoami?currentRealm=${orgId}`
          : `admin/${orgId}/console/whoami?currentRealm=${orgId}`,
      );
      const nameResponse1 = await ApiHttpClient.get(`admin/realms/${loginOrgId}/ui-ext/realms/names?`);
      let reformedState: any = {
        ...state,
        userId: response1.userId,
        realms: nameResponse1,
        realmAccess: response1.realm_access,
      };
      dispatch(setConfigSlice(reformedState));

      const hiddenClientIds = ['account', 'account-console', 'admin-cli', 'broker', 'realm-management', 'admin-cli'];
      const desiredRecords = rowsPerPage;
      let allFilteredData: any = [];
      let currentFirstValue = firstValue;

      const fetchChunk = async (firstValue: any, remainingRecords: any) => {
        const fetchSize = remainingRecords * 2;
        const url = query
          ? `admin/realms/${orgId}/clients?first=${firstValue}&max=${fetchSize}&clientId=${query}&search=true`
          : `admin/realms/${orgId}/clients?first=${firstValue}&max=${fetchSize}`;

        const response = await ApiHttpClient.get(url);
        const apiData = response.filter((client: any) => !hiddenClientIds.includes(client.clientId));

        allFilteredData = [...allFilteredData, ...apiData];

        if (allFilteredData.length >= desiredRecords || response.length < fetchSize) {
          return;
        }

        await fetchChunk(firstValue + fetchSize, desiredRecords - allFilteredData.length);
      };

      await fetchChunk(currentFirstValue, desiredRecords);

      const filteredData = allFilteredData.slice(0, desiredRecords);
      setClients(filteredData);

      const countUrl = query
        ? `admin/realms/${orgId}/clients?clientId=${query}&search=true`
        : `admin/realms/${orgId}/clients`;
      const countResponse = await ApiHttpClient.get(countUrl);
      const totalCount = countResponse.length;

      setCount(totalCount);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      padding: '7px !important',
    },
  }));

  const handleClickNavigate = (orgId: any) => {
    navigate(`/auth/userdashboard/${orgId}/clients/createclient`);
  };

  const handleClickNavigateToApplication = (client: any) => {
    if (client.baseUrl) {
      window.open(client.baseUrl, '_blank');
    } else {
      console.error('No base URL available for client:', client.clientId);
    }
  };

  const handleDeleteRow = async (index: number, row: any) => {
    try {
      const url = `/admin/realms/${orgId}/clients/${row.id}`;
      const response = await ApiHttpClient.delete(url, {});
      console.log(response, 'response');

      if (response === '') {
        fetchData();
        console.log('App deleted sucessfully');
        success('App Deleted Sucessfully');
        setOpenConfirmation(false);
        setSearchTerm('');
      } else {
        console.error('Failed to delete App:', response.statusText);
        error('Failed to delete App');
        setOpenConfirmation(false);
        setSearchTerm('');
      }
    } catch (err) {
      console.error('Error deleting App:', err);
      error('Error deleting App');
      setOpenConfirmation(false);
    }
  };

  const handleClickOpenDialog = (description: any) => {
    setDialogContent(description);
    setOpen(true);
  };

  const handleChangePage = (event: any, newPage: any) => {
    const firstValue = newPage * rowsPerPage;
    setFirstValue(firstValue);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setFirstValue(0);
  };

  const DemoPaper = styled(Paper)(({ theme }) => ({
    // maxWidth: 250,
    maxWidth: '330px',
    height: 'auto',
    // height: '120px',
    // background: theme.palette.background.paper,
    padding: '2px 0px',
    ...theme.typography.body2,
    textAlign: 'center',
  }));

  const handleClose = () => {
    setOpen(false);
    setDialogContent({});
  };

  const selectedClient = (client: any) => {
    dispatch(setClientSlice(client));
  };

  const CustomTextField = styled(TextField)(({ theme }) => ({
    '& label[data-shrink=false] + .MuiInputBase-formControl .MuiOutlinedInput-input': {
      border: '1px solid #ffffffa1',
    },
  }));

  return (
    <>
      <Grid sx={{ margin: 5, marginTop: 10 }}>
        <Layout>
          {loading ? (
            <Grid container justifyContent={'center'} spacing={'4'} textAlign={'center'}>
              {[...Array(4)].map((_, index) => (
                <Grid key={index} item xs={12} sm={6} md={3}>
                  <Grid container direction="column" alignItems="center" spacing={2}>
                    <Grid item xs={12} md={3}>
                      <Card
                        sx={{
                          padding: 2,
                          textAlign: 'center',
                        }}
                      >
                        <Skeleton variant="rectangular" width={200} height={140} />
                        <Grid>
                          <Skeleton variant="text" width={120} />
                          <Skeleton variant="text" width={90} />
                        </Grid>
                      </Card>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          ) : (
            <>
              <Grid container spacing={3}>
                <Grid item xs={12} lg={9}>
                  <Typography variant="h6">Apps</Typography>
                  <Typography variant="body1">
                    <Typography component="span" color={'red'}>
                      *Note:
                    </Typography>{' '}
                    Apps are applications and services that can request authentication of a user.
                  </Typography>
                </Grid>
                <Grid item xs={12} lg={3}>
                  <Typography variant="h6" textAlign="right">
                    Org: {orgId.toUpperCase()}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    size="small"
                    type="search"
                    fullWidth
                    id="outlined-basic"
                    label="Search for App"
                    variant="outlined"
                    autoComplete="off"
                    value={searchTerm}
                    onChange={handleSearchInputChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6} textAlign="right">
                  {accessArray.includes('manage-clients') && (
                    <Button size="medium" variant="contained" onClick={() => handleClickNavigate(orgId)}>
                      Create App
                    </Button>
                  )}
                </Grid>
              </Grid>
              <Grid container justifyContent="center" marginTop={'10px'} spacing={4} textAlign="center">
                <>
                  {clients.map((client: any, index) => (
                    <Grid key={index} item xs={14} sm={6} md={3}>
                      <DemoPaper variant="elevation">
                        <CardContent sx={{ padding: '0px', paddingBottom: '0px !important' }}>
                          <Grid container spacing={0}>
                            <Grid
                              item
                              xs={5}
                              sx={{
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                                width: '80px',
                                textAlign: 'left',
                                whiteSpace: 'nowrap',
                                paddingLeft: '10px',
                              }}
                            >
                              <Tooltip
                                title={client.clientId}
                                arrow
                                placement="top"
                                TransitionComponent={Fade}
                                TransitionProps={{ timeout: 600 }}
                              >
                                <Typography component="span" variant="h6">
                                  <Link
                                    underline="hover"
                                    color="primary"
                                    component={RouterLink}
                                    onClick={() => selectedClient(client)}
                                    to={`/auth/userdashboard/${orgId}/clients/${client.id}/clientdetails`}
                                  >
                                    {client.clientId}
                                  </Link>
                                </Typography>
                              </Tooltip>
                            </Grid>

                            <Grid
                              item
                              xs={5}
                              sx={{
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                                width: '80px',
                                textAlign: 'left',
                                whiteSpace: 'nowrap',
                                paddingLeft: '10px',
                                paddingTop: '6px',
                              }}
                            >
                              <Tooltip
                                title={client.protocol}
                                arrow
                                placement="top"
                                TransitionComponent={Fade}
                                TransitionProps={{ timeout: 600 }}
                              >
                                <Typography
                                  variant="subtitle2"
                                  component="span"
                                  gutterBottom
                                  sx={{
                                    fontSize: '12px',
                                    lineHeight: '0',
                                    border: '1px solid lightgray',
                                    borderRadius: '50px',
                                    padding: '3px',
                                    color: '#555555',
                                  }}
                                >
                                  {client.protocol}
                                </Typography>
                              </Tooltip>
                            </Grid>

                            <Grid item xs={2} sx={{ textAlign: 'end' }}>
                              {/* <Tooltip title="Delete" arrow sx={{ padding: '4px' }}>
                                <IconButton
                                  disabled={!accessArray.includes('manage-clients')}
                                  onClick={() => handleOpenConfirmation(index, client)}
                                  color="error"
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Tooltip> */}
                            </Grid>
                            <Grid item xs={5} sx={{ marginTop: '5px' }}>
                              <Typography
                                variant="subtitle2"
                                component="span"
                                gutterBottom
                                sx={{
                                  color: '#868585',
                                }}
                              >
                                Description:
                              </Typography>
                            </Grid>
                            <Grid item xs={7} sx={{ marginTop: '5px' }}>
                              <Tooltip
                                title={client.description}
                                arrow
                                placement="top"
                                TransitionComponent={Fade}
                                TransitionProps={{ timeout: 600 }}
                              >
                                <Typography
                                  variant="subtitle2"
                                  sx={{
                                    textOverflow: 'ellipsis',
                                    overflow: 'hidden',
                                    width: '143px',
                                    textAlign: 'left',
                                    whiteSpace: 'nowrap',
                                    color: '#615B5B',
                                  }}
                                  gutterBottom
                                >
                                  {client.description}
                                </Typography>
                              </Tooltip>
                            </Grid>

                            <Grid item xs={5} sx={{ marginTop: '5px' }}>
                              <Typography
                                variant="subtitle2"
                                component="span"
                                gutterBottom
                                sx={{
                                  color: '#868585',
                                  display: 'flex',
                                  paddingLeft: '11px',
                                }}
                              >
                                Base URL:
                              </Typography>
                            </Grid>
                            <Grid item xs={7} sx={{ marginTop: '5px' }}>
                              <Tooltip
                                title={client.baseUrl}
                                arrow
                                placement="top"
                                TransitionComponent={Fade}
                                TransitionProps={{ timeout: 600 }}
                              >
                                <Typography
                                  variant="subtitle2"
                                  sx={{
                                    textOverflow: 'ellipsis',
                                    overflow: 'hidden',
                                    width: '143px',
                                    textAlign: 'left',
                                    whiteSpace: 'nowrap',
                                    color: '#4D8BD7',
                                  }}
                                  gutterBottom
                                >
                                  {client.baseUrl}
                                </Typography>
                              </Tooltip>
                            </Grid>

                            <Grid item xs={12} sx={{ textAlign: 'left', padding: '6px 0px 10px 10px' }}>
                              <Button
                                onClick={() => handleClickNavigateToApplication(client)}
                                size="small"
                                variant="contained"
                                color="primary"
                                sx={{
                                  borderRadius: 50,
                                  fontSize: '12px',
                                }}
                              >
                                Visit Application
                              </Button>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </DemoPaper>
                    </Grid>
                  ))}
                  <Dialog open={open} onClose={handleClose}>
                    <DialogTitle>App Details</DialogTitle>
                    <DialogContent>
                      <Table>
                        <TableBody>
                          <TableRow>
                            <StyledTableCell>Protocol</StyledTableCell>
                            <StyledTableCell>{dialogContent.protocol}</StyledTableCell>
                          </TableRow>
                          <TableRow>
                            <StyledTableCell>Description</StyledTableCell>
                            <StyledTableCell>{dialogContent.description}</StyledTableCell>
                          </TableRow>
                          <TableRow>
                            <StyledTableCell>Base URL</StyledTableCell>
                            <StyledTableCell>{dialogContent.baseUrl}</StyledTableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleClose} color="primary">
                        Close
                      </Button>
                    </DialogActions>
                  </Dialog>
                </>
              </Grid>
              <TablePagination
                component="div"
                count={count}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={(event) => handleChangeRowsPerPage(event)}
                rowsPerPageOptions={paginationRowsPerPage}
                labelRowsPerPage={'Apps per Page'}
              />
              {/* <Grid item xs={12} sx={{ marginTop: '10px' }}>
                <TableContainer component={Paper} sx={{ maxHeight: '60vh' }}>
                  <Table sx={{ minWidth: 700 }}>
                    <TableHead sx={{ position: 'sticky', top: 0, zIndex: 1 }}>
                      <TableRow>
                        <StyledTableCell>S.No </StyledTableCell>
                        <StyledTableCell>Client ID</StyledTableCell>
                        <StyledTableCell>Name</StyledTableCell>
                        <StyledTableCell>Type</StyledTableCell>
                        <StyledTableCell>Description</StyledTableCell>
                        <StyledTableCell>Home URL</StyledTableCell>
                        <StyledTableCell>Action</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {clients.length > 0 &&
                        clients.map((client: any, index: any) => {
                          return (
                            <StyledTableRow key={client.id}>
                              <StyledTableCell component="th" scope="row">
                                {page * rowsPerPage + index + 1}
                              </StyledTableCell>
                              <StyledTableCell component="th" scope="row">
                                <Link
                                  underline="hover"
                                  key="1"
                                  color="primary"
                                  component={RouterLink}
                                  onClick={() => selectedClient(client)}
                                  to={`/auth/userdashboard/${orgId}/clients/${client.clientId}/clientdetails`}
                                >
                                  {client.clientId}
                                </Link>
                              </StyledTableCell>
                              <StyledTableCell>{client.name}</StyledTableCell>
                              <StyledTableCell>{client.protocol}</StyledTableCell>
                              <StyledTableCell>{client.description}</StyledTableCell>
                              <StyledTableCell>{client.baseUrl}</StyledTableCell>
                              <StyledTableCell>
                                <Typography
                                  // onClick={() => handleDeleteRow(index, client)}
                                  onClick={() => handleOpenConfirmation(index, client)}
                                  sx={{ cursor: 'pointer' }}
                                  component="span"
                                  color={'red'}
                                >
                                  <DeleteIcon />
                                </Typography>
                              </StyledTableCell>
                            </StyledTableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  component="div"
                  count={count}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={(event) => handleChangeRowsPerPage(event)}
                  rowsPerPageOptions={paginationRowsPerPage}
                  labelRowsPerPage={'Clients per Page'}
                />
              </Grid> */}
            </>
          )}
          <ConfirmationDialog
            open={openConfirmation}
            onClose={() => setOpenConfirmation(false)}
            title="Confirm Deletion"
            content="Are you sure you want to delete this App?"
            onConfirm={() => handleDeleteRow(selectedRowIndex, selectedUserId)}
          />
        </Layout>
      </Grid>
    </>
  );
}

export default Clients;
