import { VisibilityOff, Visibility } from '@material-ui/icons';
import {
  Grid,
  Typography,
  TextField,
  FormControlLabel,
  Checkbox,
  Switch,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Button,
} from '@mui/material';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { ApiHttpClient } from '../../interceptor/axiosInterceptor';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { error, success } from '../Toast';
import { setConfigSlice } from '../../store/realmSlice';

function SettingsEmail({ apiData, fetchData }: any) {
  const [showPassword, setShowPassword] = React.useState(false);
  const [switchChecked, setSwitchChecked] = useState(false);
  const [Username, setEmails] = useState('');
  const { orgName } = useParams();
  const state = useSelector((state: any) => state.config);
  const orgId = state.orgId;
  const accessArray = Object.values(state.realmAccess ?? {}).flat();
  const userId = state.userId;
  const initialState = useSelector((state: any) => state.initialConfig);
  const loginOrgId = initialState.orgId;
  const dispatch = useDispatch();
  const [form, setFormData] = useState({
    from: apiData?.smtpServer?.from || '',
    fromDisplayName: apiData?.smtpServer?.fromDisplayName,
    replyTo: apiData?.smtpServer?.replyTo,
    replyToDisplayName: apiData?.smtpServer?.replyToDisplayName,
    envelopeFrom: apiData?.smtpServer?.envelopeFrom,
    host: apiData?.smtpServer?.host,
    port: apiData?.smtpServer?.port,
    ssl: apiData?.smtpServer?.ssl === 'true' ? true : false,
    starttls: apiData?.smtpServer?.starttls === 'true' ? true : false,
    auth: apiData?.smtpServer?.auth,
  });

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };
  const handleSwitchChange = () => {
    setSwitchChecked(!switchChecked);
  };
  const setData = () => {
    setFormData({
      from: apiData?.smtpServer?.from || '',
      fromDisplayName: apiData?.smtpServer?.fromDisplayName || '',
      replyTo: apiData?.smtpServer?.replyTo || '',
      replyToDisplayName: apiData?.smtpServer?.replyToDisplayName || '',
      envelopeFrom: apiData?.smtpServer?.envelopeFrom || '',
      host: apiData?.smtpServer?.host || '',
      port: apiData?.smtpServer?.port || '',
      ssl: apiData?.smtpServer?.ssl === 'true' ? true : false || false,
      starttls: apiData?.smtpServer?.starttls === 'true' ? true : false || false,
      auth: apiData?.smtpServer?.auth,
    });
  };
  useEffect(() => {
    fetchEmailData();
    setData();
  }, [apiData]);
  const handleInputChange = (event: any) => {
    const { name, value, type, checked } = event.target;
    console.log(name, 'JJJ');

    console.log(name, value, type, checked, '&&&');

    setFormData({
      ...form,
      [name]: type === 'checkbox' ? checked : value,
    });
  };
  const handleSave = async () => {
    try {
      const url = `admin/realms/${orgId}/ui-ext`;
      const payload = { ...apiData, smtpServer: { ...form } };
      console.log(form, 'form');

      const response = await ApiHttpClient.put(url, payload);
      fetchData();
      success('Updated Successfully');
    } catch (err) {
      console.error('Error While Updating:', err);
      error('Error While Updating');
    }
  };

  const fetchEmailData = async () => {
    try {
      const response1 = await ApiHttpClient.get(
        orgId !== loginOrgId
          ? `admin/${loginOrgId}/console/whoami?currentRealm=${orgId}`
          : `admin/${orgId}/console/whoami?currentRealm=${orgId}`,
      );
      const nameResponse1 = await ApiHttpClient.get(`admin/realms/${loginOrgId}/ui-ext/realms/names?`);
      let reformedState: any = {
        ...state,
        userId: response1.userId,
        realms: nameResponse1,
        realmAccess: response1.realm_access,
      };
      dispatch(setConfigSlice(reformedState));
      const url = `admin/realms/${orgName}/users/${userId}`;
      const response = await ApiHttpClient.get(url);
      const Username = response.username;
      console.log(Username, 'response');

      setEmails(Username);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Typography variant="h6">Template</Typography>
        <Grid item xs={12}>
          <TextField
            name="from"
            value={form.from}
            size="small"
            fullWidth
            id="outlined-basic"
            label="From Sender Email Address"
            variant="outlined"
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="fromDisplayName"
            value={form.fromDisplayName}
            size="small"
            fullWidth
            id="outlined-basic"
            label="Display Name for Sender Email Address"
            variant="outlined"
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="replyTo"
            value={form.replyTo}
            size="small"
            fullWidth
            id="outlined-basic"
            label="Reply to Email Adress"
            variant="outlined"
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="replyToDisplayName"
            value={form.replyToDisplayName}
            size="small"
            fullWidth
            id="outlined-basic"
            label="Display Name for Reply to Email Address"
            variant="outlined"
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="envelopeFrom"
            value={form.envelopeFrom}
            size="small"
            fullWidth
            id="outlined-basic"
            label="Sender Envelope Email Address"
            variant="outlined"
            onChange={handleInputChange}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ marginTop: '5px' }}>
        <Typography variant="h6">Connection & Authentication</Typography>
        <Grid item xs={12}>
          <TextField
            name="host"
            value={form.host}
            size="small"
            fullWidth
            id="outlined-basic"
            label="SMTP Host"
            variant="outlined"
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="port"
            value={form.port}
            size="small"
            fullWidth
            id="outlined-basic"
            label="SMTP port"
            variant="outlined"
            onChange={handleInputChange}
          />
        </Grid>
        <Grid container spacing={0} sx={{ marginTop: '3px' }}>
          <Grid item xs={3}>
            <Typography variant="h6">Encryption</Typography>
          </Grid>
          <Grid item xs={3}>
            <FormControlLabel
              control={<Checkbox checked={form.ssl} name="ssl" onChange={handleInputChange} />}
              label="Enable SSL"
            />
          </Grid>
          <Grid item xs={3}>
            <FormControlLabel
              control={<Checkbox checked={form.starttls} name="starttls" onChange={handleInputChange} />}
              label="Enable StartTLS"
            />
          </Grid>
        </Grid>

        <Grid container spacing={0} sx={{ marginTop: '3px' }}>
          <Grid item xs={3}>
            <Typography variant="h6">Authentication</Typography>
          </Grid>
          <Grid item xs={3}>
            <FormControlLabel
              control={<Switch checked={switchChecked} onChange={handleSwitchChange} />}
              label={switchChecked ? 'Enabled' : 'Disabled'}
            />
          </Grid>
          {switchChecked && (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  size="small"
                  fullWidth
                  id="outlined-basic"
                  label="Username *"
                  variant="outlined"
                  value={Username}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth size="small" variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={showPassword ? 'text' : 'password'}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Password"
                  />
                </FormControl>
              </Grid>
            </Grid>
          )}
        </Grid>

        <Grid container spacing={0} sx={{ marginTop: '5px' }}>
          <Grid item xs={12}>
            <Button
              disabled={!accessArray.includes('manage-realm') && !accessArray.includes('manage-clients')}
              onClick={handleSave}
              variant="contained"
              sx={{ marginRight: '5px' }}
            >
              Save
            </Button>
            <Button variant="contained" disabled>
              Test connection
            </Button>
            <Button
              disabled={!accessArray.includes('manage-realm') && !accessArray.includes('manage-clients')}
              onClick={setData}
              sx={{ marginLeft: '5px' }}
            >
              Revert
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default SettingsEmail;
