import { Grid, Typography, FormControlLabel, Switch } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ApiHttpClient } from '../../interceptor/axiosInterceptor';
import { error, success } from '../Toast';
import { setConfigSlice } from '../../store/realmSlice';

function SettingsLogin({ apiData, fetchData }: any) {
  console.log(apiData);
  const state = useSelector((state: any) => state.config);
  const orgId = state.orgId;
  const initialState = useSelector((state: any) => state.initialConfig);
  const loginOrgId = initialState.orgId;
  const [registrationAllowed, setRegistrationAllowed] = useState(apiData.registrationAllowed);
  const [resetPasswordAllowed, setResetPasswordAllowed] = useState(apiData.resetPasswordAllowed);
  const [rememberMe, setRememberMe] = useState(apiData.rememberMe);
  const dispatch = useDispatch();
  const accessArray = Object.values(state.realmAccess ?? {}).flat();
  useEffect(() => {
    setRegistrationAllowed(apiData.registrationAllowed);
    setResetPasswordAllowed(apiData.resetPasswordAllowed);
    setRememberMe(apiData.rememberMe);
  }, [apiData]);

  useEffect(() => {
    checkAuthentication();
  }, [orgId]);
  const checkAuthentication = async () => {
    const response1 = await ApiHttpClient.get(
      orgId !== loginOrgId
        ? `admin/${loginOrgId}/console/whoami?currentRealm=${orgId}`
        : `admin/${orgId}/console/whoami?currentRealm=${orgId}`,
    );
    const nameResponse1 = await ApiHttpClient.get(`admin/realms/${loginOrgId}/ui-ext/realms/names?`);
    let reformedState: any = {
      ...state,
      userId: response1.userId,
      realms: nameResponse1,
      realmAccess: response1.realm_access,
    };
    dispatch(setConfigSlice(reformedState));
  };

  const handleSwitchChange = async (event: any, property: string) => {
    try {
      const url = `admin/realms/${orgId}/ui-ext`;
      const payload: any = apiData;
      if (property === 'registrationAllowed') {
        setRegistrationAllowed(event.target.checked);
        payload['registrationAllowed'] = event.target.checked;
      } else if (property === 'resetPasswordAllowed') {
        setResetPasswordAllowed(event.target.checked);
        payload['resetPasswordAllowed'] = event.target.checked;
      } else if (property === 'rememberMe') {
        setRememberMe(event.target.checked);
        payload['rememberMe'] = event.target.checked;
      }
      const response = await ApiHttpClient.put(url, payload);
      fetchData();
      success('Updated Sucessfully');
    } catch (err: any) {
      console.error('Error While Updating:', err);
      error(err?.['response']?.data || 'Error While Updating');
    }
  };
  return (
    <>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Typography variant="h6">Login screen customization</Typography>
        </Grid>
        <Grid item xs={2} sx={{ marginTop: '5px' }}>
          <Typography variant="subtitle1"> User registration</Typography>
        </Grid>
        <Grid item xs={2}>
          <FormControlLabel
            control={
              <Switch
                disabled={!accessArray.includes('manage-realm') && !accessArray.includes('manage-clients')}
                checked={registrationAllowed}
                onChange={(e) => handleSwitchChange(e, 'registrationAllowed')}
                color="primary"
              />
            }
            label={registrationAllowed ? 'On' : 'Off'}
          />
        </Grid>
      </Grid>
      <Grid container spacing={0}>
        <Grid item xs={2} sx={{ marginTop: '5px' }}>
          <Typography variant="subtitle1">Forgot password</Typography>
        </Grid>
        <Grid item xs={2}>
          <FormControlLabel
            control={
              <Switch
                disabled={!accessArray.includes('manage-realm') && !accessArray.includes('manage-clients')}
                checked={resetPasswordAllowed}
                onChange={(e) => handleSwitchChange(e, 'resetPasswordAllowed')}
                color="primary"
              />
            }
            label={resetPasswordAllowed ? 'On' : 'Off'}
          />
        </Grid>
      </Grid>
      <Grid container spacing={0}>
        <Grid item xs={2} sx={{ marginTop: '5px' }}>
          <Typography variant="subtitle1">Remember me</Typography>
        </Grid>
        <Grid item xs={2}>
          <FormControlLabel
            control={
              <Switch
                disabled={!accessArray.includes('manage-realm') && !accessArray.includes('manage-clients')}
                checked={rememberMe}
                onChange={(e) => handleSwitchChange(e, 'rememberMe')}
                color="primary"
              />
            }
            label={rememberMe ? 'On' : 'Off'}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default SettingsLogin;
