import { Avatar, Button, Grid, Popover, Typography } from '@mui/material';
import { deepOrange } from '@mui/material/colors';
import { useDispatch, useSelector } from 'react-redux';
import { setConfigSlice } from '../store/realmSlice';
import { useEffect, useState } from 'react';
import { KeyclockConfig } from '../auth/keycloackConfig';
import { jwtDecode } from 'jwt-decode';
import { ApiHttpClient } from '../interceptor/axiosInterceptor';
import { setProfile } from '../store/profileSlice';
// import Keycloak from 'keycloak-js';

function Logout() {
  const state = useSelector((state: any) => state?.config);
  const initialState = useSelector((state: any) => state.initialConfig);
  const profile = useSelector((state: any) => state.profile);
  const [userName, setUserName] = useState('');
  const dispatch = useDispatch();
  const [userDetails, setUserDetails] = useState<any>({});
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const orgId = state.orgId;
  const initialOrgId = initialState.orgId;

  useEffect(() => {
    let token = KeyclockConfig.getInstance()?.token;
    const extractedUsername = decodeToken(token);
    setUserName(extractedUsername);
    fetchData(extractedUsername);
  }, [orgId]);

  useEffect(() => {}, [state]);

  const decodeToken = (token: any) => {
    try {
      const decodedToken: any = jwtDecode(token);
      return decodedToken?.preferred_username || decodedToken?.username || ''; // Adjust based on your token structure
    } catch (error) {
      console.error('Failed to decode token:', error);
      return '';
    }
  };

  const fetchData = async (extractedUsername: any) => {
    try {
      if (orgId.toLowerCase() === initialOrgId.toLowerCase()) {
        const url = `/admin/realms/${orgId}/users?search=${extractedUsername}`;
        const response = await ApiHttpClient.get(url);
        if (response) {
          dispatch(setProfile(response[0]));
          const { email } = profile;
          if (email) {
            setUserDetails(profile);
          }
        }
      }
    } catch (err: any) {
      console.error('Error fetching user details:', err);
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openPopOver = Boolean(anchorEl);
  const logouthandler = async () => {
    dispatch(
      setConfigSlice({
        idp: '',
        orgId: '',
        clientId: '',
        userId: '',
        realms: {},
      }),
    );
    dispatch(setConfigSlice(initialState));
    await KeyclockConfig.getInstance()?.logout({
      redirectUri: process.env.REACT_APP_URL,
    });
    localStorage.clear();
  };

  return (
    <>
      <Avatar
        component="button"
        sx={{ border: 'none', bgcolor: deepOrange[500], marginRight: 1, cursor: 'pointer' }}
        onClick={handleClick}
      >
        {userName?.slice(0, 2)?.toUpperCase()}
      </Avatar>
      <Popover
        open={openPopOver}
        anchorEl={anchorEl}
        onClose={handleClose}
        sx={{
          marginLeft: '14px',
          marginTop: '13px',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Grid container alignItems="center" sx={{ padding: '15px', flexWrap: 'nowrap' }}>
          <Grid
            item
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginRight: '10px',
            }}
          >
            <Avatar
              sx={{
                bgcolor: deepOrange[500],
                fontSize: '30px',
                width: '60px',
                height: '60px',
                marginTop: '10px',
                marginBottom: '0px',
              }}
            >
              {userName?.slice(0, 2)?.toUpperCase()}
            </Avatar>
            <Button
              size="small"
              sx={{
                fontSize: '14px',
                textTransform: 'none',
                marginTop: '10px',
                padding: '0px',
              }}
              onClick={logouthandler}
              variant="outlined"
            >
              Logout
            </Button>
          </Grid>

          <Grid item sx={{ textAlign: 'left', marginBottom: '20px' }}>
            <Typography
              component="p"
              sx={{ margin: 0, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
            >
              <b>{profile?.userName.toUpperCase() || ''}</b>
            </Typography>
            <Typography
              component="p"
              sx={{ margin: 0, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
            >
              {profile?.email || ''}
            </Typography>
          </Grid>
        </Grid>
      </Popover>
    </>
  );
}

export default Logout;
