import {
  Button,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  styled,
  tableCellClasses,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { StyledTableRow } from '../../styles/tableStyles';
import { useEffect, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { ApiHttpClient } from '../../interceptor/axiosInterceptor';
import { useParams } from 'react-router-dom';
import useDebouncedSearch from '../../hooks/useDebouncedSearch';
import { error, success } from '../Toast';
import Model from '../model';
import SkeletonLoader from '../SkeletonLoader';
import ConfirmationDialog from '../deleteModal';
import { paginationRowsPerPage } from '../../const/strings';

function ClientsRole(clientPassed: any) {
  const state = useSelector((state: any) => state.config);
  const orgId = state.orgId;
  const accessArray = Object.values(state.realmAccess ?? {}).flat();
  const [role, setRoles] = useState<any[]>([]);
  const { orgName } = useParams();
  const [roleName, setRoleName] = useState('');
  const [description, setDescription] = useState('');
  const [roleDescription, setRoleDescription] = useState('');
  const dispatch = useDispatch();
  const { clientId } = useParams();
  const [loading, setLoading] = useState(true);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const clientState = useSelector((state: any) => state.client);
  const [openModal, setOpenModal] = useState(false);
  const [count, setCount] = useState(10);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [firstValue, setFirstValue] = useState(0);
  // Table data code starts

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      padding: '7px !important',
    },
  }));

  useEffect(() => {
    fetchData(searchTerm);
  }, [orgId, page, rowsPerPage]);

  const handleOpenConfirmation = (userId: any) => {
    setSelectedUserId(userId);
    setOpenConfirmation(true);
  };

  const fetchData = async (query = '') => {
    try {
      const url = query
        ? `admin/realms/${orgId}/clients/${clientId}/roles?first=${firstValue}&max=${rowsPerPage!}&search=${query}`
        : `admin/realms/${orgId}/clients/${clientId}/roles?first=${firstValue}&max=${rowsPerPage!}`;
      const paginationUrl = query
        ? `admin/realms/${orgId}/clients/${clientId}/roles?search=${query}`
        : `admin/realms/${orgId}/clients/${clientId}/roles`;
      const response = await ApiHttpClient.get(url);
      let apiData: any = response;
      apiData.length > 0 ? setRoles(apiData) : setRoles([]);
      const paginationResponse = await ApiHttpClient.get(paginationUrl);
      setCount(paginationResponse.length);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const [searchTerm, setSearchTerm] = useDebouncedSearch((debouncedTerm: any) => {
    fetchData(debouncedTerm);
  });

  const handleSearchInputChange = (e: any) => {
    const value = e.target.value;
    setSearchTerm(value);
    setFirstValue(0);
    setPage(0);
  };

  // Table data code ends

  // Modal pop up code starts

  const handleClickOpen = () => {
    setOpenModal(true);
    setRoleName('');
  };
  const handleClose = () => {
    setRoleName('');
    setRoleDescription('');
    setDescription('');
    setOpenModal(false);
  };

  const handleCreateRole = async () => {
    try {
      if (!roleName.trim()) {
        error('Role name is required');
        return;
      }
      const url = `admin/realms/${orgId}/clients/${clientId}/roles`;
      const roleData = { name: roleName, description: description };
      await ApiHttpClient.post(url, roleData);
      setRoleName('');
      setRoleDescription('');
      fetchData('');
      handleClose();
      setSearchTerm('');
      success('Role created successfully');
    } catch (err) {
      console.error('Error creating role:', err);
      error('Error creating role');
    }
  };

  const handleChangePage = (event: any, newPage: any) => {
    const firstValue = newPage * rowsPerPage;
    setFirstValue(firstValue);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setFirstValue(0);
  };

  const handleDeleteRow = async (id: any) => {
    try {
      const url = `admin/realms/${orgName}/roles-by-id/${id}`;
      await ApiHttpClient.delete(url, {});
      success('User deleted successfully');
      fetchData('');
      setOpenConfirmation(false);
      setSearchTerm('');
    } catch (err) {
      console.error('Error deleting user:', err);
      error('Error deleting user:');
      setOpenConfirmation(false);
    }
  };

  // Modal pop up code Ends

  return (
    <>
      {loading ? (
        <SkeletonLoader rows={''} />
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={4} sm={6}>
            <TextField
              size="small"
              fullWidth
              type="search"
              id="outlined-basic"
              label="Search Role"
              variant="outlined"
              value={searchTerm}
              onChange={handleSearchInputChange}
            />
          </Grid>
          <Grid item xs={4} sm={6} textAlign="right">
            {accessArray.includes('manage-clients') && (
              <Button size="medium" variant="contained" onClick={handleClickOpen}>
                Create Role
              </Button>
            )}
          </Grid>

          <Grid item xs={12}>
            <TableContainer component={Paper} sx={{ maxHeight: '60vh' }}>
              <Table sx={{ minWidth: 700 }}>
                <TableHead sx={{ position: 'sticky', top: 0, zIndex: 1 }}>
                  <TableRow>
                    <StyledTableCell>S.No </StyledTableCell>
                    <StyledTableCell>Role Name </StyledTableCell>
                    <StyledTableCell>Composite </StyledTableCell>
                    <StyledTableCell>Description </StyledTableCell>
                    {/* <StyledTableCell>Action</StyledTableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {role.map((row: any, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell component="th" scope="row">
                        {page * rowsPerPage + index + 1}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {row.name}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {row.composite ? 'True' : 'false'}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {row.description}
                      </StyledTableCell>
                      {/* <StyledTableCell>
                        <Typography sx={{ cursor: 'pointer' }} component="span">
                          <IconButton
                            disabled={!accessArray.includes('manage-clients')}
                            color="error"
                            onClick={() => handleOpenConfirmation(row.id)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Typography>
                      </StyledTableCell> */}
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              count={count}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={(event) => handleChangeRowsPerPage(event)}
              rowsPerPageOptions={paginationRowsPerPage}
              labelRowsPerPage={'Roles per Page'}
            />
          </Grid>
        </Grid>
      )}
      {/* modal popup code */}
      <Model
        open={openModal}
        handeleConfirm={handleCreateRole}
        title={'Create Role'}
        saveButtonName={'Create'}
        data-modal-backdrop="static"
        height="auto"
        cancelClick={() => handleClose()}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              size="small"
              fullWidth
              id="outlined-basic"
              label="Create Role"
              variant="outlined"
              autoComplete="off"
              value={roleName}
              onChange={(e) => setRoleName(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              size="small"
              fullWidth
              id="outlined-basic"
              label="Description"
              variant="outlined"
              autoComplete="off"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Grid>
        </Grid>
      </Model>

      <ConfirmationDialog
        open={openConfirmation}
        onClose={() => setOpenConfirmation(false)}
        title="Confirm Deletion"
        content="Are you sure you want to delete this Role?"
        onConfirm={() => handleDeleteRow(selectedUserId)}
      />
    </>
  );
}

export default ClientsRole;
