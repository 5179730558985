import {
  Grid,
  Container,
  Link,
  Typography,
  Breadcrumbs,
  Stack,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Box,
  Button,
  FormGroup,
  Switch,
  FormControlLabel,
  Checkbox,
  IconButton,
} from '@mui/material';
import Layout from '../Layout';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useParams, Link as RouterLink } from 'react-router-dom';
import { useState } from 'react';
import { ApiHttpClient } from '../../interceptor/axiosInterceptor';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { success, error } from '../Toast';
import { useSelector } from 'react-redux';

function CreateClient() {
  const [clientType, setClientType] = useState<any>(10);
  const [clientIdField, setClientIdField] = useState<any>('');
  const [name, setName] = useState<any>('');
  const [description, setDescription] = useState<any>('');
  const [rootUrl, setRootUrl] = useState<any>('');
  const [homeUrl, setHomeUrl] = useState<any>('');
  const [validRedirectUris, setValidRedirectUris] = useState<any>(['']);
  const [validPostLogoutRedirectUris, setValidPostLogoutRedirectUris] = useState<any>(['']);
  const [webOrigins, setWebOrigins] = useState<any>(['']);
  const [errors, setErrors] = useState<any>({});
  const [isSwitchOn, setIsSwitchOn] = useState<any>(false);
  const [isSwitchOn2, setIsSwitchOn2] = useState<any>(false);
  const state = useSelector((state: any) => state.config);
  const orgId = state.orgId;

  const handleChangeClientType = (event: any) => {
    setClientType(event.target.value);
  };

  const handleSwitchChange = (event: any) => {
    setIsSwitchOn(event.target.checked);
  };

  const handleAuthorizeSwitchChange = (event: any) => {
    setIsSwitchOn2(event.target.checked);
  };

  const handleRemoveField = (index: any, type: any) => {
    if (type === 'redirect') {
      if (validRedirectUris.length > 1) {
        setValidRedirectUris(validRedirectUris.filter((_: any, i: any) => i !== index));
      }
    } else if (type === 'post-logout') {
      if (validPostLogoutRedirectUris.length > 1) {
        setValidPostLogoutRedirectUris(validPostLogoutRedirectUris.filter((_: any, i: any) => i !== index));
      }
    } else if (type === 'web-origin') {
      if (webOrigins.length > 1) {
        setWebOrigins(webOrigins.filter((_: any, i: any) => i !== index));
      }
    }
  };

  const handleAddField = (type: any) => {
    if (type === 'redirect') {
      setValidRedirectUris([...validRedirectUris, '']);
    } else if (type === 'post-logout') {
      setValidPostLogoutRedirectUris([...validPostLogoutRedirectUris, '']);
    } else if (type === 'web-origin') {
      setWebOrigins([...webOrigins, '']);
    }
  };

  const handleChange = (e: any, index: any, type: any) => {
    const value = e.target.value;
    if (type === 'redirect') {
      const updatedUris = validRedirectUris.map((uri: any, i: any) => (i === index ? value : uri));
      setValidRedirectUris(updatedUris);
    } else if (type === 'post-logout') {
      const updatedUris = validPostLogoutRedirectUris.map((uri: any, i: any) => (i === index ? value : uri));
      setValidPostLogoutRedirectUris(updatedUris);
    } else if (type === 'web-origin') {
      const updatedUris = webOrigins.map((uri: any, i: any) => (i === index ? value : uri));
      setWebOrigins(updatedUris);
    }
  };

  const resetForm = () => {
    setClientType(10);
    setClientIdField('');
    setName('');
    setDescription('');
    setRootUrl('');
    setHomeUrl('');
    setValidRedirectUris(['']);
    setValidPostLogoutRedirectUris(['']);
    setWebOrigins(['']);
    setErrors({});
  };

  const handleCancel = () => {
    resetForm();
  };

  const handleSave = async () => {
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      return;
    }

    const payload: any = {
      protocol: clientType === 10 ? 'openid-connect' : 'saml',
      clientId: clientIdField,
      alwaysDisplayInConsole: false,
      name: name || '',
      description: description || '',
      rootUrl: rootUrl || '',
      baseUrl: homeUrl || '',
      frontchannelLogout: true,
      publicClient: true,
      implicitFlowEnabled: false,
      directAccessGrantsEnabled: true,
      standardFlowEnabled: true,
      serviceAccountsEnabled: false,
      authorizationServicesEnabled: isSwitchOn2,
      attributes: {
        'oauth2.device.authorization.grant.enabled': false,
        saml_idp_initiated_sso_url_name: '',
        'oidc.ciba.grant.enabled': false,
      },
    };
    if (validRedirectUris?.length > 0 && validRedirectUris?.[0] !== '') {
      payload['redirectUris'] = validRedirectUris;
    }
    if (webOrigins?.length > 0 && webOrigins?.[0] !== '') {
      payload['webOrigins'] = webOrigins;
    }
    if (validPostLogoutRedirectUris?.length > 0 && validPostLogoutRedirectUris?.[0] !== '') {
      const postLogOutUrl = validPostLogoutRedirectUris.join('##');
      payload['attributes']['post.logout.redirect.uris'] = postLogOutUrl;
    }

    try {
      const url = `admin/realms/${orgId}/clients`;
      const response = await ApiHttpClient.post(url, payload);
      success('Client created successfully');
      resetForm();
    } catch (err) {
      console.error('Error creating client:', err);
      error('Error creating client');
    }
  };

  const validateForm = () => {
    const errors: any = {};
    if (!clientIdField || clientIdField.trim() === '') {
      errors.clientIdField = 'Client ID is required';
    }
    setErrors(errors);
    return errors;
  };

  const breadcrumbs = [
    <Link underline="hover" key="1" color="primary" component={RouterLink} to={`/auth/userdashboard/${orgId}/clients`}>
      Clients
    </Link>,
    <Typography key="2" color="text.primary">
      CreateClient
    </Typography>,
  ];

  return (
    <>
      <Grid sx={{ margin: 5, marginTop: 10 }}>
        <Layout>
          <Stack spacing={2} sx={{ marginTop: '10px' }}>
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
              {breadcrumbs}
            </Breadcrumbs>
          </Stack>
          <Grid
            container
            spacing={3}
            sx={{ marginTop: '3px', borderBottom: '1px solid lightgrey', paddingBottom: '15px' }}
          >
            <Grid item xs={12} lg={9}>
              <Typography variant="h6">Create Client</Typography>
              <Typography variant="body1">
                <Typography component="span" color={'red'}>
                  *Note:
                </Typography>{' '}
                Clients are applications and services that can request authentication of a user.
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ marginTop: '5px' }}>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Client type</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={clientType}
                  label="clientType"
                  size="small"
                  onChange={handleChangeClientType}
                >
                  <MenuItem value={10}>OpenID Connect</MenuItem>
                  <MenuItem value={20}>SAML</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <TextField
                size="small"
                fullWidth
                id="Client ID *"
                name="Client ID *"
                label="Client ID *"
                variant="outlined"
                value={clientIdField}
                autoComplete="off"
                onChange={(e) => {
                  setClientIdField(e.target.value);
                  validateForm();
                }}
                error={!!errors.clientIdField}
                helperText={errors.clientIdField}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                size="small"
                fullWidth
                id="Name"
                name="Name"
                label="Name"
                variant="outlined"
                value={name}
                autoComplete="off"
                onChange={(e) => setName(e.target.value)}
                error={!!errors.name}
                helperText={errors.name}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                size="small"
                fullWidth
                id="Description"
                name="Description"
                label="Description"
                variant="outlined"
                value={description}
                autoComplete="off"
                onChange={(e) => setDescription(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                size="small"
                fullWidth
                id="Root URL "
                name="Root URL "
                label="Root URL "
                variant="outlined"
                value={rootUrl}
                onChange={(e) => setRootUrl(e.target.value)}
                error={!!errors.rootUrl}
                helperText={errors.rootUrl}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                size="small"
                fullWidth
                id="Home URL "
                name="Home URL "
                label="Home URL "
                variant="outlined"
                value={homeUrl}
                onChange={(e) => setHomeUrl(e.target.value)}
                error={!!errors.homeUrl}
                helperText={errors.homeUrl}
              />
            </Grid>

            <Grid item xs={6}>
              <FormGroup row>
                <Typography variant="body1">Client authentication</Typography>
                <FormControlLabel
                  sx={{ paddingLeft: '15px', marginTop: '-8px' }}
                  control={<Switch checked={isSwitchOn} onChange={handleSwitchChange} />}
                  label={isSwitchOn ? 'ON' : 'OFF'}
                />
              </FormGroup>
            </Grid>
            <Grid item xs={6}>
              <FormGroup row>
                <Typography variant="body1">Authorization</Typography>
                <FormControlLabel
                  sx={{ paddingLeft: '15px', marginTop: '-8px' }}
                  control={<Switch checked={isSwitchOn2} onChange={handleAuthorizeSwitchChange} />}
                  label={isSwitchOn2 ? 'ON' : 'OFF'}
                />
              </FormGroup>
            </Grid>
            <FormControl component="fieldset">
              <Grid container spacing={3}>
                <Grid item xs={12} sm={2} sx={{ marginTop: '10px', marginLeft: '15px' }}>
                  <Typography variant="body1">Authorization Flow</Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="kc-flow-standard"
                        name="standard"
                        checked
                        inputProps={{ 'aria-labelledby': 'kc-flow-standard' }}
                      />
                    }
                    label="Standard flow"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="kc-flow-implicit"
                        name="implicit"
                        inputProps={{ 'aria-labelledby': 'kc-flow-implicit' }}
                      />
                    }
                    label="Implicit flow"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="kc-oauth-device-authorization-grant"
                        name="oauthDeviceAuthorizationGrant"
                        inputProps={{ 'aria-labelledby': 'kc-oauth-device-authorization-grant' }}
                      />
                    }
                    label="OAuth 2.0 Device Authorization Grant"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="kc-oidc-ciba-grant"
                        name="oidcCibaGrant"
                        disabled
                        inputProps={{ 'aria-labelledby': 'kc-oidc-ciba-grant' }}
                      />
                    }
                    label="OIDC CIBA Grant"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="kc-flow-direct"
                        name="directAccessGrantsEnabled"
                        checked
                        inputProps={{ 'aria-labelledby': 'kc-flow-direct' }}
                      />
                    }
                    label="Direct access grants"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="kc-flow-service-account"
                        name="serviceAccount"
                        disabled
                        inputProps={{ 'aria-labelledby': 'kc-flow-service-account' }}
                      />
                    }
                    label="Service accounts roles"
                  />
                </Grid>
              </Grid>
            </FormControl>

            <Grid item xs={12}>
              {validRedirectUris?.map((uri: any, index: any) => (
                <Grid item xs={12} key={index} container alignItems="center">
                  <Grid item xs={11} sx={{ marginBottom: '10px' }}>
                    <TextField
                      size="small"
                      fullWidth
                      id={`valid-redirect-uri-${index}`}
                      name={`valid-redirect-uri-${index}`}
                      label="Valid Redirect URIs"
                      variant="outlined"
                      value={uri}
                      onChange={(e) => handleChange(e, index, 'redirect')}
                      error={!!errors.validRedirectUris}
                      helperText={errors.validRedirectUris}
                    />
                  </Grid>
                  <Grid item xs={1} container justifyContent="flex-end">
                    <IconButton onClick={() => handleAddField('redirect')}>
                      <AddCircleOutlineIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => handleRemoveField(index, 'redirect')}
                      disabled={validRedirectUris.length <= 1}
                    >
                      <RemoveCircleOutlineIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              ))}
            </Grid>

            <Grid item xs={12}>
              {validPostLogoutRedirectUris?.map((uri: any, index: any) => (
                <Grid item xs={12} key={index} container alignItems="center">
                  <Grid item xs={11} sx={{ marginBottom: '10px' }}>
                    <TextField
                      size="small"
                      fullWidth
                      id={`valid-post-logout-redirect-uri-${index}`}
                      name={`valid-post-logout-redirect-uri-${index}`}
                      label="Valid Post Logout Redirect URIs"
                      variant="outlined"
                      value={uri}
                      onChange={(e) => handleChange(e, index, 'post-logout')}
                      error={!!errors.validPostLogoutRedirectUris}
                      helperText={errors.validPostLogoutRedirectUris}
                    />
                  </Grid>
                  <Grid item xs={1} container justifyContent="flex-end">
                    <IconButton onClick={() => handleAddField('post-logout')}>
                      <AddCircleOutlineIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => handleRemoveField(index, 'post-logout')}
                      disabled={validPostLogoutRedirectUris.length <= 1}
                    >
                      <RemoveCircleOutlineIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              ))}
            </Grid>

            <Grid item xs={12}>
              {webOrigins?.map((uri: any, index: any) => (
                <Grid item xs={12} key={index} container alignItems="center">
                  <Grid item xs={11} sx={{ marginBottom: '10px' }}>
                    <TextField
                      size="small"
                      fullWidth
                      id={`web-origin-${index}`}
                      name={`web-origin-${index}`}
                      label="Web Origins"
                      variant="outlined"
                      value={uri}
                      onChange={(e) => handleChange(e, index, 'web-origin')}
                      error={!!errors.webOrigins}
                      helperText={errors.webOrigins}
                    />
                  </Grid>
                  <Grid item xs={1} container justifyContent="flex-end">
                    <IconButton onClick={() => handleAddField('web-origin')}>
                      <AddCircleOutlineIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => handleRemoveField(index, 'web-origin')}
                      disabled={webOrigins.length <= 1}
                    >
                      <RemoveCircleOutlineIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: '5px' }}>
            <Box display="flex" justifyContent="flex-end">
              <Button variant="contained" color="primary" style={{ marginRight: '8px' }} onClick={handleSave}>
                Save
              </Button>
              <Button variant="outlined" color="primary" onClick={handleCancel}>
                Cancel
              </Button>
            </Box>
          </Grid>
        </Layout>
      </Grid>
    </>
  );
}

export default CreateClient;
