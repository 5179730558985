import {
  Grid,
  Link,
  Typography,
  Breadcrumbs,
  Stack,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Box,
  Button,
  FormGroup,
  Switch,
  FormControlLabel,
} from '@mui/material';
import Layout from '../Layout';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useParams, Link as RouterLink, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { ApiHttpClient } from '../../interceptor/axiosInterceptor';
import { success, error } from '../Toast';
import { useSelector } from 'react-redux';
import { errorNotification } from '../../utils/errorNotification';

function CreateClientScope() {
  const [clientType, setClientType] = useState<any>(10);
  const [clientProtocolType, setClientProtocolType] = useState<any>(10);
  const [name, setName] = useState<any>('');
  const [description, setDescription] = useState<any>('');
  const [screenText, setScreenText] = useState<any>('');
  const [guiOrder, setGuiOrder] = useState<any>('');
  const [errors, setErrors] = useState<any>({});
  const [isSwitchOn, setIsSwitchOn] = useState<any>(true);
  const [isSwitchOn2, setIsSwitchOn2] = useState<any>(false);
  const state = useSelector((state: any) => state.config);
  const orgId = state.orgId;
  const navigate = useNavigate();
  const handleChangeClientType = (event: any) => {
    setClientType(event.target.value);
  };
  const handleChangeProtocolClientType = (event: any) => {
    setClientProtocolType(event.target.value);
  };
  const handleSwitchChange = (event: any) => {
    setIsSwitchOn(event.target.checked);
  };

  const handleIncludeInTokenScopeChange = (event: any) => {
    setIsSwitchOn2(event.target.checked);
  };

  const resetForm = () => {
    setClientType(10);
    setName('');
    setDescription('');
    setGuiOrder('');
    setScreenText('');
    setIsSwitchOn(true);
    setIsSwitchOn2(false);
    setClientProtocolType(10);
    setErrors({});
  };

  const handleCancel = () => {
    resetForm();
    navigate(`/auth/userdashboard/${orgId}/clientscopes`);
  };

  const handleSave = async () => {
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      return;
    }
    const type = clientType === 10 ? 'none' : clientType === 20 ? 'default' : 'optional';
    const payload: any = {
      attributes: {
        'consent.screen.text': screenText,
        'display.on.consent.screen': String(isSwitchOn),
        'gui.order': String(guiOrder),
        'include.in.token.scope': isSwitchOn2,
      },
      description: description || '',
      name: name.includes(' ') ? name.split(' ').join('_') : name || '',
      protocol: clientProtocolType === 10 ? 'openid-connect' : 'saml',
      type: type,
    };

    try {
      const url = `admin/realms/${orgId}/client-scopes`;
      await ApiHttpClient.post(url, payload);
      const getResponse = await ApiHttpClient.get(url);
      const transformedName = name.includes(' ') ? name.split(' ').join('_').toLowerCase() : name.toLowerCase();
      const newScope = getResponse.find((scope: any) => scope.name.toLowerCase() === transformedName);
      const selectedType = type === 'default' ? 'default-default-client-scopes' : 'default-optional-client-scopes';
      if (newScope) {
        const newScopeId = newScope?.id;
        if (clientType !== 10) {
          const deleteResponseUrl =
            type === 'default'
              ? `admin/realms/${orgId}/${selectedType}/${newScopeId}`
              : `admin/realms/${orgId}/${selectedType}/${newScopeId}`;

          const typeResponseUrl = `admin/realms/${orgId}/${selectedType}`;
          const scopeMappingUrl = `admin/realms/${orgId}/client-scopes/${newScopeId}/scope-mappings`;
          await ApiHttpClient.delete(deleteResponseUrl, {});
          await ApiHttpClient.put(deleteResponseUrl, {});
          await ApiHttpClient.get(deleteResponseUrl);
          await ApiHttpClient.get(typeResponseUrl);
          await ApiHttpClient.get(scopeMappingUrl);
        } else {
          const getUrl = `admin/realms/${orgId}/client-scopes/${newScopeId}`;
          const getDefault = `admin/realms/${orgId}/default-default-client-scopes`;
          const getOptional = `admin/realms/${orgId}/default-optional-client-scopes`;
          const scopeMappingUrl = `admin/realms/${orgId}/client-scopes/${newScopeId}/scope-mappings`;

          await ApiHttpClient.get(getUrl);
          await ApiHttpClient.get(getDefault);
          await ApiHttpClient.get(getOptional);
          await ApiHttpClient.get(scopeMappingUrl);
        }
        success('Client created successfully');
        resetForm();
      }
    } catch (err: any) {
      console.error('Error creating client:', err);
      const errNotification = errorNotification(err);
      const message = errNotification?.errorMessage;
      const params = errNotification?.params;
      error(params ? params : message);
    }
  };

  const validateForm = () => {
    const errors: any = {};
    if (!name || name.trim() === '') {
      errors.name = 'name is required';
    }
    setErrors(errors);
    return errors;
  };

  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="primary"
      component={RouterLink}
      to={`/auth/userdashboard/${orgId}/clientscopes`}
    >
      Client scope
    </Link>,
    <Typography key="2" color="text.primary">
      Create client scope
    </Typography>,
  ];

  return (
    <>
      <Grid sx={{ margin: 5, marginTop: 10 }}>
        <Layout>
          <Stack spacing={2} sx={{ marginTop: '10px' }}>
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
              {breadcrumbs}
            </Breadcrumbs>
          </Stack>
          <Grid
            container
            spacing={3}
            sx={{ marginTop: '3px', borderBottom: '1px solid lightgrey', paddingBottom: '15px' }}
          >
            <Grid item xs={12} lg={9}>
              <Typography variant="h6">Create Client Scope</Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ marginTop: '5px' }}>
            <Grid item xs={6}>
              <TextField
                size="small"
                fullWidth
                id="Name"
                name="Name"
                label="Name"
                variant="outlined"
                value={name}
                autoComplete="off"
                onChange={(e) => setName(e.target.value)}
                error={!!errors.name}
                helperText={errors.name}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                size="small"
                fullWidth
                id="Description"
                name="Description"
                label="Description"
                variant="outlined"
                value={description}
                autoComplete="off"
                onChange={(e) => setDescription(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Type</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={clientType}
                  label="Type"
                  size="small"
                  onChange={handleChangeClientType}
                >
                  <MenuItem value={10}>None</MenuItem>
                  <MenuItem value={20}>Default</MenuItem>
                  <MenuItem value={30}>Optional</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Protocol</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={clientProtocolType}
                  label="clientType"
                  size="small"
                  onChange={handleChangeProtocolClientType}
                >
                  <MenuItem value={10}>OpenID Connect</MenuItem>
                  <MenuItem value={20}>SAML</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormGroup row>
                <Typography variant="body1">Display on consent screen</Typography>
                <FormControlLabel
                  sx={{ paddingLeft: '15px', marginTop: '-8px' }}
                  control={<Switch checked={isSwitchOn} onChange={handleSwitchChange} />}
                  label={isSwitchOn ? 'ON' : 'OFF'}
                />
              </FormGroup>
            </Grid>
            <Grid item xs={6}>
              <FormGroup row>
                <Typography variant="body1">Include in token scope</Typography>
                <FormControlLabel
                  sx={{ paddingLeft: '15px', marginTop: '-8px' }}
                  control={<Switch checked={isSwitchOn2} onChange={handleIncludeInTokenScopeChange} />}
                  label={isSwitchOn2 ? 'ON' : 'OFF'}
                />
              </FormGroup>
            </Grid>
            <Grid item xs={6}>
              <TextField
                size="small"
                fullWidth
                id="Consent screen text"
                name="Consent screen text"
                label="Consent screen text"
                variant="outlined"
                value={screenText}
                onChange={(e) => setScreenText(e.target.value)}
                error={!!errors.rootUrl}
                helperText={errors.rootUrl}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                size="small"
                fullWidth
                id="Display Order"
                name="Display Order"
                label="Display Order"
                variant="outlined"
                type="number"
                value={guiOrder}
                onChange={(e) => setGuiOrder(e.target.value)}
                error={!!errors.rootUrl}
                helperText={errors.rootUrl}
                inputProps={{ min: 0 }}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: '5px' }}>
            <Box display="flex" justifyContent="flex-end">
              <Button variant="contained" color="primary" style={{ marginRight: '8px' }} onClick={handleSave}>
                Save
              </Button>
              <Button variant="outlined" color="primary" onClick={handleCancel}>
                Cancel
              </Button>
            </Box>
          </Grid>
        </Layout>
      </Grid>
    </>
  );
}

export default CreateClientScope;
