import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Grid,
  Table,
  TableBody,
  TableHead,
  TableRow,
  ThemeProvider,
  Typography,
} from '@mui/material';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useEffect, useState } from 'react';
import Layout from './Layout';
// import theme from '../theme';
import { TABLE_HEADS } from '../const/strings';
import { useSelector } from 'react-redux';
import { StyledTableCell, StyledTableRow } from '../styles/tableStyles';
import { useNavigate } from 'react-router-dom';

function Profile() {
  useEffect(() => {}, []);
  let navigate = useNavigate();
  const userState = useSelector((state: any) => state.user);
  const roleHead = [TABLE_HEADS.sNo, TABLE_HEADS.groups, TABLE_HEADS.roles];
  const [rolesInfo, setRolesInfo] = useState<Array<Object>>([]);
  const [rolesLoader, setRolesLoader] = useState(false);
  const [sessionLoader, setSessionLoader] = useState(false);
  const jsonData = [
    { id: 1, groupName: 'Group 1', roleName: 'Role 1' },
    { id: 2, groupName: 'Group 2', roleName: 'Role 2' },
  ];
  const TABLE_ROWS = [
    {
      id: 1,
      ipAddress: '192.168.1.1',
      lastAccess: new Date(),
      rememberMe: true,
      start: new Date(),
      userId: 'user1',
      username: 'John Doe',
    },
    {
      id: 2,
      ipAddress: '192.168.1.2',
      lastAccess: new Date(),
      rememberMe: false,
      start: new Date(),
      userId: 'user2',
      username: 'Jane Smith',
    },
    // Add more data as needed
  ];
  const TABLE_HEAD = [
    TABLE_HEADS.id,
    TABLE_HEADS.username,
    TABLE_HEADS.userId,
    TABLE_HEADS.ipAddress,
    TABLE_HEADS.start,
    TABLE_HEADS.lastAccess,
  ];
  //   const router = useRouter();
  const homeClick = () => {
    navigate(`/auth/userdashboard`);
    // dispatch(setClientData("none"));
    // router.push(`/${params.org}/dashboard`);
  };

  return (
    <Grid sx={{ margin: 5, marginTop: 10 }}>
      <Layout>
        <Button
          size="small"
          variant="outlined"
          sx={{
            marginBottom: '10px',
            color: 'theme.palette.text.primary',
          }}
          onClick={homeClick}
        >
          <KeyboardBackspaceIcon fontSize="small" />
        </Button>
        <Card>
          <CardContent>
            <Grid item xs={12}>
              <Typography variant="h5" fontWeight={800}>
                {TABLE_HEADS.userInfo}
                <Divider />
              </Typography>
            </Grid>
            <Grid container spacing={2} sx={{ marginTop: 1 }}>
              <Grid item xs={12} sm={6} md={2}>
                <Typography>
                  {TABLE_HEADS.firstName + ': '}
                  <Typography component="span" sx={{ fontWeight: '800', fontSize: '16px' }}>
                    {userState?.userData?.given_name}
                  </Typography>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <Typography>
                  {TABLE_HEADS.lastName + ': '}
                  <Typography component="span" sx={{ fontWeight: '800', fontSize: '16px' }}>
                    {userState?.userData?.family_name}
                  </Typography>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Typography>
                  {TABLE_HEADS.username + ': '}
                  <Typography component="span" sx={{ fontWeight: '800', fontSize: '16px' }}>
                    {userState?.userData?.preferred_username}
                  </Typography>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Typography>
                  {TABLE_HEADS.email + ': '}
                  <Typography component="span" sx={{ fontWeight: '800', fontSize: '16px' }}>
                    {userState?.userData?.email}
                  </Typography>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <Typography>
                  {TABLE_HEADS.emailVerified + ': '}
                  <Typography component="span" sx={{ fontWeight: '800', fontSize: '16px' }}>
                    {userState?.userData?.email_verified}
                  </Typography>
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        <Card sx={{ marginTop: 2 }}>
          <CardContent>
            <Grid item xs={12}>
              <Typography variant="h5" fontWeight={800}>
                {TABLE_HEADS.userRoles}
              </Typography>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <Table>
                  <TableHead>
                    <TableRow>
                      {roleHead?.map((head) => (
                        <StyledTableCell key={head} scope="col">
                          {head}
                        </StyledTableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {jsonData.map((data) => (
                      <StyledTableRow key={data.id}>
                        <StyledTableCell>{data.id}</StyledTableCell>
                        <StyledTableCell>{data.groupName}</StyledTableCell>
                        <StyledTableCell>{data.roleName}</StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
                {rolesLoader && (
                  <Grid
                    style={{
                      position: 'absolute',
                      top: '70%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                    }}
                  >
                    <CircularProgress size={30} thickness={5} color="primary" />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Card sx={{ marginTop: 2 }}>
          <CardContent>
            <Grid item xs={12}>
              <Typography variant="h5" fontWeight={800}>
                {TABLE_HEADS.userSessions}
              </Typography>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <Table>
                  <TableHead>
                    <TableRow>
                      {TABLE_HEAD?.map((head) => (
                        <StyledTableCell key={head} scope="col">
                          {head}
                        </StyledTableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {TABLE_ROWS.map((row) => (
                      <StyledTableRow key={row.id}>
                        <StyledTableCell>{row.id}</StyledTableCell>
                        <StyledTableCell>{row.username}</StyledTableCell>
                        <StyledTableCell>{row.userId}</StyledTableCell>
                        <StyledTableCell>{row.ipAddress}</StyledTableCell>
                        <StyledTableCell>{new Date(row.start).toString()}</StyledTableCell>
                        <StyledTableCell>{new Date(row.lastAccess).toString()}</StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
                {sessionLoader && (
                  <Grid
                    style={{
                      position: 'absolute',
                      top: '70%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                    }}
                  >
                    <CircularProgress size={30} thickness={5} color="primary" />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Layout>
    </Grid>
  );
}

export default Profile;
