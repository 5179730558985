import {
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  styled,
  tableCellClasses,
} from '@mui/material';
import { StyledTableRow } from '../../styles/tableStyles';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ApiHttpClient } from '../../interceptor/axiosInterceptor';
import Model from '../model';
import { useDispatch } from 'react-redux';
import useDebouncedSearch from '../../hooks/useDebouncedSearch';
import SkeletonLoader from '../SkeletonLoader';
import FileCopyIcon from '@mui/icons-material/FileCopy';

function SettingsKeys() {
  const { orgName } = useParams();
  const [keys, setKeys] = useState([]);
  const [selectedKeys, setSelectedKeys]: any = useState([]);
  const [loading, setLoading] = useState(true);
  const [type, setType] = useState<any>('');
  const dispatch = useDispatch();
  const [copied, setCopied] = useState(false);
  const hiddenAlgorithm = ['AES', 'HS256'];
  const [openModal, setOpenModal] = useState(false);
  useEffect(() => {
    fetchData('');
  }, []);

  const handleClickOpen = (type: string, data: any) => {
    setType(type);
    setSelectedKeys(data);
    setOpenModal(true);
  };
  const handleCopyClick = (data: any) => {
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(data)
        .then(() => {
          setCopied(true);
          setTimeout(() => setCopied(false), 2000);
        })
        .catch((error) => {
          console.error('Failed to copy text: ', error);
        });
    } else {
      console.error('Clipboard API not supported');
    }
  };
  const handleClose = () => {
    setOpenModal(false);
  };

  const fetchData = async (query = '') => {
    try {
      const url = query
        ? `admin/realms/${orgName}/keys?first=0&max=51&clientId=${query}&search=true`
        : `admin/realms/${orgName}/keys`;
      const response = await ApiHttpClient.get(url);
      const apiData = response.keys;
      setKeys(apiData && apiData.length ? apiData : []);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const [searchTerm, setSearchTerm] = useDebouncedSearch((debouncedTerm: any) => {
    fetchData(debouncedTerm);
  });

  const handleSearchInputChange = (e: any) => {
    const value = e.target.value;
    setSearchTerm(value);
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      padding: '7px !important',
    },
  }));

  return (
    <>
      {' '}
      {loading ? (
        <SkeletonLoader rows={''} />
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <TextField
              size="small"
              fullWidth
              type="search"
              id="outlined-basic"
              label="Search Keys"
              variant="outlined"
              autoComplete="off"
              value={searchTerm}
              onChange={handleSearchInputChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TableContainer component={Paper} sx={{ maxHeight: '60vh' }}>
              <Table sx={{ minWidth: 700 }}>
                <TableHead sx={{ position: 'sticky', top: 0, zIndex: 1 }}>
                  <TableRow>
                    <StyledTableCell>S.No </StyledTableCell>
                    <StyledTableCell>Algorithm</StyledTableCell>
                    <StyledTableCell>Type</StyledTableCell>
                    <StyledTableCell>Kid</StyledTableCell>
                    <StyledTableCell>Use</StyledTableCell>
                    <StyledTableCell>Provider</StyledTableCell>
                    <StyledTableCell>Public keys</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {keys.length > 0 &&
                    keys
                      .filter((key: any) => !hiddenAlgorithm.includes(key.algorithm))
                      .map((keys: any, index: any) => (
                        <StyledTableRow key={keys.id}>
                          <StyledTableCell>{index + 1} </StyledTableCell>
                          <StyledTableCell>{keys.algorithm}</StyledTableCell>
                          <StyledTableCell>{keys.type}</StyledTableCell>
                          <StyledTableCell>{keys.kid}</StyledTableCell>
                          <StyledTableCell>{keys.use}</StyledTableCell>
                          <StyledTableCell>{keys.providerPriority}</StyledTableCell>
                          <StyledTableCell>
                            <Grid container spacing={0}>
                              <Grid item xs={12}>
                                {keys?.publicKey && (
                                  <Button
                                    size="small"
                                    variant="outlined"
                                    sx={{ marginRight: '10px' }}
                                    onClick={() => handleClickOpen('Public Key', keys)}
                                  >
                                    Public Key
                                  </Button>
                                )}
                                {keys?.certificate && (
                                  <Button
                                    size="small"
                                    variant="outlined"
                                    onClick={() => handleClickOpen('Certificate', keys)}
                                  >
                                    Certificate
                                  </Button>
                                )}
                              </Grid>
                            </Grid>
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      )}
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={copied}
        autoHideDuration={1500}
        message="Text copied to clipboard"
      />
      <Model
        open={openModal}
        width="800px"
        height="auto"
        handeleConfirm={'cancel'}
        title={type}
        data-modal-backdrop="static"
        cancelClick={() => handleClose()}
      >
        {type === 'Public Key' ? (
          <>
            <Grid container spacing={0} sx={{ wordBreak: 'break-all', alignItems: 'center' }}>
              <Box>
                <Typography component="span">{selectedKeys.publicKey}</Typography>
                <IconButton
                  sx={{ paddingTop: 0 }}
                  aria-label="copy text"
                  edge="end"
                  onClick={() => handleCopyClick(selectedKeys.publicKey)}
                >
                  <FileCopyIcon />
                </IconButton>
              </Box>
            </Grid>
          </>
        ) : (
          <>
            <Grid container spacing={0} sx={{ wordBreak: 'break-all', alignItems: 'center' }}>
              <Box>
                <Typography component="span"> {selectedKeys.certificate}</Typography>
                <IconButton
                  sx={{ paddingTop: 0 }}
                  aria-label="copy text"
                  edge="end"
                  onClick={() => handleCopyClick(selectedKeys.certificate)}
                >
                  <FileCopyIcon />
                </IconButton>
              </Box>
            </Grid>
          </>
        )}{' '}
      </Model>
    </>
  );
}

export default SettingsKeys;
