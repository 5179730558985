import React from 'react';
import {
  List,
  ListItem,
  ListItemText,
  Divider,
  ListItemButton,
  ListItemIcon,
  Typography,
  Tooltip,
} from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboard';
import GroupsIcon from '@mui/icons-material/Groups';
import InfoIcon from '@mui/icons-material/Info';
import HandshakeIcon from '@mui/icons-material/Handshake';
import LockPersonIcon from '@mui/icons-material/LockPerson';
import SettingsIcon from '@mui/icons-material/Settings';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setConfigSlice } from '../../store/realmSlice';
import { useTheme } from '@mui/material/styles';
import { MENU_HEADS } from '../../const/strings';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import EventNoteIcon from '@mui/icons-material/EventNote';

function SideNav({ open }: any) {
  const location = useLocation();
  const state = useSelector((state: any) => state.config);
  const orgId = state.orgId;
  const accessArray = Object.values(state.realmAccess ?? {}).flat();
  const initialState = useSelector((state: any) => state.initialConfig);
  const dispatch = useDispatch();
  const theme = useTheme();
  const menuItems = [
    { text: 'Dashboard', icon: <SpaceDashboardIcon />, link: '/auth/dashboard' },
    ...(accessArray.includes('view-clients')
      ? [
          {
            text: 'Apps',
            icon: <HandshakeIcon />,
            link: `/auth/userdashboard/${orgId}/clients`,
          },
          {
            text: 'Client Scopes',
            icon: <VerifiedUserIcon />,
            link: `/auth/userdashboard/${orgId}/clientscopes`,
          },
        ]
      : []),
    ...(accessArray.includes('view-users') || accessArray.includes('manage-users')
      ? [
          {
            text: 'Users',
            icon: <PersonIcon />,
            link: `/auth/userdashboard/${orgId}/users`,
          },
        ]
      : []),
    ...(accessArray.includes('view-realm')
      ? [{ text: 'Realm roles', icon: <AccountCircleIcon />, link: `/auth/userdashboard/${orgId}/roles` }]
      : []),
    ...(accessArray.includes('query-groups')
      ? [{ text: 'Groups', icon: <GroupsIcon />, link: `/auth/userdashboard/${orgId}/groups` }]
      : []),
    ...(accessArray.includes('view-users') && accessArray.includes('view-realm')
      ? [{ text: 'Sessions', icon: <InfoIcon />, link: `/auth/userdashboard/${orgId}/info` }]
      : []),
    ...(accessArray.includes('view-users') && accessArray.includes('view-events')
      ? [{ text: 'Events', icon: <EventNoteIcon />, link: `/auth/userdashboard/${orgId}/events` }]
      : []),
  ];

  const configureMenuItems = [
    ...(accessArray.includes('view-clients') && accessArray.includes('view-realm')
      ? [{ text: 'Settings', icon: <SettingsIcon />, link: `/auth/userdashboard/${orgId}/settings` }]
      : []),
    ...(accessArray.includes('view-identity-providers') &&
    accessArray.includes('view-realm') &&
    accessArray.includes('view-clients')
      ? [{ text: 'Authentication', icon: <LockPersonIcon />, link: `/auth/userdashboard/${orgId}/authentication` }]
      : []),
  ];

  const handleDashBoardNavigation = (menu: string) => {
    if (location.pathname !== '/auth/dashboard') {
      if (menu === MENU_HEADS.dashboard) {
        dispatch(setConfigSlice(initialState));
      }
    }
  };

  const routeSelected = (link: any, text: any) => {
    const clientScopesPath = `/auth/userdashboard/${orgId}/clientscopes`;
    const createClientScopesPath = `${clientScopesPath}/createClientScopes`;

    if (location.pathname === createClientScopesPath && text === 'Client Scopes') {
      return link === clientScopesPath || link === createClientScopesPath;
    } else if (location.pathname === clientScopesPath && text === 'Client Scopes') {
      return location.pathname === link;
    } else {
      return location.pathname === link || location.pathname.startsWith(link + '/');
    }
  };

  return (
    <>
      <List>
        {menuItems.map(({ text, icon, link }) => (
          <ListItem sx={{ display: 'block' }} key={text} disablePadding>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
              component={Link}
              to={link}
              selected={routeSelected(link, text)}
              onClick={() => handleDashBoardNavigation(text)}
            >
              <Tooltip title={text} placement="right-start">
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                  style={{ color: theme.palette.text.primary }}
                >
                  {icon}
                </ListItemIcon>
              </Tooltip>
              <ListItemText
                sx={{ opacity: open ? 1 : 0 }}
                primary={text}
                style={{ color: theme.palette.text.primary }}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      {open && (
        <List>
          <ListItem disablePadding sx={{ paddingLeft: '15px' }}>
            <ListItemText primary="Configure" style={{ color: theme.palette.text.primary }} />
          </ListItem>
        </List>
      )}
      <Divider />

      <List>
        {configureMenuItems.map(({ text, icon, link }) => (
          <ListItem sx={{ display: 'block' }} key={text} disablePadding>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
              component={Link}
              to={link}
              selected={location.pathname.startsWith(link)}
              onClick={() => handleDashBoardNavigation(text)}
            >
              <Tooltip title={text} placement="right-start">
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                  style={{ color: theme.palette.text.primary }}
                >
                  {icon}
                </ListItemIcon>
              </Tooltip>
              <ListItemText
                sx={{ opacity: open ? 1 : 0 }}
                primary={text}
                style={{ color: theme.palette.text.primary }}
              />
            </ListItemButton>
          </ListItem>
          // <ListItem disablePadding>
          //   {accessArray.includes('view-clients') && accessArray.includes('view-realm') && (
          //     <ListItemButton component={Link} to={`/auth/userdashboard/${orgId}/settings`}>
          //       <ListItemIcon>
          //         <SettingsIcon />
          //       </ListItemIcon>
          //       <ListItemText primary="Settings" />
          //     </ListItemButton>
          //   )}
          // </ListItem>
          // <ListItem disablePadding>
          //   {accessArray.includes('view-authorization') && (
          //     <ListItemButton component={Link} to={`/auth/userdashboard/${orgId}/authentication`}>
          //       <ListItemIcon>
          //         <LockPersonIcon />
          //       </ListItemIcon>
          //       <ListItemText primary="Authentication" />
          //     </ListItemButton>
          //   )}
          // </ListItem>
        ))}
      </List>
    </>
  );
}

export default SideNav;
