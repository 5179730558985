import { ToastContainer, toast as toaster } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const success = (message: string) => {
  toaster.success(message);
};

export const error = (message: string) => {
  toaster.error(message);
};

const Toast = () => {
  return <ToastContainer />;
};

export default Toast;
