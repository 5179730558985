import { Button, Card, Grid, Paper, Skeleton, Tooltip, Typography, styled } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import SettingsIcon from '@mui/icons-material/Settings';
import PersonIcon from '@mui/icons-material/Person';
import Layout from '../components/Layout';
import { useEffect, useState } from 'react';
import { ApiHttpClient } from '../interceptor/axiosInterceptor';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

function UserDashboard() {
  const [clientorganizations, setclientOrganizations] = useState([]);
  const { orgName } = useParams();
  const state = useSelector((state: any) => state.config);
  const orgId = state.orgId;
  const [loading, setLoading] = useState(true);
  let navigate = useNavigate();
  const hiddenClientIds = ['account', 'account-console', 'admin-cli', 'broker', 'realm-management', 'admin-cli'];
  const DemoPaper = styled(Paper)(({ theme }) => ({
    maxWidth: 250,
    height: 'auto',
    // background: theme.palette.paper.main,
    padding: theme.spacing(2),
    ...theme.typography.body2,
    textAlign: 'center',
  }));
  const handleClickNavigate = (organizationName: any) => {
    navigate(`/auth/userdashboard/profile`);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = `admin/realms/${orgName}/clients`;
        const response = await ApiHttpClient.get(url);
        setclientOrganizations(response.filter((org: any) => !hiddenClientIds.includes(org.clientId)));
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  const handleUser = async (id: any) => {
    navigate(`/auth/userdashboard/${orgName}/users`);
    // { state: { clientId } }
    // ${id} not dont remove this should be used in url
  };

  return (
    <Grid sx={{ marginTop: 10 }}>
      <Layout>
        <div className="h-full bg-white p-10 mt-4">
          <Grid container justifyContent="center" alignItems="center" spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h4" align="center" gutterBottom>
                Welcome
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ textAlign: 'center', marginBottom: '21px' }}>
              <Typography variant="body1">
                Hey <b>{orgId.toUpperCase()}</b>
                {`, You have privileges to view or manage the following apps below`}.
              </Typography>
            </Grid>
          </Grid>

          {loading ? (
            <Grid container justifyContent="center" spacing={4} textAlign="center">
              {[...Array(4)].map((_, index) => (
                <Grid item xs={12} md={3}>
                  <Card
                    sx={{
                      padding: 2,
                      textAlign: 'center',
                      height: '150px',
                    }}
                  >
                    <Skeleton variant="text" width={120} />
                    <Grid
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginTop: 2,
                      }}
                    >
                      <Skeleton variant="circular" width={40} height={30} />
                      <Skeleton variant="circular" width={40} height={30} />
                      <Skeleton variant="circular" width={40} height={30} />
                    </Grid>
                  </Card>
                </Grid>
              ))}
            </Grid>
          ) : (
            <Grid container justifyContent="center" spacing={4} textAlign="center">
              {clientorganizations.map((organization: any, index: any) => (
                <Grid key={index} item xs={12} sm={6} md={3}>
                  <DemoPaper variant="elevation">
                    <Typography
                      variant="h6"
                      align="center"
                      sx={{
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                      }}
                      gutterBottom
                    >
                      <Tooltip title={organization.clientId}>
                        <span>{organization.clientId}</span>
                      </Tooltip>
                    </Typography>
                    <Grid
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginTop: 2,
                      }}
                    >
                      <Button
                        sx={{
                          '&:hover': {
                            backgroundColor: 'transparent',
                          },
                        }}
                      >
                        <Tooltip
                          title="View"
                          placement="bottom"
                          sx={{
                            cursor: 'pointer',
                            border: '2px solid',
                            padding: 0.5,
                            borderRadius: 5,
                          }}
                        >
                          <VisibilityIcon fontSize="large" />
                        </Tooltip>
                      </Button>
                      <Button
                        onClick={() => handleClickNavigate(organization)}
                        sx={{
                          '&:hover': {
                            backgroundColor: 'transparent',
                          },
                        }}
                      >
                        <Tooltip
                          title="View Profile"
                          placement="bottom"
                          sx={{
                            cursor: 'pointer',
                            border: '2px solid ',
                            padding: 0.5,
                            borderRadius: 5,
                          }}
                        >
                          <PersonIcon fontSize="large" />
                        </Tooltip>
                      </Button>
                      <Button
                        onClick={() => handleUser(organization.id)}
                        sx={{
                          '&:hover': {
                            backgroundColor: 'transparent',
                          },
                        }}
                      >
                        <Tooltip
                          title="Manage"
                          placement="bottom"
                          sx={{
                            cursor: 'pointer',
                            border: '2px solid ',
                            padding: 0.5,
                            borderRadius: 5,
                          }}
                        >
                          <SettingsIcon fontSize="large" />
                        </Tooltip>
                      </Button>
                    </Grid>
                  </DemoPaper>
                </Grid>
              ))}
            </Grid>
          )}
        </div>
      </Layout>
    </Grid>
  );
}

export default UserDashboard;
