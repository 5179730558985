import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ApiHttpClient } from '../../interceptor/axiosInterceptor';
import {
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  TextField,
  Button,
  Snackbar,
} from '@mui/material';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { Visibility, VisibilityOff } from '@mui/icons-material';
function Credentials() {
  const state = useSelector((state: any) => state.config);
  const orgId = state.orgId;
  const [clientSeceret, setClientSecret]: any = useState([]);
  const [selectedAuth, setSelectedAuth]: any = useState('');
  const [authData, setAuthData]: any = useState([]);
  const [copied, setCopied] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const clientState = useSelector((state: any) => state.client);
  const fetchData = async () => {
    try {
      const url = `admin/realms/${orgId}/clients/${clientState.id}/client-secret`;
      const response = await ApiHttpClient.get(url);
      const apiData = response;
      if (apiData) {
        setClientSecret(apiData);
        console.log(apiData, 'jj');
      } else {
        console.error('API returned undefined data');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  const fetchClientAuth = async () => {
    try {
      const url = `admin/realms/${orgId}/authentication/client-authenticator-providers`;
      const response = await ApiHttpClient.get(url);
      const apiData = response;
      if (apiData) {
        setAuthData(apiData);
        console.log(apiData, 'jj');
        const defaultAuth = apiData.find((auth: any) => auth.displayName === 'Client Id and Secret');
        if (defaultAuth) {
          setSelectedAuth(defaultAuth.displayName);
        }
      } else {
        console.error('API returned undefined data');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  const handleCopyClick = () => {
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(clientSeceret?.value)
        .then(() => {
          setCopied(true);
          setTimeout(() => setCopied(false), 2000);
        })
        .catch((error) => {
          console.error('Failed to copy text: ', error);
        });
    } else {
      console.error('Clipboard API not supported');
    }
  };
  const handleCloseSnackbar = () => {
    setCopied(false);
  };
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };
  useEffect(() => {
    fetchData();
    fetchClientAuth();
  }, [orgId]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl fullWidth size="small">
            <InputLabel id="demo-simple-select-helper-label">Client Authenticator</InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={selectedAuth}
              // onChange={(e: any) => setSelectedLoginTheme(e.target.value)}
              label="Select Login theme "
            >
              {authData.map((theme: any) => (
                <MenuItem key={theme.displayName} value={theme.displayName}>
                  {theme.displayName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl fullWidth variant="outlined">
            <InputLabel htmlFor="outlined-adornment-password">Client Secret</InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={showPassword ? 'text' : 'password'}
              size="small"
              value={clientSeceret?.value || ''}
              label="Client Secret"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                  <IconButton aria-label="copy text" edge="end" onClick={handleCopyClick}>
                    <FileCopyIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={copied}
            autoHideDuration={1500}
            onClose={handleCloseSnackbar}
            message="Text copied to clipboard"
          />
        </Grid>
      </Grid>
    </>
  );
}

export default Credentials;
