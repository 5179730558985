import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import {
  Grid,
  TextField,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
  styled,
  TableCell,
  tableCellClasses,
  IconButton,
  Box,
  Typography,
  Collapse,
  TablePagination,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Popover,
  InputAdornment,
  OutlinedInput,
  Theme,
  useTheme,
  SelectChangeEvent,
  ListItem,
  Input,
  useAutocomplete,
  List,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import SkeletonLoader from '../SkeletonLoader';
import { ApiHttpClient } from '../../interceptor/axiosInterceptor';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { StyledTableRow } from '../../styles/tableStyles';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { paginationRowsPerPage } from '../../const/strings';
import useDebouncedSearch from '../../hooks/useDebouncedSearch';
import { LocalizationProvider, DatePicker, ArrowDropDownIcon } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import Chip from '@mui/material/Chip';
import usePopupMargin from '../../hooks/usePopupMargin';
import { setConfigSlice } from '../../store/realmSlice';
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: '7px !important',
  },
}));

function UserEvents() {
  const [loading, setLoading] = useState(true);
  const [openRows, setOpenRows] = useState<{ [key: string]: boolean }>({});

  const state = useSelector((state: any) => state.config);
  const orgId = state.orgId;
  const [events, setEvents] = useState<any[]>([]);
  const [count, setCount] = useState(10);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [firstValue, setFirstValue] = useState(0);
  const [eventType, setEventType] = useState([]);
  const [enabledEventTypes, setEnabledEventTypes] = useState<any[]>([]);
  const [client, setClient] = useState('');
  const [ipAddress, setIpAddress] = useState('');
  const initialRender = useRef(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [submittedData, setSubmittedData] = useState<any[]>([]);
  const chipRef: any = useRef(null);
  const open = Boolean(anchorEl);
  const [userId, setUserId] = useState('');
  const [fromDate, setFromDate] = useState(null);
  const [query, setQuery] = useState('');
  const [hasDeleted, setHasDeleted] = useState(false);
  const [toDate, setToDate] = useState(null);
  const [expanded, setExpanded] = useState(false);
  const [expanded1, setExpanded1] = useState(false);
  const [value, setValue] = useState(enabledEventTypes);
  const [openTypes, setOpenTypes] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredOptions, setFilteredOptions] = useState<any[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<any[]>([]);
  const [displaySelectedTextOptions, setDisplaySelectedTextOptions] = useState<any[]>([]);
  const initialState = useSelector((state: any) => state.initialConfig);
  const loginOrgId = initialState.orgId;
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    mainDropdown: '',
    nestedDropdown1: '',
    nestedDropdown2: '',
    textField1: '',
    textField2: '',
  });
  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
        setOpenTypes(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);
  useEffect(() => {
    // if (initialRender.current) {
    //   initialRender.current = false;
    //   return;
    // }
    fetchData(query ? query : '');
  }, [orgId, page, rowsPerPage]);

  const popupMarginTop = usePopupMargin(inputRef, [selectedOptions, openTypes]);

  const fetchData = async (query = '') => {
    try {
      const response1 = await ApiHttpClient.get(
        orgId !== loginOrgId
          ? `admin/${loginOrgId}/console/whoami?currentRealm=${orgId}`
          : `admin/${orgId}/console/whoami?currentRealm=${orgId}`,
      );
      const nameResponse1 = await ApiHttpClient.get(`admin/realms/${loginOrgId}/ui-ext/realms/names?`);
      let reformedState: any = {
        ...state,
        userId: response1.userId,
        realms: nameResponse1,
        realmAccess: response1.realm_access,
      };
      dispatch(setConfigSlice(reformedState));
      const url = query
        ? `/admin/realms/${orgId}/events?${query}&first=${firstValue}&max=${rowsPerPage!}`
        : `/admin/realms/${orgId}/events?first=${firstValue}&max=${rowsPerPage!}`;
      const paginationUrl = query ? `/admin/realms/${orgId}/events?${query}` : `/admin/realms/${orgId}/events`;
      const response = await ApiHttpClient.get(url);
      const paginationResponse = await ApiHttpClient.get(paginationUrl);
      const configUrl = `/admin/realms/${orgId}/events/config`;
      const configResponse = await ApiHttpClient.get(configUrl);
      const enabledEventTypes = configResponse['enabledEventTypes'];
      setEnabledEventTypes(enabledEventTypes ? enabledEventTypes : []);
      setFilteredOptions(enabledEventTypes ? enabledEventTypes : []);
      setCount(paginationResponse.length);
      setEvents(response ? response : []);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleRowClick = (eventId: any) => {
    setOpenRows((prevOpenRows) => {
      const newOpenRows: any = Object.keys(prevOpenRows).reduce((acc: any, key) => {
        acc[key] = false;
        return acc;
      }, {});

      newOpenRows[eventId] = !prevOpenRows[eventId];
      return newOpenRows;
    });
  };

  const renderEventDetails = (event: any) => {
    const details = event.details;
    switch (event.type) {
      case 'CODE_TO_TOKEN':
        return (
          <>
            <Typography sx={{ paddingBottom: '10px' }}>
              token_id:{' '}
              <Typography component="span" sx={{ paddingLeft: '15px' }}>
                {details?.token_id}
              </Typography>{' '}
            </Typography>
            <Typography sx={{ paddingBottom: '10px' }}>
              grant_type:{' '}
              <Typography component="span" sx={{ paddingLeft: '15px' }}>
                {details?.grant_type}
              </Typography>{' '}
            </Typography>
            <Typography sx={{ paddingBottom: '10px' }}>
              refresh_token_type:{' '}
              <Typography component="span" sx={{ paddingLeft: '15px' }}>
                {details?.refresh_token_type}
              </Typography>{' '}
            </Typography>
            <Typography sx={{ paddingBottom: '10px' }}>
              scope:{' '}
              <Typography component="span" sx={{ paddingLeft: '15px' }}>
                {details?.scope}
              </Typography>{' '}
            </Typography>
            <Typography sx={{ paddingBottom: '10px' }}>
              refresh_token_id:{' '}
              <Typography component="span" sx={{ paddingLeft: '15px' }}>
                {details?.refresh_token_id}
              </Typography>{' '}
            </Typography>
            <Typography sx={{ paddingBottom: '10px' }}>
              code_id:{' '}
              <Typography component="span" sx={{ paddingLeft: '15px' }}>
                {details?.code_id}
              </Typography>{' '}
            </Typography>
            <Typography sx={{ paddingBottom: '10px' }}>
              client_auth_method:{' '}
              <Typography component="span" sx={{ paddingLeft: '15px' }}>
                {details?.client_auth_method}
              </Typography>{' '}
            </Typography>
          </>
        );
      case 'LOGIN':
        return (
          <>
            <Typography sx={{ paddingBottom: '10px' }}>
              auth_method:
              <Typography component="span" sx={{ paddingLeft: '15px' }}>
                {details?.auth_method}
              </Typography>{' '}
            </Typography>
            <Typography sx={{ paddingBottom: '10px' }}>
              auth_type:
              <Typography component="span" sx={{ paddingLeft: '15px' }}>
                {details?.auth_type}
              </Typography>{' '}
            </Typography>
            <Typography sx={{ paddingBottom: '10px' }}>
              redirect_uri:
              <Typography component="span" sx={{ paddingLeft: '15px' }}>
                {details?.redirect_uri}
              </Typography>{' '}
            </Typography>
            <Typography sx={{ paddingBottom: '10px' }}>
              consent:
              <Typography component="span" sx={{ paddingLeft: '15px' }}>
                {details?.consent}
              </Typography>{' '}
            </Typography>
            <Typography sx={{ paddingBottom: '10px' }}>
              code_id:
              <Typography component="span" sx={{ paddingLeft: '15px' }}>
                {details?.code_id}
              </Typography>{' '}
            </Typography>
            <Typography sx={{ paddingBottom: '10px' }}>
              username:
              <Typography component="span" sx={{ paddingLeft: '15px' }}>
                {details?.username}
              </Typography>{' '}
            </Typography>
          </>
        );
      case 'LOGIN_ERROR':
        return (
          <>
            <Typography sx={{ paddingBottom: '10px' }}>
              auth_method:
              <Typography component="span" sx={{ paddingLeft: '15px' }}>
                {details?.auth_method}
              </Typography>{' '}
            </Typography>
            <Typography sx={{ paddingBottom: '10px' }}>
              auth_type:
              <Typography component="span" sx={{ paddingLeft: '15px' }}>
                {details?.auth_type}
              </Typography>{' '}
            </Typography>
            <Typography sx={{ paddingBottom: '10px' }}>
              redirect_uri:
              <Typography component="span" sx={{ paddingLeft: '15px' }}>
                {details?.redirect_uri}
              </Typography>{' '}
            </Typography>
            <Typography sx={{ paddingBottom: '10px' }}>
              code_id:
              <Typography component="span" sx={{ paddingLeft: '15px' }}>
                {details?.code_id}
              </Typography>{' '}
            </Typography>
            <Typography sx={{ paddingBottom: '10px' }}>
              username:
              <Typography component="span" sx={{ paddingLeft: '15px' }}>
                {details?.username}
              </Typography>{' '}
            </Typography>
            <Typography sx={{ paddingBottom: '10px' }}>
              error:
              <Typography component="span" sx={{ paddingLeft: '15px' }}>
                {details?.error}
              </Typography>{' '}
            </Typography>
          </>
        );
      case 'LOGOUT':
        return (
          <>
            <Typography>
              redirect_uri:
              <Typography component="span" sx={{ paddingLeft: '15px' }}>
                {details?.redirect_uri}
              </Typography>{' '}
            </Typography>
          </>
        );
      default:
        return (
          <>
            <Typography>auth_method:{details?.auth_method}</Typography>
            <Typography>auth_type:{details?.auth_type}</Typography>
            <Typography>redirect_uri:{details?.redirect_uri}</Typography>
            <Typography>code_id:{details?.code_id}</Typography>
            <Typography>username:{details?.username}</Typography>
            <Typography>error:{details?.error}</Typography>
            <Typography>client_auth_method:{details?.client_auth_method}</Typography>
            <Typography>token_id:{details?.token_id}</Typography>
            <Typography>grant_type:{details?.grant_type}</Typography>
            <Typography>refresh_token_type:{details?.refresh_token_type}</Typography>
            <Typography>code_id:{details?.code_id}</Typography>
            <Typography>refresh_token_id:{details?.refresh_token_id}</Typography>
          </>
        );
    }
  };

  const handleChangePage = (event: any, newPage: any) => {
    const firstValue = newPage * rowsPerPage;
    setFirstValue(firstValue);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setFirstValue(0);
  };

  const handleUserIdChange = (event: any) => setUserId(event.target.value);
  const handleClientChange = (event: any) => setClient(event.target.value);
  const handleIpAddressChange = (event: any) => setIpAddress(event.target.value);

  const handleSubmit = () => {
    const dateFormat = 'YYYY-MM-DD';
    setPage(0);
    setFirstValue(0);
    setDisplaySelectedTextOptions(selectedOptions);
    const data = [
      selectedOptions.length > 0 && { label: 'Event Type', value: selectedOptions },
      userId && { label: 'User ID', value: userId },
      client && { label: 'Client', value: client },
      ipAddress && { label: 'IP Address', value: ipAddress },
      fromDate && { label: 'From Date', value: dayjs(fromDate).format(dateFormat) },
      toDate && { label: 'To Date', value: dayjs(toDate).format(dateFormat) },
    ].filter(Boolean);

    setSubmittedData(data);

    const query = [
      selectedOptions.length > 0 ? 'type=' + selectedOptions.join('&type=') : '',
      userId ? `userId=${userId}` : '',
      client ? `client=${client}` : '',
      ipAddress ? `ipAddress=${ipAddress}` : '',
      fromDate ? `dateFrom=${dayjs(fromDate).format(dateFormat)}` : '',
      toDate ? `dateTo=${dayjs(toDate).format(dateFormat)}` : '',
    ]
      .filter(Boolean)
      .join('&');
    setQuery(query);
    fetchData(query ? query : '');
    setAnchorEl(null);
    handlePopoverClose();
  };

  const handleClose = () => {
    setAnchorEl(null);
    // setSubmittedData([]);
  };

  const id = open ? 'filter-popover' : undefined;

  const handleDropdownClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const theme = useTheme();
  const initialDisplayCount = 3;

  const toggleExpand = () => {
    setExpanded(!expanded);
  };
  const displayedEventTypes = expanded ? eventType : eventType.slice(0, initialDisplayCount);

  function getStyles(name: string, eventType: readonly string[], theme: Theme) {
    return {
      fontWeight:
        eventType.indexOf(name) === -1 ? theme?.typography.fontWeightRegular : theme?.typography.fontWeightMedium,
    };
  }

  const handleChange1 = (event: SelectChangeEvent<typeof eventType>) => {
    const {
      target: { value },
    }: any = event;
    setEventType(typeof value === 'string' ? value.split(',') : value);
  };

  useEffect(() => {
    if (hasDeleted) {
      const dateFormat = 'YYYY-MM-DD';

      const query = [
        selectedOptions.length > 0 ? 'type=' + selectedOptions.join('&type=') : '',
        userId ? `user=${userId}` : '',
        client ? `client=${client}` : '',
        ipAddress ? `ipAddress=${ipAddress}` : '',
        fromDate ? `dateFrom=${dayjs(fromDate).format(dateFormat)}` : '',
        toDate ? `dateTo=${dayjs(toDate).format(dateFormat)}` : '',
      ]
        .filter(Boolean)
        .join('&');
      setQuery(query);
      fetchData(query);
      setHasDeleted(false);
    }
  }, [hasDeleted, selectedOptions, userId, client, ipAddress, fromDate, toDate]);

  const handleDeleteItem = (label: string, itemToDelete: string) => {
    if (label === 'Event Type') {
      setEventType((prev) => prev.filter((item) => item !== itemToDelete));
      setSelectedOptions(selectedOptions.filter((o) => o !== itemToDelete));
      setDisplaySelectedTextOptions(displaySelectedTextOptions.filter((o) => o !== itemToDelete));
    }
    if (label === 'User ID') {
      setUserId('');
    }
    if (label === 'Client') {
      setClient('');
    }
    if (label === 'IP Address') {
      setIpAddress('');
    }
    if (label === 'From Date') {
      setFromDate(null);
    }
    if (label === 'To Date') {
      setToDate(null);
    }

    setSubmittedData((prevData) => prevData.filter((item) => item.label !== label || item.value !== itemToDelete));
    const dateFormat = 'YYYY-MM-DD';

    const query = [
      selectedOptions.length > 0 ? 'type=' + selectedOptions.join('&type=') : '',
      userId ? `user=${userId}` : '',
      client ? `client=${client}` : '',
      ipAddress ? `ipAddress=${ipAddress}` : '',
      fromDate ? `dateFrom=${dayjs(fromDate).format(dateFormat)}` : '',
      toDate ? `dateTo=${dayjs(toDate).format(dateFormat)}` : '',
    ]
      .filter(Boolean)
      .join('&');
    setQuery(query);
    setPage(0);
    setFirstValue(0);
    setHasDeleted(true);
  };
  const handleEventTypeDeleteItem = () => {
    setSelectedOptions([]);
    setDisplaySelectedTextOptions([]);
    setPage(0);
    setFirstValue(0);
    setSubmittedData((prevData) => prevData.filter((item) => item.label !== 'Event Type'));
    setHasDeleted(true);
  };

  const handleSearchChange = (event: any) => {
    const value = event.target.value;
    setSearchTerm(value);
    setFilteredOptions(enabledEventTypes.filter((option: any) => option.toLowerCase().includes(value.toLowerCase())));
    setOpenTypes(true);
  };

  const handleOpenEventTypes = () => {
    setOpenTypes(!openTypes);
  };

  const handleSelectOption = (option: any) => {
    if (!selectedOptions.includes(option)) {
      setSelectedOptions([...selectedOptions, option]);
    }
    if (selectedOptions.includes(option)) {
      setSelectedOptions(selectedOptions.filter((item) => item !== option));
    }
    setDisplaySelectedTextOptions(selectedOptions);
    setSearchTerm('');
    setFilteredOptions(enabledEventTypes);
  };

  const handleRemoveOption = (option: any) => {
    setSelectedOptions(selectedOptions.filter((o) => o !== option));
    setDisplaySelectedTextOptions(displaySelectedTextOptions.filter((o: any) => o !== option));
  };

  const handleExpand = () => {
    setExpanded1(!expanded1);
  };
  const getStyles1 = (option: string, selectedOptions: string[], theme: any) => {
    return {
      background: selectedOptions.includes(option) ? '#1976d21f' : 'transparent',
      color: theme.palette.text.primary,
      cursor: 'pointer',
      padding: '8px 16px',
    };
  };

  const minInitialOptionsInField = 2;
  const displaySelectedOptions = expanded1 ? selectedOptions : selectedOptions.slice(0, minInitialOptionsInField);
  const displaySelectedOptionsText = expanded
    ? displaySelectedTextOptions
    : displaySelectedTextOptions.slice(0, minInitialOptionsInField);
  return (
    <>
      {loading ? (
        <SkeletonLoader rows={''} />
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Box sx={{ p: 0 }}>
              <TextField
                fullWidth
                label="Search Event"
                value={formData.nestedDropdown1}
                onClick={handleDropdownClick}
                size="small"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <ArrowDropDownIcon />
                    </InputAdornment>
                  ),
                  readOnly: true,
                }}
              />
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                sx={{ maxWidth: 1600 }}
              >
                <Box component="form" onSubmit={handleSubmit} sx={{ p: 2, width: '300px' }}>
                  <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                  >
                    <Box component="form" onSubmit={handleSubmit} sx={{ p: 2, width: 500 }}>
                      <Grid container spacing={1}>
                        {/* <FormControl fullWidth sx={{ m: 1, mt: 1 }}>
                          <InputLabel id="demo-multiple-chip-label">Event Saved Type</InputLabel>
                          <Select
                            labelId="demo-multiple-chip-label"
                            id="demo-multiple-chip"
                            size="small"
                            multiple
                            value={eventType}
                            onChange={handleChange1}
                            input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                            renderValue={(selected) => (
                              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {selected.map((value: any) => {
                                  return <Chip key={value} label={value} />;
                                })}
                              </Box>
                            )}
                            MenuProps={MenuProps}
                          >
                            {enabledEventTypes.length > 0
                              ? enabledEventTypes.map((name: any) => (
                                  <MenuItem key={name} value={name} style={getStyles(name, eventType, theme)}>
                                    {name}
                                  </MenuItem>
                                ))
                              : []}
                          </Select>
                        </FormControl> */}
                        <FormControl fullWidth sx={{ m: 1, mt: 1 }} ref={containerRef}>
                          <InputLabel id="demo-multiple-chip-label">Event Saved Type</InputLabel>
                          <OutlinedInput
                            id="select-multiple-chip"
                            label="Event Saved Type"
                            size="small"
                            onClick={() => handleOpenEventTypes()}
                            onChange={(e: any) => handleSearchChange(e)}
                            // value={selectedOptions}
                            sx={{ width: '100%' }}
                            startAdornment={
                              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {displaySelectedOptions.map((option) => (
                                  <Chip
                                    key={option}
                                    label={option}
                                    onDelete={() => handleRemoveOption(option)}
                                    sx={{ gap: 0.5 }}
                                  />
                                ))}
                                {selectedOptions.length > minInitialOptionsInField && (
                                  <Button
                                    onClick={() => handleExpand()}
                                    sx={{
                                      // color: '#1976d2',
                                      textTransform: 'none',
                                      ml: 1,
                                    }}
                                  >
                                    {expanded1
                                      ? 'Show Less'
                                      : `${selectedOptions.length - minInitialOptionsInField} more`}
                                  </Button>
                                )}
                              </Box>
                            }
                            ref={inputRef}
                            endAdornment={
                              <InputAdornment position="end">
                                <ArrowDropDownIcon />
                              </InputAdornment>
                            }
                          />
                          {openTypes && (
                            <Paper
                              sx={{
                                position: 'absolute',
                                zIndex: 10,
                                width: '100%',
                                mt: `${popupMarginTop}px`,
                                maxHeight: 200,
                                overflow: 'auto',
                                cursor: 'pointer',
                              }}
                            >
                              <List>
                                {filteredOptions.map((option, index) => (
                                  <ListItem
                                    key={index}
                                    sx={{
                                      '&:hover': {
                                        background: 'grey',
                                      },
                                    }}
                                    style={getStyles1(option, selectedOptions, theme)}
                                    onClick={() => handleSelectOption(option)}
                                  >
                                    {option}
                                  </ListItem>
                                ))}
                              </List>
                            </Paper>
                          )}
                        </FormControl>

                        <TextField
                          size="small"
                          fullWidth
                          label="User ID"
                          variant="outlined"
                          value={userId}
                          onChange={handleUserIdChange}
                          sx={{ m: 1, mt: 1 }}
                        />

                        <TextField
                          size="small"
                          fullWidth
                          label="Client"
                          variant="outlined"
                          value={client}
                          onChange={handleClientChange}
                          sx={{ m: 1, mt: 1 }}
                        />

                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            label="From Date"
                            value={fromDate}
                            sx={{ m: 1, mt: 1 }}
                            slotProps={{ textField: { size: 'small' } }}
                            onChange={(newValue: any) => setFromDate(newValue)}
                          />
                          <DatePicker
                            label="To Date"
                            value={toDate}
                            sx={{ m: 1, mt: 1 }}
                            slotProps={{ textField: { size: 'small' } }}
                            onChange={(newValue: any) => setToDate(newValue)}
                          />
                        </LocalizationProvider>

                        <TextField
                          size="small"
                          fullWidth
                          label="IP Address"
                          variant="outlined"
                          value={ipAddress}
                          onChange={handleIpAddressChange}
                          sx={{ m: 1, mt: 1 }}
                        />

                        <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
                          <Button fullWidth variant="contained" color="primary" onClick={handleSubmit}>
                            Submit
                          </Button>
                          <Button
                            fullWidth
                            sx={{ marginLeft: '5px' }}
                            variant="outlined"
                            color="primary"
                            onClick={handleClose}
                          >
                            Close
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                  </Popover>
                </Box>
              </Popover>
            </Box>
          </Grid>

          {submittedData && submittedData.length > 0 && (
            <Grid item xs={12} style={{ paddingTop: '0px', width: '300px' }}>
              {submittedData.map((ele, index) => {
                if (ele.label === 'Event Type' && ele.value && ele.value.length > 0 && selectedOptions.length > 0) {
                  return (
                    <Grid key={index} container spacing={2} sx={{ marginLeft: '5px', marginTop: '10px' }}>
                      <Paper>
                        <Grid item xs={12} sx={{ padding: '8px' }}>
                          <Typography component={'span'} variant="body1">
                            Event Type:
                          </Typography>
                          {displaySelectedOptionsText.map((data: any, idx: any) => (
                            <Chip
                              key={idx}
                              ref={chipRef}
                              label={data}
                              onDelete={() => handleDeleteItem('Event Type', data)}
                              sx={{
                                margin: '2px',
                                maxWidth: '200px',
                                flexShrink: 1,
                              }}
                            />
                          ))}
                          {displaySelectedTextOptions.length > minInitialOptionsInField && (
                            <Button
                              onClick={toggleExpand}
                              sx={{
                                textTransform: 'none',
                                ml: 1,
                              }}
                            >
                              {expanded
                                ? 'Show less'
                                : `${displaySelectedTextOptions.length - minInitialOptionsInField} more`}
                            </Button>
                          )}
                          <Button
                            onClick={() => handleEventTypeDeleteItem()}
                            sx={{
                              textTransform: 'none',
                              ml: 1,
                              marginLeft: 'auto',
                            }}
                          >
                            <CancelOutlinedIcon />
                          </Button>
                        </Grid>
                      </Paper>
                    </Grid>
                  );
                }

                if (ele.label === 'User ID' && ele.value && userId) {
                  return (
                    <Grid container spacing={2} sx={{ marginLeft: '5px', marginTop: '10px' }}>
                      <Paper>
                        <Grid item xs={12} sx={{ padding: '8px' }}>
                          <Typography component={'span'} variant="body1">
                            User ID:
                          </Typography>
                          <Chip
                            key={ele.value}
                            label={ele.value}
                            sx={{
                              margin: '2px',
                            }}
                          />
                          <Button
                            onClick={() => handleDeleteItem('User ID', ele.value)}
                            sx={{
                              textTransform: 'none',
                              ml: 1,
                            }}
                          >
                            <CancelOutlinedIcon />
                          </Button>
                        </Grid>
                      </Paper>
                    </Grid>
                  );
                }

                if (ele.label === 'Client' && ele.value && client) {
                  return (
                    <Grid container spacing={2} sx={{ marginLeft: '5px', marginTop: '10px' }}>
                      <Paper>
                        <Grid item xs={12} sx={{ padding: '8px' }}>
                          <Typography component={'span'} variant="body1">
                            Client:
                          </Typography>
                          <Chip
                            key={ele.value}
                            label={ele.value}
                            sx={{
                              margin: '2px',
                            }}
                          />
                          <Button
                            onClick={() => handleDeleteItem('Client', ele.value)}
                            sx={{
                              textTransform: 'none',
                              ml: 1,
                            }}
                          >
                            <CancelOutlinedIcon />
                          </Button>
                        </Grid>
                      </Paper>
                    </Grid>
                  );
                }

                if (ele.label === 'IP Address' && ele.value && ipAddress) {
                  return (
                    <Grid container spacing={2} sx={{ marginLeft: '5px', marginTop: '10px' }}>
                      <Paper>
                        <Grid item xs={12} sx={{ padding: '8px' }}>
                          <Typography component={'span'} variant="body1">
                            IP Address:
                          </Typography>
                          <Chip
                            key={ele.value}
                            label={ele.value}
                            sx={{
                              margin: '2px',
                            }}
                          />
                          <Button
                            onClick={() => handleDeleteItem('IP Address', ele.value)}
                            sx={{
                              textTransform: 'none',
                              ml: 1,
                            }}
                          >
                            <CancelOutlinedIcon />
                          </Button>
                        </Grid>
                      </Paper>
                    </Grid>
                  );
                }

                if (ele.label === 'From Date' && ele.value && fromDate) {
                  return (
                    <Grid container spacing={2} sx={{ marginLeft: '5px', marginTop: '10px' }}>
                      <Paper>
                        <Grid item xs={12} sx={{ padding: '8px' }}>
                          <Typography component={'span'} variant="body1">
                            From Date:
                          </Typography>
                          <Chip
                            key={ele.value}
                            label={ele.value}
                            sx={{
                              margin: '2px',
                            }}
                          />
                          <Button
                            onClick={() => handleDeleteItem('From Date', ele.value)}
                            sx={{
                              textTransform: 'none',
                              ml: 1,
                            }}
                          >
                            <CancelOutlinedIcon />
                          </Button>
                        </Grid>
                      </Paper>
                    </Grid>
                  );
                }

                if (ele.label === 'To Date' && ele.value && toDate) {
                  return (
                    <Grid container spacing={2} sx={{ marginLeft: '5px', marginTop: '10px' }}>
                      <Paper>
                        <Grid item xs={12} sx={{ padding: '8px' }}>
                          <Typography component={'span'} variant="body1">
                            To Date:
                          </Typography>
                          <Chip
                            key={ele.value}
                            label={ele.value}
                            sx={{
                              margin: '2px',
                            }}
                          />
                          <Button
                            onClick={() => handleDeleteItem('To Date', ele.value)}
                            sx={{
                              textTransform: 'none',
                              ml: 1,
                            }}
                          >
                            <CancelOutlinedIcon />
                          </Button>
                        </Grid>
                      </Paper>
                    </Grid>
                  );
                }

                return null;
              })}
            </Grid>
          )}
          <Grid item xs={12}>
            <TableContainer component={Paper} sx={{ maxHeight: '60vh' }}>
              <Table sx={{ minWidth: 700 }}>
                <TableHead sx={{ position: 'sticky', top: 0, zIndex: 1 }}>
                  <TableRow>
                    <StyledTableCell></StyledTableCell>
                    <StyledTableCell>Time</StyledTableCell>
                    <StyledTableCell>User</StyledTableCell>
                    <StyledTableCell>Event saved type</StyledTableCell>
                    <StyledTableCell>IP address</StyledTableCell>
                    <StyledTableCell>Client</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {events.map((event, index) => (
                    <React.Fragment key={index}>
                      <StyledTableRow>
                        <StyledTableCell>
                          <IconButton aria-label="expand row" size="small" onClick={() => handleRowClick(index)}>
                            {openRows[index] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                          </IconButton>
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          {/* {event.time} */}
                          {dayjs(event.time).format('MMMM D, YYYY [at] h:mm A')}
                        </StyledTableCell>
                        <StyledTableCell>{event.userId ? event.userId : 'No user details'}</StyledTableCell>
                        <StyledTableCell>
                          {event.type === 'LOGIN_ERROR' ? (
                            <WarningAmberRoundedIcon sx={{ color: 'orange', width: '19px', height: '17px' }} />
                          ) : (
                            <CheckCircleIcon sx={{ color: 'green', width: '19px', height: '17px' }} />
                          )}
                          <Typography component="span">{event.type}</Typography>
                        </StyledTableCell>
                        <StyledTableCell>{event.ipAddress}</StyledTableCell>
                        <StyledTableCell>{event.clientId}</StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow>
                        <TableCell colSpan={6} style={{ paddingBottom: 0, paddingTop: 0 }}>
                          <Collapse in={openRows[index]} timeout="auto" unmountOnExit>
                            {openRows[index] && (
                              <Box sx={{ margin: 1 }}>
                                <Grid container spacing={2}>
                                  <Grid item xs={4}>
                                    {renderEventDetails(event)}
                                  </Grid>
                                </Grid>
                              </Box>
                            )}
                          </Collapse>
                        </TableCell>
                      </StyledTableRow>
                    </React.Fragment>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              count={count}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={(event) => handleChangeRowsPerPage(event)}
              rowsPerPageOptions={paginationRowsPerPage}
              labelRowsPerPage={'Events per Page'}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default UserEvents;
