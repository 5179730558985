import { useEffect, useState } from 'react';

const usePopupMargin = (ref: any, dependencies: any[]) => {
  const [popupMarginTop, setPopupMarginTop] = useState(0);

  useEffect(() => {
    const observer = new ResizeObserver(() => {
      if (ref.current) {
        const rect = ref.current.getBoundingClientRect();
        setPopupMarginTop(rect.height + 8);
      }
    });

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, dependencies);

  return popupMarginTop;
};

export default usePopupMargin;
