import React from 'react';
import {
  Grid,
  Skeleton,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  Paper,
  styled,
  TableCell,
  tableCellClasses,
} from '@mui/material';
import { StyledTableRow } from '../styles/tableStyles';

interface SkeletonLoaderProps {
  rows: any;
}

const SkeletonLoader: React.FC<SkeletonLoaderProps> = ({ rows }) => {
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      padding: '7px !important',
    },
  }));
  return (
    <Grid container spacing={3}>
      {[...Array(rows)].map((_, index) => (
        <Grid item xs={12} key={index}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={9}>
              <Skeleton animation="wave" variant="text" width={300} height={30} />
            </Grid>
            <Grid item xs={12} lg={3}>
              <Skeleton animation="wave" variant="text" width={300} height={30} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Skeleton animation="wave" variant="text" width={300} height={30} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Skeleton animation="wave" variant="text" width={300} height={30} />
            </Grid>
            <Grid item xs={12}>
              <TableContainer component={Paper} sx={{ maxHeight: '60vh' }}>
                <Table sx={{ minWidth: 700 }}>
                  <TableHead sx={{ position: 'sticky', top: 0, zIndex: 1 }}>
                    <TableRow>
                      <StyledTableCell>
                        <Skeleton animation="wave" variant="text" width={100} height={30} />
                      </StyledTableCell>
                      <StyledTableCell>
                        <Skeleton animation="wave" variant="text" width={200} height={30} />
                      </StyledTableCell>
                      <StyledTableCell>
                        <Skeleton animation="wave" variant="text" width={200} height={30} />
                      </StyledTableCell>
                      <StyledTableCell>
                        <Skeleton animation="wave" variant="text" width={200} height={30} />
                      </StyledTableCell>
                      <StyledTableCell>
                        <Skeleton animation="wave" variant="text" width={200} height={30} />
                      </StyledTableCell>
                      <StyledTableCell>
                        <Skeleton animation="wave" variant="text" width={100} height={30} />
                      </StyledTableCell>
                      <StyledTableCell>
                        <Skeleton animation="wave" variant="text" width={100} height={30} />
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {[...Array(5)].map((_, rowIndex) => (
                      <StyledTableRow key={rowIndex}>
                        <StyledTableCell>
                          <Skeleton animation="wave" variant="text" width={100} height={30} />
                        </StyledTableCell>
                        <StyledTableCell>
                          <Skeleton animation="wave" variant="text" width={200} height={30} />
                        </StyledTableCell>
                        <StyledTableCell>
                          <Skeleton animation="wave" variant="text" width={200} height={30} />
                        </StyledTableCell>
                        <StyledTableCell>
                          <Skeleton animation="wave" variant="text" width={200} height={30} />
                        </StyledTableCell>
                        <StyledTableCell>
                          <Skeleton animation="wave" variant="text" width={200} height={30} />
                        </StyledTableCell>
                        <StyledTableCell>
                          <Skeleton animation="wave" variant="text" width={100} height={30} />
                        </StyledTableCell>
                        <StyledTableCell>
                          <Skeleton animation="wave" variant="text" width={100} height={30} />
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

export default SkeletonLoader;
