import { Button } from '@mui/material';
import { useRouteError } from 'react-router-dom';
import { useNavigate, useLocation } from 'react-router-dom';
import { KeyclockConfig } from '../auth/keycloackConfig';

export default function ErrorPage() {
  const navigate = useNavigate();
  const error = useRouteError();
  const location = useLocation();
  const handleError = async () => {
    if (error) {
      navigate(-1);
    } else {
      try {
        const keycloakInstance = KeyclockConfig.getInstance();
        if (keycloakInstance && typeof keycloakInstance.logout === 'function') {
          await keycloakInstance.logout({
            redirectUri: process.env.REACT_APP_URL,
          });
        } else {
          console.error('Keycloak instance is not properly initialized or logout function is not available.');
        }
      } catch (err) {
        console.error('Error in error page::-', err);
      } finally {
        navigate('/auth/dashboard');
      }
    }
  };
  return (
    <div id="error-page">
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      {error ? (
        <>
          <Button onClick={handleError} type="submit" color="secondary" variant="outlined" fullWidth>
            Go Back
          </Button>
          <p>
            <i>{error?.statusText || error?.message || JSON.stringify(error)}</i>
          </p>
        </>
      ) : (
        <>
          <Button onClick={handleError} type="submit" color="secondary" variant="outlined" fullWidth>
            Sign in
          </Button>
          <p>
            <i>{location.pathname} is not a valid route.</i>
          </p>
        </>
      )}
    </div>
  );
}
