import {
  Avatar,
  Button,
  Chip,
  Container,
  Fade,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Skeleton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  styled,
  tableCellClasses,
} from '@mui/material';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import Layout from '../Layout';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import SkeletonLoader from '../SkeletonLoader';
import { useParams } from 'react-router-dom';
import { ApiHttpClient } from '../../interceptor/axiosInterceptor';
import useDebouncedSearch from '../../hooks/useDebouncedSearch';
import { error, success } from '../Toast';
import { paginationRowsPerPage } from '../../const/strings';
import { setConfigSlice } from '../../store/realmSlice';

function AppInfo() {
  const [loading, setLoading] = useState(true);
  const { orgName } = useParams();
  const state = useSelector((state: any) => state.config);
  const orgId = state.orgId;
  const initialState = useSelector((state: any) => state.initialConfig);
  const loginOrgId = initialState.orgId;
  const accessArray = Object.values(state.realmAccess ?? {}).flat();
  const userId = state.userId;
  const [sessions, setSessions] = useState<any>([]);
  const initialRender = useRef(true);
  const [count, setCount] = useState(10);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [firstValue, setFirstValue] = useState(0);
  const [searchTerm, setSearchTerm] = useDebouncedSearch((debouncedTerm: any) => {
    fetchData(debouncedTerm);
  });
  const dispatch = useDispatch();
  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      return;
    }
    fetchData(searchTerm);
  }, [orgId, page, rowsPerPage]);

  const fetchData = async (query = '') => {
    try {
      // /newTestRealm/ui-ext/sessions?first=0&max=11&type=ALL&search=
      const response1 = await ApiHttpClient.get(
        orgId !== loginOrgId
          ? `admin/${loginOrgId}/console/whoami?currentRealm=${orgId}`
          : `admin/${orgId}/console/whoami?currentRealm=${orgId}`,
      );
      const nameResponse1 = await ApiHttpClient.get(`admin/realms/${loginOrgId}/ui-ext/realms/names?`);
      let reformedState: any = {
        ...state,
        userId: response1.userId,
        realms: nameResponse1,
        realmAccess: response1.realm_access,
      };
      dispatch(setConfigSlice(reformedState));
      const url = query
        ? `admin/realms/${orgName}/ui-ext/sessions?first=${firstValue}&max=${rowsPerPage!}&type=ALL&search=${query}`
        : `admin/realms/${orgName}/ui-ext/sessions?first=${firstValue}&max=${rowsPerPage!}&type=ALL&search=`;
      const paginationUrl = query
        ? `admin/realms/${orgName}/ui-ext/sessions?first=0&max=100&type=ALL&search=${query}`
        : `admin/realms/${orgName}/ui-ext/sessions?first=0&max=100&type=ALL&search=`;
      const paginationResponse = await ApiHttpClient.get(paginationUrl);
      const response = await ApiHttpClient.get(url);
      setCount(paginationResponse.length);
      setSessions(response && response.length > 0 ? response : []);

      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleSearchInputChange = (e: any) => {
    const value = e.target.value;
    setSearchTerm(value);
  };

  const handleSignOut = async (sessionId: string) => {
    try {
      const url = `admin/realms/${orgName}/sessions/${sessionId}`;
      await ApiHttpClient.delete(url, {});
      setSessions((prevSessions: any) => prevSessions.filter((session: any) => session.id !== sessionId));
      success('Successfully Logged Out');
    } catch (err) {
      console.error('Error signing out:', err);
      error('Error in Logged Out');
    }
  };

  const handleChangePage = (event: any, newPage: any) => {
    const firstValue = newPage * rowsPerPage;
    setFirstValue(firstValue);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setFirstValue(0);
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      padding: '7px !important',
    },
  }));

  return (
    <Grid sx={{ margin: 5, marginTop: 10 }}>
      <Layout>
        {loading ? (
          <SkeletonLoader rows={''} />
        ) : (
          <Grid container spacing={3}>
            <Grid item xs={12} lg={9}>
              <Typography variant="h6">Sessions</Typography>
              <Typography variant="body1">
                <Typography component="span" color={'red'}>
                  *Note:
                </Typography>{' '}
                Sessions are sessions of users in this realm and the clients that they access within the session.
              </Typography>
            </Grid>
            {/* <Grid item xs={12} lg={3}>
                <Typography variant="h6" textAlign="right">
                  Org: {orgId}
                </Typography>
              </Grid> */}

            <Grid item xs={12} sm={6}>
              <TextField
                size="small"
                fullWidth
                type="search"
                id="outlined-basic"
                label="Search Session"
                variant="outlined"
                value={searchTerm}
                onChange={handleSearchInputChange}
              />
            </Grid>
            {/* <Grid item xs={12} sm={6} textAlign="right">
                <Button size="medium" variant="contained">
                  Create User
                </Button>
              </Grid> */}

            <Grid item xs={12}>
              <TableContainer component={Paper} sx={{ maxHeight: '60vh' }}>
                <Table sx={{ minWidth: 700 }}>
                  <TableHead sx={{ position: 'sticky', top: 0, zIndex: 1 }}>
                    <TableRow>
                      <StyledTableCell>S.No </StyledTableCell>
                      <StyledTableCell>User</StyledTableCell>
                      <StyledTableCell>Started</StyledTableCell>
                      <StyledTableCell>Last access</StyledTableCell>
                      <StyledTableCell>IP address</StyledTableCell>
                      <StyledTableCell>Clients</StyledTableCell>
                      <StyledTableCell>Action</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {sessions.map((session: any, index: any) => (
                      <TableRow key={session.id}>
                        <StyledTableCell> {page * rowsPerPage + index + 1}</StyledTableCell>
                        <StyledTableCell>{session.username}</StyledTableCell>
                        <StyledTableCell>{new Date(session.start).toLocaleString()}</StyledTableCell>
                        <StyledTableCell>{new Date(session.lastAccess).toLocaleString()}</StyledTableCell>
                        <StyledTableCell>{session.ipAddress}</StyledTableCell>
                        <StyledTableCell>{Object.values(session.clients)[0] as React.ReactNode}</StyledTableCell>

                        <StyledTableCell>
                          <Tooltip sx={{ cursor: 'pointer' }} title="Sign Out" arrow placement="bottom">
                            <IconButton
                              color="primary"
                              onClick={() => handleSignOut(session.id)}
                              disabled={!accessArray.includes('manage-users')}
                            >
                              <ExitToAppIcon />
                            </IconButton>
                          </Tooltip>
                        </StyledTableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                component="div"
                count={count}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={(event) => handleChangeRowsPerPage(event)}
                rowsPerPageOptions={paginationRowsPerPage}
                labelRowsPerPage={'Roles per Page'}
              />
            </Grid>
          </Grid>
        )}
      </Layout>
    </Grid>
  );
}

export default AppInfo;
