import { createSlice } from '@reduxjs/toolkit';

const profileSlice = createSlice({
  name: 'profile',
  initialState: {
    userName: '',
    email: '',
  },
  reducers: {
    setProfile(state, action) {
      state.userName = action.payload.username;
      state.email = action.payload.email;
    },
  },
});
// `createSlice` automatically generated action creators with these names.
// export them as named exports from this "slice" file
// export const { todoAdded, todoToggled } = todosSlice.actions;
export const { setProfile } = profileSlice.actions;
// Export the slice reducer as the default export
export default profileSlice.reducer;
// export default todosSlice.reducer;
