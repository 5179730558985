import {
  Grid,
  Container,
  Link,
  Typography,
  Breadcrumbs,
  Stack,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Box,
  Button,
  FormGroup,
  Switch,
  FormControlLabel,
  Checkbox,
  IconButton,
} from '@mui/material';
import Layout from '../Layout';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useParams, Link as RouterLink } from 'react-router-dom';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { ApiHttpClient } from '../../interceptor/axiosInterceptor';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { success, error } from '../Toast';
import { useDispatch, useSelector } from 'react-redux';
import { setConfigSlice } from '../../store/realmSlice';

function ClientSettings(clientPassed: any) {
  const [clientType, setClientType] = useState<any>(10);
  const [loginTheme, setLoginTheme] = useState<any>(10);
  const [clientIdField, setClientIdField] = useState<any>('');
  const [name, setName] = useState<any>('');
  const [clientText, setClientText] = useState<any>('');
  const [frontlogout, setFrontLogout] = useState<any>('');
  const [backlogout, setBackLogout] = useState<any>('');
  const initialRender = useRef(true);
  const [description, setDescription] = useState<any>('');
  const [rootUrl, setRootUrl] = useState<any>('');
  const [homeUrl, setHomeUrl] = useState<any>('');
  const [validRedirectUris, setValidRedirectUris] = useState<any>(['']);
  const [validPostLogoutRedirectUris, setValidPostLogoutRedirectUris] = useState<any>(['']);
  const [webOrigins, setWebOrigins] = useState<any>(['']);
  const [adminUrl, setAdminUrl] = useState<any>('');
  const [errors, setErrors] = useState<any>({});
  const [isSwitchOn, setIsSwitchOn] = useState<any>(false);
  const [isSwitchOn2, setIsSwitchOn2] = useState<any>(false);
  const [isSwitchOn3, setIsSwitchOn3] = useState<any>(false);
  const [isSwitchOn4, setIsSwitchOn4] = useState<any>(false);
  const [isSwitchOn5, setIsSwitchOn5] = useState<any>(true);
  const [isSwitchOn6, setIsSwitchOn6] = useState<any>(true);
  const [isSwitchOn7, setIsSwitchOn7] = useState<any>(false);

  const state = useSelector((state: any) => state.config);
  const orgId = state.orgId;
  const initialState = useSelector((state: any) => state.initialConfig);
  const loginOrgId = initialState.orgId;
  const { clientId } = useParams();
  const dispatch = useDispatch();
  const accessArray = Object.values(state.realmAccess ?? {}).flat();
  const [clients, setClients] = useState<any>('');
  const clientState = useSelector((state: any) => state.client);

  useEffect(() => {
    fetchData();
  }, [orgId]);

  useEffect(() => {
    if (!initialRender.current) {
      fetchData();
    } else {
      initialRender.current = false;
    }
  }, [orgId]);

  const fetchData = async () => {
    try {
      const response1 = await ApiHttpClient.get(
        orgId !== loginOrgId
          ? `admin/${loginOrgId}/console/whoami?currentRealm=${orgId}`
          : `admin/${orgId}/console/whoami?currentRealm=${orgId}`,
      );
      const nameResponse1 = await ApiHttpClient.get(`admin/realms/${loginOrgId}/ui-ext/realms/names?`);
      let reformedState: any = {
        ...state,
        userId: response1.userId,
        realms: nameResponse1,
        realmAccess: response1.realm_access,
      };
      dispatch(setConfigSlice(reformedState));
      const url = `admin/realms/${orgId}/clients/${clientId}`;
      const response = await ApiHttpClient.get(url);
      let apiData: any = response;
      setClients(apiData);
      apiData?.name && setName(apiData.name);
      apiData?.clientId && setClientIdField(apiData.clientId);
      apiData?.rootUrl && setRootUrl(apiData.rootUrl);
      apiData?.description && setDescription(apiData.description);
      apiData?.adminUrl && setAdminUrl(apiData.adminUrl);
      apiData?.homeUrl && setHomeUrl(apiData.homeUrl);
      apiData?.redirectUris.length > 0 ? setValidRedirectUris(apiData?.redirectUris) : setValidRedirectUris(['']);
      if (
        apiData?.attributes?.['post.logout.redirect.uris'] &&
        apiData?.attributes?.['post.logout.redirect.uris'].length > 0
      ) {
        const postLogoutRedirectUris = apiData?.attributes?.['post.logout.redirect.uris'] ?? '';
        setValidPostLogoutRedirectUris(postLogoutRedirectUris.split('##'));
      } else {
        setValidPostLogoutRedirectUris(['']);
        setFrontLogout('');
        setBackLogout('');
      }

      apiData?.webOrigins && apiData?.webOrigins.length > 0 ? setWebOrigins(apiData?.webOrigins) : setWebOrigins(['']);
      apiData?.['attributes']['frontchannel.logout.url'] &&
        setFrontLogout(apiData?.['attributes']['frontchannel.logout.url']);
      apiData?.['attributes']['backchannel.logout.url'] &&
        setBackLogout(apiData?.['attributes']['backchannel.logout.url']);

      apiData?.['frontchannelLogout'] && setIsSwitchOn5(!!apiData?.['frontchannelLogout']);
      apiData?.['attributes']['backchannelLogout'] &&
        setIsSwitchOn6(!!apiData?.['attributes']['backchannel.logout.session.required']);

      apiData?.['attributes']['backchannel.logout.revoke.offline.tokens'] &&
        setIsSwitchOn7(apiData?.['attributes']['backchannel.logout.revoke.offline.tokens']);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleChangeClientType = (event: any) => {
    setClientType(event.target.value);
  };

  const handleChangeThemeType = (event: any) => {
    setLoginTheme(event.target.value);
  };

  const handleSwitchChange = (event: any) => {
    setIsSwitchOn(event.target.checked);
  };

  const handleAuthorizeSwitchChange = (event: any) => {
    setIsSwitchOn2(event.target.checked);
  };

  const handleConsentSwitchChange = (event: any) => {
    setIsSwitchOn3(event.target.checked);
  };

  const handleDisplaySwitchChange = (event: any) => {
    setIsSwitchOn4(event.target.checked);
  };

  const handleFrontChannelSwitchChange = (event: any) => {
    setIsSwitchOn5(event.target.checked);
  };

  const handleBackChannelSwitchChange = (event: any) => {
    setIsSwitchOn6(event.target.checked);
  };

  const handleBackChannelRevokeSwitchChange = (event: any) => {
    setIsSwitchOn7(event.target.checked);
  };

  const handleRemoveField = (index: any, type: any) => {
    if (type === 'redirect') {
      if (validRedirectUris.length > 1) {
        setValidRedirectUris(validRedirectUris.filter((_: any, i: any) => i !== index));
      }
    } else if (type === 'post-logout') {
      if (validPostLogoutRedirectUris.length > 1) {
        setValidPostLogoutRedirectUris(validPostLogoutRedirectUris.filter((_: any, i: any) => i !== index));
      }
    } else if (type === 'web-origin') {
      if (webOrigins.length > 1) {
        setWebOrigins(webOrigins.filter((_: any, i: any) => i !== index));
      }
    }
  };

  const handleAddField = (type: any) => {
    if (type === 'redirect') {
      setValidRedirectUris([...validRedirectUris, '']);
    } else if (type === 'post-logout') {
      setValidPostLogoutRedirectUris([...validPostLogoutRedirectUris, '']);
    } else if (type === 'web-origin') {
      setWebOrigins([...webOrigins, '']);
    }
  };

  const handleChange = (e: any, index: any, type: any) => {
    const value = e.target.value;
    if (type === 'redirect') {
      const updatedUris = validRedirectUris.map((uri: any, i: any) => (i === index ? value : uri));
      setValidRedirectUris(updatedUris);
    } else if (type === 'post-logout') {
      const updatedUris = validPostLogoutRedirectUris.map((uri: any, i: any) => (i === index ? value : uri));
      setValidPostLogoutRedirectUris(updatedUris);
    } else if (type === 'web-origin') {
      const updatedUris = webOrigins.map((uri: any, i: any) => (i === index ? value : uri));
      setWebOrigins(updatedUris);
    }
  };

  const resetForm = () => {
    setClientType(10);
    setClientIdField('');
    setName('');
    setDescription('');
    setRootUrl('');
    setHomeUrl('');
    setValidRedirectUris(['']);
    setValidPostLogoutRedirectUris(['']);
    setWebOrigins(['']);
    setAdminUrl('');
    setErrors({});
  };

  const handleCancel = () => {
    resetForm();
  };

  const handleUpdate = async () => {
    const validationErrors = validateForm();
    if (validationErrors.clientIdField) {
      setErrors(validationErrors);
      return;
    }

    // Prepare payload
    const payload: any = {
      id: clientId,
      protocol: clientType === 10 ? 'openid-connect' : 'saml',
      clientId: clientIdField,
      alwaysDisplayInConsole: false,
      name: name || '',
      description: description || '',
      rootUrl: rootUrl || '',
      baseUrl: homeUrl || '',
      frontchannelLogout: true,
      publicClient: true,
      adminUrl: adminUrl,
      implicitFlowEnabled: false,
      directAccessGrantsEnabled: true,
      redirectUris: validRedirectUris || '',
      webOrigins: webOrigins || '',
      standardFlowEnabled: true,
      serviceAccountsEnabled: false,
      authorizationServicesEnabled: isSwitchOn2,
      attributes: {
        'backchannel.logout.revoke.offline.tokens': 'false',
        'backchannel.logout.session.required': 'true',
        'backchannel.logout.url': '',
        'display.on.consent.screen': false,
        'frontchannel.logout.url': '',
        login_theme: '',
        'oauth2.device.authorization.grant.enabled': false,
        'oidc.ciba.grant.enabled': false,
      },
      authenticationFlowBindingOverrides: {},
      access: {
        configure: true,
        manage: true,
        view: true,
      },
      bearerOnly: false,
      clientAuthenticatorType: 'client-secret',
      consentRequired: false,
      defaultClientScopes: ['web-origins', 'acr', 'profile', 'roles', 'email'],
      surrogateAuthRequired: false,
      enabled: true,
      fullScopeAllowed: true,
      nodeReRegistrationTimeout: -1,
      notBefore: 0,
      optionalClientScopes: ['address', 'phone', 'offline_access', 'microprofile-jwt'],
    };
    if (validRedirectUris?.length > 0 && validRedirectUris?.[0] !== '') {
      payload['redirectUris'] = validRedirectUris;
    }
    if (webOrigins?.length > 0 && webOrigins?.[0] !== '') {
      payload['webOrigins'] = webOrigins;
    }
    if (validPostLogoutRedirectUris?.length > 0 && validPostLogoutRedirectUris?.[0] !== '') {
      let postLogoutUris = validPostLogoutRedirectUris.join('##');
      payload['attributes']['post.logout.redirect.uris'] = postLogoutUris;
    }

    if (frontlogout.length > 0 && frontlogout !== '') {
      payload['attributes']['frontchannel.logout.url'] = frontlogout;
    }

    if (backlogout.length > 0 && backlogout !== '') {
      payload['attributes']['backchannel.logout.url'] = backlogout;
    }

    payload['frontchannelLogout'] = isSwitchOn5;

    payload['attributes']['backchannel.logout.session.required'] = isSwitchOn6;

    payload['attributes']['backchannel.logout.revoke.offline.tokens'] = isSwitchOn7;

    try {
      const url = `admin/realms/${orgId}/clients/${clientId}`;
      const response = await ApiHttpClient.put(url, payload);
      success('Client updated successfully');
      resetForm();
      fetchData();
    } catch (err) {
      console.error('Error creating client:', err);
      error('Error updating client');
    }
  };

  const validateForm = () => {
    const errors: any = {};
    if (!clientIdField) errors.clientIdField = 'Client ID is required';
    return errors;
  };

  return (
    <>
      <Grid container spacing={2} sx={{ marginTop: '5px' }}>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Client type</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={clientType}
              label="clientType"
              size="small"
              onChange={handleChangeClientType}
            >
              <MenuItem value={10}>OpenID Connect</MenuItem>
              <MenuItem value={20}>SAML</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <TextField
            size="small"
            fullWidth
            id="Client ID *"
            name="Client ID *"
            label="Client ID *"
            variant="outlined"
            value={clientIdField}
            disabled
            // onChange={(e) => setClientIdField(e.target.value)}
            error={!!errors.clientIdField}
            helperText={errors.clientIdField}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            size="small"
            fullWidth
            id="Name"
            name="Name"
            label="Name"
            variant="outlined"
            value={name}
            onChange={(e) => setName(e.target.value)}
            error={!!errors.name}
            helperText={errors.name}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            size="small"
            fullWidth
            id="Description"
            name="Description"
            label="Description"
            variant="outlined"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            size="small"
            fullWidth
            id="Root URL "
            name="Root URL "
            label="Root URL "
            variant="outlined"
            value={rootUrl}
            onChange={(e) => setRootUrl(e.target.value)}
            error={!!errors.rootUrl}
            helperText={errors.rootUrl}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            size="small"
            fullWidth
            id="Home URL "
            name="Home URL "
            label="Home URL "
            variant="outlined"
            value={homeUrl}
            onChange={(e) => setHomeUrl(e.target.value)}
            error={!!errors.homeUrl}
            helperText={errors.homeUrl}
          />
        </Grid>

        <Grid item xs={6}>
          <FormGroup row>
            <Typography variant="body1">Client authentication</Typography>
            <FormControlLabel
              sx={{ paddingLeft: '15px', marginTop: '-8px' }}
              control={<Switch checked={isSwitchOn} onChange={handleSwitchChange} />}
              label={isSwitchOn ? 'ON' : 'OFF'}
            />
          </FormGroup>
        </Grid>
        <Grid item xs={6}>
          <FormGroup row>
            <Typography variant="body1">Authorization</Typography>
            <FormControlLabel
              sx={{ paddingLeft: '15px', marginTop: '-8px' }}
              control={<Switch checked={isSwitchOn2} onChange={handleAuthorizeSwitchChange} />}
              label={isSwitchOn2 ? 'ON' : 'OFF'}
            />
          </FormGroup>
        </Grid>
        <FormControl component="fieldset">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={2} sx={{ marginTop: '10px', marginLeft: '15px' }}>
              <Typography variant="body1">Authorization Flow</Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControlLabel
                control={
                  <Checkbox
                    id="kc-flow-standard"
                    name="standard"
                    checked
                    inputProps={{ 'aria-labelledby': 'kc-flow-standard' }}
                  />
                }
                label="Standard flow"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    id="kc-flow-implicit"
                    name="implicit"
                    inputProps={{ 'aria-labelledby': 'kc-flow-implicit' }}
                  />
                }
                label="Implicit flow"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    id="kc-oauth-device-authorization-grant"
                    name="oauthDeviceAuthorizationGrant"
                    inputProps={{ 'aria-labelledby': 'kc-oauth-device-authorization-grant' }}
                  />
                }
                label="OAuth 2.0 Device Authorization Grant"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    id="kc-oidc-ciba-grant"
                    name="oidcCibaGrant"
                    disabled
                    inputProps={{ 'aria-labelledby': 'kc-oidc-ciba-grant' }}
                  />
                }
                label="OIDC CIBA Grant"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControlLabel
                control={
                  <Checkbox
                    id="kc-flow-direct"
                    name="directAccessGrantsEnabled"
                    checked
                    inputProps={{ 'aria-labelledby': 'kc-flow-direct' }}
                  />
                }
                label="Direct access grants"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    id="kc-flow-service-account"
                    name="serviceAccount"
                    disabled
                    inputProps={{ 'aria-labelledby': 'kc-flow-service-account' }}
                  />
                }
                label="Service accounts roles"
              />
            </Grid>
          </Grid>
        </FormControl>
        <Grid item xs={11}>
          <TextField
            size="small"
            fullWidth
            id="Admin URL "
            name="Admin URL "
            label="Admin URL "
            variant="outlined"
            value={adminUrl}
            onChange={(e) => setAdminUrl(e.target.value)}
          />
        </Grid>

        <Grid item xs={12}>
          {validRedirectUris?.map((uri: any, index: any) => (
            <Grid item xs={12} key={index} container alignItems="center">
              <Grid item xs={11} sx={{ marginBottom: '10px' }}>
                <TextField
                  size="small"
                  fullWidth
                  id={`valid-redirect-uri-${index}`}
                  name={`valid-redirect-uri-${index}`}
                  label="Valid Redirect URIs"
                  variant="outlined"
                  value={uri}
                  onChange={(e) => handleChange(e, index, 'redirect')}
                  error={!!errors.validRedirectUris}
                  helperText={errors.validRedirectUris}
                />
              </Grid>
              <Grid item xs={1} container justifyContent="flex-end">
                <IconButton onClick={() => handleAddField('redirect')}>
                  <AddCircleOutlineIcon />
                </IconButton>
                <IconButton
                  onClick={() => handleRemoveField(index, 'redirect')}
                  disabled={validRedirectUris.length <= 1}
                >
                  <RemoveCircleOutlineIcon />
                </IconButton>
              </Grid>
            </Grid>
          ))}
        </Grid>

        <Grid item xs={12}>
          {validPostLogoutRedirectUris?.map((uri: any, index: any) => (
            <Grid item xs={12} key={index} container alignItems="center">
              <Grid item xs={11} sx={{ marginBottom: '10px' }}>
                <TextField
                  size="small"
                  fullWidth
                  id={`valid-post-logout-redirect-uri-${index}`}
                  name={`valid-post-logout-redirect-uri-${index}`}
                  label="Valid Post Logout Redirect URIs"
                  variant="outlined"
                  value={uri}
                  onChange={(e) => handleChange(e, index, 'post-logout')}
                  error={!!errors.validPostLogoutRedirectUris}
                  helperText={errors.validPostLogoutRedirectUris}
                />
              </Grid>
              <Grid item xs={1} container justifyContent="flex-end">
                <IconButton onClick={() => handleAddField('post-logout')}>
                  <AddCircleOutlineIcon />
                </IconButton>
                <IconButton
                  onClick={() => handleRemoveField(index, 'post-logout')}
                  disabled={validPostLogoutRedirectUris.length <= 1}
                >
                  <RemoveCircleOutlineIcon />
                </IconButton>
              </Grid>
            </Grid>
          ))}
        </Grid>

        <Grid item xs={12}>
          {webOrigins?.map((uri: any, index: any) => (
            <Grid item xs={12} key={index} container alignItems="center">
              <Grid item xs={11} sx={{ marginBottom: '10px' }}>
                <TextField
                  size="small"
                  fullWidth
                  id={`web-origin-${index}`}
                  name={`web-origin-${index}`}
                  label="Web Origins"
                  variant="outlined"
                  value={uri}
                  onChange={(e) => handleChange(e, index, 'web-origin')}
                  error={!!errors.webOrigins}
                  helperText={errors.webOrigins}
                />
              </Grid>
              <Grid item xs={1} container justifyContent="flex-end">
                <IconButton onClick={() => handleAddField('web-origin')}>
                  <AddCircleOutlineIcon />
                </IconButton>
                <IconButton onClick={() => handleRemoveField(index, 'web-origin')} disabled={webOrigins.length <= 1}>
                  <RemoveCircleOutlineIcon />
                </IconButton>
              </Grid>
            </Grid>
          ))}
        </Grid>

        <Grid item xs={12}>
          <Typography variant="body1" sx={{ fontWeight: '500' }}>
            Login settings
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Login theme</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={loginTheme}
              label="clientType"
              size="small"
              onChange={handleChangeThemeType}
            >
              <MenuItem value={10}>Greenko</MenuItem>
              <MenuItem value={20}>Intellaire</MenuItem>
              <MenuItem value={30}>Keycloak</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <TextField
            size="small"
            fullWidth
            id="clientText"
            name="clientText"
            label="Client consent screen text "
            variant="outlined"
            value={clientText}
            onChange={(e) => setClientText(e.target.value)}
          />
        </Grid>

        <Grid item xs={6}>
          <FormGroup row>
            <Typography variant="body1">Consent required</Typography>
            <FormControlLabel
              sx={{ paddingLeft: '15px', marginTop: '-8px' }}
              control={<Switch checked={isSwitchOn3} onChange={handleConsentSwitchChange} />}
              label={isSwitchOn3 ? 'ON' : 'OFF'}
            />
          </FormGroup>
        </Grid>
        <Grid item xs={6}>
          <FormGroup row>
            <Typography variant="body1">Consent required</Typography>
            <FormControlLabel
              sx={{ paddingLeft: '15px', marginTop: '-8px' }}
              control={<Switch checked={isSwitchOn4} onChange={handleDisplaySwitchChange} />}
              label={isSwitchOn4 ? 'ON' : 'OFF'}
            />
          </FormGroup>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="body1" sx={{ fontWeight: '500' }}>
            Logout settings
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <TextField
            size="small"
            fullWidth
            id="frontlogout"
            name="frontlogout"
            label="Front-channel logout URL"
            variant="outlined"
            value={frontlogout}
            onChange={(e) => setFrontLogout(e.target.value)}
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            size="small"
            fullWidth
            id="backlogout"
            name="backlogout"
            label="Backchannel logout URL"
            variant="outlined"
            value={backlogout}
            onChange={(e) => setBackLogout(e.target.value)}
          />
        </Grid>

        <Grid item xs={4}>
          <FormGroup row>
            <Typography variant="body1">Front channel logout</Typography>
            <FormControlLabel
              sx={{ paddingLeft: '15px', marginTop: '-8px' }}
              control={<Switch checked={isSwitchOn5} onChange={handleFrontChannelSwitchChange} />}
              label={isSwitchOn5 ? 'ON' : 'OFF'}
            />
          </FormGroup>
        </Grid>

        <Grid item xs={4}>
          <FormGroup row>
            <Typography variant="body1">Backchannel logout session required</Typography>
            <FormControlLabel
              sx={{ paddingLeft: '15px', marginTop: '-8px' }}
              control={<Switch checked={isSwitchOn6} onChange={handleBackChannelSwitchChange} />}
              label={isSwitchOn6 ? 'ON' : 'OFF'}
            />
          </FormGroup>
        </Grid>

        <Grid item xs={4}>
          <FormGroup row>
            <Typography variant="body1">Backchannel logout revoke offline sessions</Typography>
            <FormControlLabel
              sx={{ paddingLeft: '15px', marginTop: '-8px' }}
              control={<Switch checked={isSwitchOn7} onChange={handleBackChannelRevokeSwitchChange} />}
              label={isSwitchOn7 ? 'ON' : 'OFF'}
            />
          </FormGroup>
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{ marginTop: '5px' }}>
        <Box display="flex">
          <Button
            disabled={!accessArray.includes('manage-clients')}
            variant="contained"
            color="primary"
            style={{ marginRight: '8px' }}
            onClick={handleUpdate}
          >
            Save
          </Button>
          <Button
            disabled={!accessArray.includes('manage-clients')}
            variant="outlined"
            color="primary"
            onClick={handleCancel}
          >
            Cancel
          </Button>
        </Box>
      </Grid>
    </>
  );
}

export default ClientSettings;
