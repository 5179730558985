import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { api } from '../modules/todo/apiSlice';
import rootReducer from './rootReducer';
import counterReducer from './counterSlice';
import realmSlice from './realmSlice';
import appStateSlice from './appStateSlice';
import initialRealmSlice from './loginRealmSlice';
import clientSlice from './clientSlice';
import themeReducer from './themeSlice';
import sidebarReducer from './sideBarSlice';
import profileSlice from './profileSlice';
const persistConfig = {
  key: 'config',
  storage,
};

const authPersistConfig = {
  key: 'root',
  storage: storage,
};

const themePersistConfig = {
  key: 'theme',
  storage,
};

const clientPersistConfig = {
  key: 'client',
  storage,
};

const sideBarPersistConfig = {
  key: 'sidebar',
  storage,
};

const initialLoginIdPersistConfig = {
  key: 'initialConfig',
  storage,
};

const profilePersistConfig = {
  key: 'profile',
  storage,
};
const persistedRealmReducer = persistReducer(persistConfig, realmSlice);
const persistedAppReducer = persistReducer(authPersistConfig, appStateSlice);
const persistedInitialRealmReducer = persistReducer(initialLoginIdPersistConfig, initialRealmSlice);
const persistedClientReducer = persistReducer(clientPersistConfig, clientSlice);
const persistedThemeReducer = persistReducer(themePersistConfig, themeReducer);
const peristedSideBarReducer = persistReducer(sideBarPersistConfig, sidebarReducer);
const persistedProfileReducer = persistReducer(profilePersistConfig, profileSlice);
const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    rootReducer,
    counter: counterReducer,
    config: persistedRealmReducer,
    initialConfig: persistedInitialRealmReducer,
    client: persistedClientReducer,
    app: persistedAppReducer,
    theme: persistedThemeReducer,
    sidebar: peristedSideBarReducer,
    profile: persistedProfileReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

const persistor = persistStore(store);

// Define RootState type
export type RootState = ReturnType<typeof store.getState>;

export { store, persistor };
