import { Box, Breadcrumbs, Grid, Link, Stack, Tab, Tabs, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { Link as RouterLink, useLocation, useParams } from 'react-router-dom';
import Layout from '../Layout';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import GroupsChild from './GroupsChild';
import { useDispatch, useSelector } from 'react-redux';
import GroupsMembers from './GroupsMembers';
import GroupsAttributes from './GroupAttributes';
import RoleMapping from './RoleMapping';
import UserUpdate from './UserUpdate';
import UserRoleMapping from './UserRoleMapping';
import { ApiHttpClient } from '../../interceptor/axiosInterceptor';
import UserAttributes from './UserAttributes';
import { setConfigSlice } from '../../store/realmSlice';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function UserDetails() {
  const [value, setValue] = React.useState(0);
  const state = useSelector((state: any) => state.config);
  const initialState = useSelector((state: any) => state.initialConfig);
  const loginOrgId = initialState.orgId;
  const orgId = state.orgId;
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const groupName = queryParams.get('groupName');
  const { userId } = useParams();
  const [user, setUser]: any = useState(null);
  const [attributes, setAttributes] = useState({});
  const [userDetails, setUserDetails] = useState({});
  const sidebarOpen = useSelector((state: any) => state.sidebar.isOpen);
  const dispatch = useDispatch();
  const initialRender = useRef(true);
  const breadcrumbs = [
    <Link underline="hover" key="1" color="primary" component={RouterLink} to={`/auth/userdashboard/${orgId}/users`}>
      Users
    </Link>,
    <Typography key="2" color="text.primary">
      User details
    </Typography>,
  ];

  useEffect(() => {
    fetchData();
    if (initialRender.current) {
      initialRender.current = false;
      return;
    }
  }, [orgId]);

  const fetchData = async () => {
    try {
      const response1 = await ApiHttpClient.get(
        orgId !== loginOrgId
          ? `admin/${loginOrgId}/console/whoami?currentRealm=${orgId}`
          : `admin/${orgId}/console/whoami?currentRealm=${orgId}`,
      );
      const nameResponse1 = await ApiHttpClient.get(`admin/realms/${loginOrgId}/ui-ext/realms/names?`);
      let reformedState: any = {
        ...state,
        userId: response1.userId,
        realms: nameResponse1,
        realmAccess: response1.realm_access,
      };
      dispatch(setConfigSlice(reformedState));

      const url = `/admin/realms/${orgId}/users/${userId}?userProfileMetadata=true`;
      const unmanagedAttributesUrl = `/admin/realms/${orgId}/ui-ext/users/${userId}/unmanagedAttributes`;
      const userUrl = `admin/realms/${orgId}/users/${userId}`;
      const response = await ApiHttpClient.get(url);
      const userDetails = await ApiHttpClient.get(userUrl);
      setUserDetails(userDetails);
      const attributesResponse = await ApiHttpClient.get(unmanagedAttributesUrl);
      const attributesArray = Object.entries(attributesResponse || {}).map(([key, value]: any) => ({
        key,
        value: value[0],
      }));
      setAttributes(attributesArray);
      let apiData: any = response;
      setUser(apiData?.username);
    } catch (err) {
      console.log(err);
    }
  };
  const handleUserDetails = (data: any) => {
    setUserDetails(data);
    const { attributes } = data;
    const attributesArray = Object.entries(attributes || {}).map(([key, value]: any) => ({
      key,
      value: value[0],
    }));
    console.log(attributesArray);
    setAttributes(attributesArray);
  };
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  return (
    <>
      <Grid sx={{ margin: 5, marginTop: 10 }}>
        <Layout>
          <Stack spacing={2} sx={{ marginTop: '10px' }}>
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
              {breadcrumbs}
            </Breadcrumbs>
          </Stack>

          <Grid container spacing={3} sx={{ marginTop: '3px', paddingBottom: '15px' }}>
            <Grid item xs={12} lg={9}>
              <Typography variant="h5">User: {user}</Typography>
            </Grid>
            <Box sx={{ width: '100%' }}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                  <Tab label="Details" {...a11yProps(0)} />
                  {/* <Tab label="Credentials" {...a11yProps(1)} /> */}
                  <Tab label="Role Mapping" {...a11yProps(1)} />
                  <Tab label="Attributes" {...a11yProps(2)} />
                  <Tab label="Groups" {...a11yProps(3)} />
                  <Tab label="Sessions" {...a11yProps(4)} />
                </Tabs>
              </Box>

              <CustomTabPanel value={value} index={0}>
                <UserUpdate user={userDetails} onDetailsChange={handleUserDetails} />
              </CustomTabPanel>

              <CustomTabPanel value={value} index={1}>
                <div
                  style={{
                    display: 'flex',
                    marginLeft: sidebarOpen ? '80px' : '50px',
                    transition: 'margin 0.3s',
                  }}
                >
                  <UserRoleMapping userName={user} />
                </div>
              </CustomTabPanel>

              <CustomTabPanel value={value} index={2}>
                <UserAttributes
                  userId={userId}
                  attributes={attributes}
                  details={userDetails}
                  onDetailsChange={handleUserDetails}
                />
              </CustomTabPanel>
              <CustomTabPanel value={value} index={3}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                    padding: 2,
                  }}
                >
                  <Grid item xs={12} sm={6}>
                    <>
                      <Typography variant="body1" gutterBottom>
                        Comming Soon...!
                      </Typography>
                    </>
                  </Grid>
                </Box>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={4}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                    padding: 2,
                  }}
                >
                  <Grid item xs={12} sm={6}>
                    <>
                      <Typography variant="body1" gutterBottom>
                        Comming Soon...!
                      </Typography>
                    </>
                  </Grid>
                </Box>{' '}
              </CustomTabPanel>
              {/* {<CustomTabPanel value={value} index={3}>
                <RoleMapping />
              </CustomTabPanel> } */}
            </Box>
          </Grid>
        </Layout>
      </Grid>
    </>
  );
}

export default UserDetails;
