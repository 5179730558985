import { useState, useEffect } from 'react';

const useDebouncedSearch = (callback: any, delay = 500) => {
  const [searchTerm, setSearchTerm] = useState<any>('');
  const [debouncedTerm, setDebouncedTerm] = useState(searchTerm);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedTerm(searchTerm);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm, delay]);

  useEffect(() => {
    callback(debouncedTerm);
  }, [debouncedTerm]);

  return [searchTerm, setSearchTerm];
};

export default useDebouncedSearch;
