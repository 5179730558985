import React from 'react';
import MasterDashboard from '../components/masterDashboard';
import { Box, Grid, Typography } from '@mui/material';
import Layout from '../components/Layout';
import { KeyclockConfig } from '../auth/keycloackConfig';

function Dashboard() {
  React.useEffect(() => {
    // refreshToken();
  }, []);

  return (
    <Grid item xs={12}>
      <Grid sx={{ marginTop: 10 }}>
        <Layout>
          <MasterDashboard />

          {/* <UserDashboard /> */}
        </Layout>
      </Grid>
    </Grid>
  );
}

export default Dashboard;
